import React, { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { enUS } from 'date-fns/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Button } from '@mui/material';
import InputField from './InputFields/InputField';
import { useFormik } from 'formik';
import * as Yup from "yup";
import dayjs from 'dayjs';
import useHelperFuncs from '../../../utils/hooks/useHelperFuncs';



type MultiRangeCalenderProps = {
  onSelectDateRange: (startDate: Date, endDate: Date) => void;
  handleBookClick: any,
  carAvailabilityData: any,
  seatingCapacity: any
};

const MultiRangeCalender: React.FC<MultiRangeCalenderProps> = ({
  onSelectDateRange,
  handleBookClick,
  carAvailabilityData,
  seatingCapacity
}) => {
  const { isDateRangeWithin } = useHelperFuncs();

  const [rangeStart, setRangeStart] = useState<null | any>(null);
  const [rangeEnd, setRangeEnd] = useState<null | any>(null);

  const handleDateChange = (ranges: any) => {
    // console.log('ranges', ranges);
    const { startDate, endDate } = ranges.selection;

    // Only call onSelectDateRange if both start and end dates are defined
    if (startDate && endDate) {
      onSelectDateRange(startDate, endDate);
    }
    formik.setFieldValue("dateRange", { selection: ranges.selection });
  };

  const formik = useFormik({
    initialValues: {
      dateRange: {
        selection: {
          startDate: null,
          endDate: null,
          key: 'selection'
        }
      },
      noOfPersons: 0,
    },
    validationSchema: Yup.object({
      dateRange: Yup.object().shape({
        selection: Yup.object().shape({
          startDate: Yup.string().required('Start date is required'),
          endDate: Yup.string().required('Date range is required')
            .test("is-greater", `Car can relocate between ${rangeStart} to ${rangeEnd}`,
              function (value) {
                const { startDate } = this.parent;
                // console.log('startDate', startDate, 'value', value);
                return isDateRangeWithin(startDate, value, rangeStart, rangeEnd);
              }),
        })
      }),
      noOfPersons: Yup.number().min(1, 'At least 1 person required').required('No of persons is required')
        .test('seatingCapacity', `Max seating capacity is ${seatingCapacity}`, function (value) {
          return value <= seatingCapacity;
        }),
    }),
    onSubmit: handleBookClick
  });

  useEffect(() => {
    const sidemenu: any = document.getElementsByClassName('rdrDefinedRangesWrapper');
    const topmenu: any = document.getElementsByClassName('rdrDateDisplayWrapper');
    if (sidemenu[0]) sidemenu[0].style.display = "none";
    if (topmenu[0]) topmenu[0].style.display = "none";
  }, [])

  useEffect(() => {
    if (carAvailabilityData) {
      setRangeStart(dayjs(carAvailabilityData?.pickupDate).format('YYYY-MM-DD'));
      setRangeEnd(dayjs(carAvailabilityData?.dropoffDate).format('YYYY-MM-DD'));
    }
  }, [carAvailabilityData])


  return (
    <form onSubmit={formik.handleSubmit}
    // className='days_info'
    >
      <DateRangePicker
        ranges={[formik?.values?.dateRange.selection]}
        onChange={handleDateChange}
        locale={enUS}
        showDateDisplay={false}
        // rangeColors={['#4caf50']}
        minDate={new Date()}
      // staticRanges={[]}
      // inputRanges={[]}
      />
      {formik.errors?.dateRange?.selection?.endDate && (
        <div className="error text-danger font-short mb-2" style={{ marginLeft: '14px' }}>{formik.errors?.dateRange?.selection?.endDate}</div>
      )}

      <div className="col-md-12">
        <InputField
          type="number"
          label="Number of persons"
          placeholder="Enter number"
          name='noOfPersons'
          value={formik?.values?.noOfPersons}
          onChange={(e: any) => formik.setFieldValue("noOfPersons", e.target.value)}
          required={true}
        />
        {formik.errors?.noOfPersons && (
          <div className="error text-danger font-short" style={{ marginLeft: '15px' }}>{formik.errors?.noOfPersons}</div>
        )}
      </div>

      <Button
        variant="contained"
        color="primary"
        sx={{ width: '100%', mt: 2, textTransform: 'none', fontSize: '16px' }}
        disabled={!formik.dirty || !formik.isValid}
        type="submit"
      >
        Book Car
      </Button>
    </form>
  );
};

export default MultiRangeCalender;
