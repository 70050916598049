import React, { useState } from "react";
import SettingsSidebar from "../../../user/settings/settingssidebar";
import ProfileSection from "./ProfileSection";
import NotificationSection from "./NotificationSection";
import SecuritySection from "./SecuitySection";
import DashboardLayout from "../DashboardLayout";
import FreelanceDocumnets from "./FreelanceDocumnets";

const UserSettings = () => {
  const [selectedSection, setSelectedSection] = useState("Profile");

  const renderSection = () => {
    switch (selectedSection) {
      case "Profile":
        return <ProfileSection />;
      case "Security":
        return <SecuritySection />;
      case "Notifications":
        return <NotificationSection />;
      case "Documents":
        return <FreelanceDocumnets />;
      default:
        return <ProfileSection />;
    }
  };

  return (
    <>
      <DashboardLayout title="User Settings" subtitle="User Settings">
        <div className="row">
          <SettingsSidebar
            setSelectedSection={setSelectedSection}
            selectedSection={selectedSection}
          />
          <div className="col-lg-9">
            <div className="settings-info">
              <div className="settings-sub-heading">
                <h4>{selectedSection}</h4>
              </div>
              {renderSection()}
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};

export default UserSettings;

