import React from "react";

const RelocationStatus = ({ status }: any) => {
  const getStatusClass = (status: string) => {
    switch (status) {
      // Payment Status
      case "Pending":
        return "badge badge-light-warning";
      case "Success":
        return "badge badge-light-success";
      case "Failed":
        return "badge badge-light-danger";

      // Relocation Status
      case "Upcoming":
        return "badge badge-light-secondary";
      case "Inprogress":
        return "badge badge-light-warning";
      case "Completed":
        return "badge badge-light-success";
      case "Cancelled":
        return "badge badge-light-danger";

      default:
        return "";
    }
  };

  return <span className={getStatusClass(status)}>{status}</span>;
};

export default RelocationStatus;
