import React from 'react';
import ImageWithBasePath from '../../../../core/data/img/ImageWithBasePath';
import { Link } from 'react-router-dom';
import ModalWrapper from './ModalWrapper';

const SuccessModal = ({ title, message, orderId, link, linkText, modalId }:any) => {
  return (
    <ModalWrapper id={modalId} title={title} modalSize="modal-md">
      <div className="order-success-info">
        <span className="order-success-icon">
          <ImageWithBasePath src="assets/img/icons/check-icon.svg" alt="Icon" />
        </span>
        <h4>{title}</h4>
        <p>
          {message} {orderId && <span>Order id : {orderId}</span>}
        </p>
        <div className="modal-btn">
          <Link to={link} className="btn btn-secondary">
            {linkText} <i className="feather icon-arrow-right" />
          </Link>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default SuccessModal;
