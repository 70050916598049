import React, { useEffect } from "react";
// import { all_routes } from "../router/all_routes";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../../core/data/img/ImageWithBasePath";

// Configuration objects
const companyInfo = {
  about: "With a team of experienced professionals and a commitment to reliability, we handle every step of the transport process with precision. Trust us to deliver exceptional service. Our customer-centric approach ensures your needs are met efficiently and effectively. Choose us for a seamless and dependable transport experience."
};

const quickLinks = [
  { text: "My Account", to: "#" },
  { text: "How it works", to: "#" },
  { text: "Terms & Conditions", to: "#" },
  { text: "Privacy Policy", to: "#" },
  { text: "Services", to: "#" },
];

const contactInfo = [
  { type: "location", icon: "feather icon-map-pin", value: "3517 W. Gray St. Utica, Pennsylvania 57867", link: "mailto:support@example.com" },
  { type: "phone", icon: "feather icon-phone-call", value: "+1(888)7601940", link: "tel:+1(888)7601940" },
  { type: "email", icon: "feather icon-mail", value: "support@example.com", link: "mailto:support@example.com" }
];

const socialLinks = [
  { icon: "fa-brands fa-facebook-f fa-facebook fi-icon", to: "#" },
  { icon: "fab fa-twitter fi-icon", to: "#" },
  { icon: "fab fa-linkedin fi-icon", to: "#" }
];

const paymentIcons = [
  { src: "assets/img/icons/paypal.svg", alt: "Paypal" },
  { src: "assets/img/icons/visa.svg", alt: "Visa" },
  { src: "assets/img/icons/master.svg", alt: "Master" },
  { src: "assets/img/icons/applegpay.svg", alt: "applegpay" }
];

const Footer = () => {
  //const routes = all_routes;

  useEffect(() => {
    AOS.init({ duration: 1000 });
    const handleScroll = () => AOS.refresh();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {/* Footer */}
      <footer className="footer">
        {/* Footer Top */}
        <div className="footer-top aos">
          <div className="footer-container" data-aos="fade-down">
            <div className="row">
              <div className="col-lg-5">
                <div className="row">
                  <div className="col-lg-8 col-md-6">
                    {/* Footer Widget */}
                    <div className="footer-widget footer-menu">
                      <h5 className="footer-title">About Company</h5>
                      <p>{companyInfo.about}</p>
                      <div className="subscribe-container">
                        <div>Subscribe to our newsletter</div>
                        <div className="subscribe_btn">Subscribe</div>
                      </div>
                    </div>
                    {/* /Footer Widget */}
                  </div>
                  <div className="col-lg-4 col-md-6">
                    {/* Footer Widget */}
                    <div className="footer-widget footer-menu">
                      <h5 className="footer-title">Quick links</h5>
                      <ul>
                        {quickLinks.map((link, index) => (
                          <li key={index}>
                            <Link to={link.to}>{link.text}</Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                    {/* /Footer Widget */}
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="footer-contact footer-widget">
                  <h5 className="footer-title">Contact Info</h5>
                  <div className="footer-contact-info" style={{width:'50%'}}>
                    {contactInfo.map((info, index) => (
                      <div className="footer-address" key={index}>
                        <span><i className={info.icon} /></span>
                        <div className="addr-info">
                          <Link to={info.link}>{info.value}</Link>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="footer-social-widget">
                    <ul className="nav-social">
                      {socialLinks.map((social, index) => (
                        <li key={index}>
                          <Link to={social.to}><i className={social.icon} /></Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Footer Top */}
        {/* Footer Bottom */}
        <div className="footer-bottom">
          <div className="container">
            {/* Copyright */}
            <div className="copyright">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <div className="copyright-text">
                    <p>© 2024 FLEXYMOVE. All Rights Reserved.</p>
                  </div>
                </div>
                <div className="col-md-6">
                  {/* Copyright Menu */}
                  <div className="copyright-menu">
                    <div className="vistors-details">
                      <ul className="d-flex">
                        {paymentIcons.map((icon, index) => (
                          <li key={index}>
                            <Link to="#"><ImageWithBasePath className="img-fluid" src={icon.src} alt={icon.alt} /></Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  {/* /Copyright Menu */}
                </div>
              </div>
            </div>
            {/* /Copyright */}
          </div>
        </div>
        {/* /Footer Bottom */}
      </footer>
      {/* /Footer */}
    </>
  );
};

export default Footer;
