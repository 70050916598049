import React from 'react'

const InvoicePaymentDetails = ({ paymentMethod, totalAmount }:any) => {
    return (
      <>
        <div className="payment-details">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="invoice-terms">
                <h6>Payment Details</h6>
                <div className="invocie-note">
                  <p>{paymentMethod.replace(/\n/g,)}</p>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-6 col-md-6">
              <div className="invoice-total-box">
                <div className="invoice-total-inner">
                  {paymentDetails.map((detail:any, index:any) => (
                    <p key={index}>
                      {detail.label} <span>{detail.amount}</span>
                    </p>
                  ))}
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="invoice-total">
          <h4>
            Total Amount <span>{totalAmount}</span>
          </h4>
        </div>
      </>
    );
  };
  

export default InvoicePaymentDetails