import React from "react";
import DashBoardItem from "../../../common/ui_components/DashBoardItem";
// import { all_routes } from "../../../router/all_routes";

const DashboardItemList = ({ listData }: any) => {
  // const routes = all_routes;

  return (
    <div className="row">
      {listData?.length > 0 && listData?.map(({ title, value, iconSrc, linkTo, linkText, iconClass }: any, index: string) => (
        <DashBoardItem
          key={index}
          title={title}
          value={value}
          iconSrc={iconSrc}
          linkTo={linkTo}
          linkText={linkText}
          iconClass={iconClass}
        />
      ))}
    </div>
  );
};

export default DashboardItemList;
