import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { doGetApiCall } from '../../utils/apiconfig';



const BASE_API_URL = process.env.REACT_APP_PUBLIC_BASE_API_URL;

// <------------------------- Thunk For Login ------------------------->
export const fetchTransactions: any = createAsyncThunk('/transaction/fetchTransactions', async (body?: any) => {
    // console.log("body ===>", body);

    if (body?.endpoint) {
        try {
            const res: any = await doGetApiCall({ url: `${BASE_API_URL}/${body.endpoint}` });
            // console.log("Api Response in thunk --------->", res);
            // if (!res.error) handleUserData(res?.result);
            // else handleError("Failed to fetch user data.");
            return res;
        } catch (err) {
            console.error("Error in fetching cars list:", err);
            // handleError("Error fetching user data.");
        }
    }
});

interface TransactionState {
    loading: 'idle' | 'pending' | 'succeeded' | 'failed',
    totalAmount: number,
    transactionList: any[],
}

const initialState: TransactionState = {
    loading: 'idle',
    totalAmount: 0,
    transactionList: [],
}

const carSlice = createSlice({
    name: 'transaction',
    initialState,

    // Reducer Funcs return new updated state based on the action
    reducers: {
        // reducerFunc(state, action) {
        //     state.transactionList = action.payload;
        // },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed

        // <------ Reducers for fetchTransactions ------> 
        builder.addCase(fetchTransactions.pending, (state) => {
            state.loading = 'pending';
        });
        builder.addCase(fetchTransactions.fulfilled, (state, action) => {
            state.loading = 'succeeded';
            const { payload } = action;

            if (!payload?.error && payload?.code === 'ALL_TRANSACTIONS_FETCHED') {
                state.totalAmount = payload?.result?.totalAmount;
                state.transactionList = payload?.result?.transactions;
            }
        })
        builder.addCase(fetchTransactions.rejected, (state, action) => {
            state.loading = 'failed';
            if (action?.payload?.error) state.transactionList = [...state.transactionList];
        })
    },
})

// Action creators are generated for each case reducer function
// export const { } = carSlice.actions;
export default carSlice.reducer;