import React from "react";


const ServingLocationSection = () => {

  return (
    <section className="section serving-location popular-explore">
      <div className="card_container">
        <div className="section-heading" data-aos="fade-down">
          <h2>Locations we serve</h2>
          <p>We provide our services in various regions, ensuring widespread accessibility. </p>
        </div>
          <div className="map-section" data-aos="fade-down">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3682.870405699623!2d88.46225287597733!3d22.621314079457033!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f89f003469095b%3A0xa63d75f3a277675c!2sUnderscore!5e0!3m2!1sen!2sin!4v1723025091940!5m2!1sen!2sin" width="100%" height="100%" loading="lazy"></iframe>
          </div>
      </div>
    </section>
  );
}

export default ServingLocationSection;