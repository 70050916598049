import React from 'react'
import Autocomplete from "react-google-autocomplete";
import { Dropdown } from "primereact/dropdown";
import DropdownOptions from "./OptionDropdown";
import IconInputField from '../../common/ui_components/InputFields/IconInputField';

const { countryOptions } = DropdownOptions();

const PickUpLocation = ({ formik }: any) => {


    const getAddressComponent = (addressComponents: any, type: any) => {
        const component = addressComponents?.find((component: any) =>
            component.types.includes(type)
        );
        return component ? component.long_name : "";
    };


    return (
        <>
            <p>Pickup Location Details</p>
            <div className="d-flex align-items-center over-mt mb-4">
                <div className="border-line"></div>
                <div className={`exc-border`}></div>
            </div>
            <div className="input-block">
                <label className="form-label">Street Address</label>
                <Autocomplete
                    apiKey={process.env.REACT_APP_PUBLIC_GOOGLE_API_KEY}
                    onPlaceSelected={(place: any) => {
                        const addressComponents = place?.address_components;
                        const streetAddress = place?.formatted_address;
                        const country = getAddressComponent(addressComponents, "country");
                        const city = getAddressComponent(addressComponents, "locality") || getAddressComponent(addressComponents, "administrative_area_level_1");
                        const pincode = getAddressComponent(addressComponents, "postal_code");

                        // Set Formik values when a place is selected
                        formik.setFieldValue("pickupStreetAddress", streetAddress);
                        formik.setFieldValue("pickupLat", place?.geometry?.location?.lat());
                        formik.setFieldValue("pickupLng", place?.geometry?.location?.lng());
                        formik.setFieldValue("pickupCountry", country);
                        formik.setFieldValue("pickupCity", city);
                        formik.setFieldValue("pickupPincode", pincode);
                    }}
                    placeholder="Enter Street Address"
                    className="form-control"
                    options={{ types: ["address"] }}
                    value={formik.values.pickupStreetAddress}
                    onChange={(e: any) => formik.setFieldValue("pickupStreetAddress", e.target.value)}
                />

                {formik.touched.pickupStreetAddress && formik.errors.pickupStreetAddress && <div className="error text-danger font-short">{formik.errors.pickupStreetAddress}</div>}
            </div>
            <div className="row row-cols-1 row-cols-md-3 mb-2">
                {/* pickupCountry */}
                <div className="input-block">
                    <label className="form-label">Country</label>
                    <Dropdown
                        // value={formik.values.pickupCountry}
                        value={countryOptions.find((country) => country.name === formik.values.pickupCountry)}
                        onChange={(e: any) => { formik.setFieldValue("pickupCountry", e.value.name) }}
                        options={countryOptions}
                        optionLabel="name"
                        className="w-100"
                    />
                    {formik.touched.pickupCountry && formik.errors.pickupCountry && <div className="error text-danger font-short mt-2">{formik.errors.pickupCountry}</div>}
                </div>

                {/* pickupCity */}
                <div className="input-block">
                    <IconInputField
                        label="City"
                        placeholder="Enter City"
                        // iconClass="feather icon-user"
                        value={formik.values.pickupCity}
                        onChange={(e) => formik.setFieldValue("pickupCity", e.target.value)}
                    />
                    {formik.touched.pickupCity && formik.errors.pickupCity && <div className="error text-danger font-short"> {formik.errors.pickupCity} </div>}
                </div>

                {/* pickupPincode */}
                <div className="input-block">
                    <IconInputField
                        label="Pincode"
                        placeholder="Enter Pincode"
                        // iconClass="feather icon-user"
                        value={formik.values.pickupPincode}
                        onChange={(e) => formik.setFieldValue("pickupPincode", e.target.value)}
                    />
                    {formik.touched.pickupPincode && formik.errors.pickupPincode && <div className="error text-danger font-short"> {formik.errors.pickupPincode} </div>}
                </div>
            </div>
        </>
    )
}

export default PickUpLocation