import React from "react";

const BillingInfoHeader = () => (
  <div className="booking-info-head justify-content-between">
    <div className="d-flex align-items-center">
      <span>
        <i className="bx bx-add-to-queue" />
      </span>
      <h5>Billing Info</h5>
    </div>
  </div>
);

export default BillingInfoHeader;
