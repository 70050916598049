import React, { useEffect, useState } from "react";
import ListHeader from "./ListHeader";
import DashboardBookingTable from "../../../common/ui_components/DashboardBookingTable";
import { useSelector } from "react-redux";
import { all_routes } from "../../../router/all_routes";
// import useDashboardList from "../../../../utils/hooks/useDashboardList";



interface Car {
  imgSrc: string;
  carName: string;
  carType: string;
  startDate: string;
  endDate: string;
  price: string;
  paymentStatus: string;
  relocationStatus: string;

  carId: string;
  stepCompleted: number;
  rentAmount: string;
  route: string;
}

const UserRecentRelocation = ({ options, filterQuery, setFilterQuery }: any) => {
  // const { filterQuery, setFilterQuery } = useDashboardList();

  const carList = useSelector((state: any) => state?.carReducer?.carList);

  const [carListData, setCarListData] = useState<Car[]>([]);
  const [title, setTitle] = useState<string>('Recent Relocations');

  // Only for formating lsit data
  useEffect(() => {
    if (carList?.length > 0) {
      const formatedCarList = carList.map((car: any) => {
        return {
          // UI Data
          imgSrc: car?.images?.[0]?.key ? `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/medias?key=${car?.images?.[0]?.key}&width=${100}` : '/assets/img/cars/car-01.jpg',
          carName: car?.modelName || 'Model Name',
          carType: car?.carCategory?.name || 'NA',
          startDate: car?.pickupDate || 'On DD MM YYYY, 00:00 AM/PM',
          endDate: car?.dropoffDate || 'On DD MM YYYY, 00:00 AM/PM',
          price: car?.rentAmount,
          paymentStatus: car?.paymentStatus,
          relocationStatus: car?.bookingInfo?.status,

          // Extra data
          carId: car?._id,
          stepCompleted: car?.stepCompleted,
          rentAmount: car?.rentAmount,
          route: all_routes.userDashboard,
        }
      });
      setCarListData(formatedCarList);
    }
  }, [carList])

  useEffect(() => {
    // console.log('filterQuery ------>', filterQuery);
    if (filterQuery.includes('time=7days')) setTitle('Last 7 days Relocation');
    if (filterQuery.includes('time=15days')) setTitle('Last 15 days Relocation');
    if (filterQuery.includes('time=30days')) setTitle('Last 30 days Relocation');
    if (filterQuery.includes('time=lastMonth')) setTitle('Last Month Relocations');
  }, [filterQuery])

  return (
    <div className="col-lg-8 d-flex">
      <div className="card user-card flex-fill">
        <ListHeader options={options} title={title} filterQuery={filterQuery} setFilterQuery={setFilterQuery} />
        <div className="card-body p-0">
          {carListData?.length > 0 ? <DashboardBookingTable carList={carListData} /> : 'No data found'}
        </div>
      </div>
    </div>
  );
};

export default UserRecentRelocation;