import React, { useState } from "react";
import BookingFormSection from "../BookingFormSection";

const InputField = ({
  label,
  type = "text",
  placeholder,
  required,
  value,
  onChange,
  onBlur,
  name,
  className,
  readOnly,
}: any) => {
  const [isToggle, setIsToggle] = useState(false);

  // Handle trimming onBlur
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const trimmedValue = e.target.value.trim();

    // Update value on blur to reflect trimming
    if (onChange) {
      const changeEvent = { ...e, target: { ...e.target, value: trimmedValue } };
      onChange(changeEvent as React.ChangeEvent<HTMLInputElement>);
    }

    // Trigger onBlur with the trimmed value
    if (onBlur) {
      const blurEvent = { ...e, target: { ...e.target, value: trimmedValue } };
      onBlur(blurEvent as React.FocusEvent<HTMLInputElement>);
    }
  };

  const inputType = type === "password" ? (isToggle ? "text" : "password") : type;
  const iconClass = type === "password" ? (isToggle ? "fa-eye" : "fa-eye-slash") : "";

  return (
    <BookingFormSection className={className}>
      <label className="form-label">
        {label} {required && <span className="text-danger"> *</span>}
      </label>
      <div className="input-container">
        <input
          type={inputType}
          className="form-control-without-icon pass-input-three"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={handleBlur} // Handle trimming on blur
          // onBlur={onBlur}
          name={name}
          readOnly={readOnly}
        />
        {type === "password" && (
          <span
            className={`fas toggle_password ${iconClass}`}
            onClick={() => setIsToggle(!isToggle)}
          />
        )}
      </div>
    </BookingFormSection>
  );
};

export default InputField;
