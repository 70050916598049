import React from "react";
import DriverBannerCard from "../../common/ui_components/cards/DriverBannerCard";
import { getCookie } from "../../../utils/cookieUtils";

const DriverBannerSection = () => {
  const token = getCookie('token');

  return (
    <section className="relocation-banner ">
      <div className="row align-items-center card-container driver-section">
        {
          !token && <div className="col-lg-4 left-section" data-aos="fade-down">
            <DriverBannerCard />
          </div>
        }
        <div className="col-lg-8 driver-banner-img-container" data-aos="fade-down">
          <h1>
            <span style={{ color: '#00CCFF' }}>Efficient</span> and <br />
            <span className="banner-gradient-text " style={{ color: '#0076D7' }}>Cost Effective</span> Fleet <br /> Relocation Solutions
          </h1>
          <p style={{ color: '#fff' }}>
            Streamline your fleet management with our tailored services for enterprises.<br />
            Trust our expert solutions to ensure your fleet operates smoothly and complies<br />
            with all regulations.
          </p>
        </div>
      </div>
    </section>
  );
}

export default DriverBannerSection;