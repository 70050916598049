import React from 'react';
import { Link } from 'react-router-dom';

// type Props = {
//     label?: string | undefined;
//     onClick?: () => void;
//     className?: string;
//     to?: string;
// };

const Button = ({ label, onClick, className, to, type}: any) => {

    if (to) {
        return (
            <Link to={to} className={className} onClick={onClick}>
                {label}
            </Link>
        );
    }
    return (
        <button className={className} onClick={onClick} type={type}>
            {label}
        </button>
    );
};

export default Button;
