import React from "react";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../../core/data/img/ImageWithBasePath";

type DashboardMenuItemProps =  {
  route: string;
  iconSrc: string;
  label: string;
  isActive: (route: string) => boolean;
  additionalRoutes?: string[];
}

const DashboardMenuItem = ({ route, iconSrc, label, isActive, additionalRoutes = [] }: DashboardMenuItemProps) => {
  const isActiveClass = isActive(route) || additionalRoutes.some(isActive) ? "active" : "";

  return (
    <li>
      <Link to={route} className={isActiveClass}>
        <ImageWithBasePath src={iconSrc} alt={`${label} Icon`} />
        <span>{label}</span>
      </Link>
    </li>
  );
};

export default DashboardMenuItem;
