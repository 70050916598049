import React from "react";
import ImageWithBasePath from "../../../core/data/img/ImageWithBasePath";

type Step = {
  iconSrc: string;
  title: string;
  isCompleted: boolean;
};

type BillingStepsProps = {
  steps: Step[];
};

const BillingSteps: React.FC<BillingStepsProps> = ({ steps }) => {
  return (
    <div className="col-xl-8 col-lg-9">
      <div className="booking-wizard-lists">
        <ul>
          {steps.map((step, index) => (
            <li
              key={index}
              className={step.isCompleted ? "active activated" : ""}
            >
              <span>
                <ImageWithBasePath
                  src={step.iconSrc}
                  alt={`${step.title} Icon`}
                />
              </span>
              <h6>{step.title}</h6>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default BillingSteps;
