import React from 'react';
import InputField from '../../../common/ui_components/InputFields/InputField';
import LocationAutocomplete from 'react-google-autocomplete';
import { useSelector } from 'react-redux';

const ProfileAddressSection = ({ formik }: any) => {

  const login_data = useSelector((state: any) => state?.authReducer?.login_data);

  const getAddressComponent = (addressComponents: any, type: any) => {
    const component = addressComponents.find((component: any) => component.types.includes(type));
    return component ? component.long_name : "";
  };

  return (
    <div className="profile-info-grid">
      <div className="profile-info-header">
        <h5>Address Information</h5>
        <p>Information about the address of user</p>
      </div>
      <div className="profile-inner">
        <div className="row">
          {/* Address */}
          <div className="input-block col-md-12">
            <label className="form-label">Street Address</label>
            <div style={{ pointerEvents: !login_data?.phoneVerified ? "none" : "auto", opacity: !login_data?.phoneVerified ? 0.5 : 1 }}>
              <LocationAutocomplete
                apiKey={process.env.REACT_APP_PUBLIC_GOOGLE_API_KEY}
                onPlaceSelected={(place: any) => {
                  // console.log("location place -->", place);

                  const addressComponents = place.address_components;
                  const streetAddress = place.formatted_address;
                  const city = getAddressComponent(addressComponents, "locality") || getAddressComponent(addressComponents, "administrative_area_level_1"); // Fallback for certain regions
                  const state = getAddressComponent(addressComponents, "administrative_area_level_1") || getAddressComponent(addressComponents, "administrative_area_level_2") || getAddressComponent(addressComponents, "administrative_area_level_3"); // Fallback for certain regions
                  const country = getAddressComponent(addressComponents, "country");
                  const pincode = getAddressComponent(addressComponents, "postal_code");

                  // Set Formik values
                  formik.setFieldValue("address", streetAddress);
                  formik.setFieldValue("city", city);
                  formik.setFieldValue("state", state);
                  formik.setFieldValue("country", country);
                  formik.setFieldValue("pincode", pincode);
                }}
                placeholder="Enter Street Address"
                className="form-control px-3"
                defaultValue={formik?.values?.address}
                options={{ types: ["address"] }} // Changed to "address" to get more accurate results for the full address
              />
            </div>
            {formik.touched.address && formik.errors.address && login_data?.phoneVerified && (
              <div className="error text-danger font-short">
                {formik.errors.address}
              </div>
            )}
          </div>

          {/* Country */}
          <div className="col-md-6">
            <InputField
              label="Country"
              placeholder="Enter Country"
              value={formik.values.country}
              required
              onChange={(e: any) => formik.setFieldValue("country", e.target.value)}
              className="profile-inputfield"
              readOnly={!login_data?.phoneVerified}
            />
            {formik.touched.country && formik.errors.country && login_data?.phoneVerified && (
              <div className="error text-danger font-short" style={{ margin: '0 0 5px 5px' }}>
                {formik.errors.country}
              </div>
            )}
          </div>

          {/* State */}
          <div className="col-md-6">
            <InputField
              label="State"
              placeholder="Enter State"
              value={formik.values.state}
              required
              onChange={(e: any) => formik.setFieldValue("state", e.target.value)}
              className="profile-inputfield"
              readOnly={!login_data?.phoneVerified}
            />
            {formik.touched.state && formik.errors.state && login_data?.phoneVerified && (
              <div className="error text-danger font-short" style={{ margin: '0 0 5px 5px' }}>
                {formik.errors.state}
              </div>
            )}
          </div>

          {/* city */}
          <div className="col-md-6">
            <InputField
              label="City"
              placeholder="Enter City"
              value={formik.values.city}
              required
              onChange={(e: any) => formik.setFieldValue("city", e.target.value)}
              className="profile-inputfield"
              readOnly={!login_data?.phoneVerified}
            />
            {formik.touched.city && formik.errors.city && login_data?.phoneVerified && (
              <div className="error text-danger font-short" style={{ margin: '0 0 5px 5px' }}>
                {formik.errors.city}
              </div>
            )}
          </div>

          {/* Pincode */}
          <div className="col-md-6">
            <InputField
              label="Pincode"
              placeholder="Enter Pincode"
              value={formik.values.pincode}
              required
              onChange={(e: any) => formik.setFieldValue("pincode", e.target.value)}
              className="profile-inputfield"
              readOnly={!login_data?.phoneVerified}
            />
            {formik.touched.pincode && formik.errors.pincode && login_data?.phoneVerified && (
              <div className="error text-danger font-short" style={{ margin: '0 0 5px 5px' }}>
                {formik.errors.pincode}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileAddressSection;
