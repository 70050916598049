import React from 'react';

const ModalWrapper = ({id,title, children,modalSize = 'modal-md'}:any) => {
  return (
    <div
      className="modal new-modal fade"
      id={id}
      data-keyboard="false"
      data-backdrop="static"
    >
      <div className={`modal-dialog modal-dialog-centered ${modalSize}`}>
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">{title}</h4>
            <button
              type="button"
              className="close-btn"
              data-bs-dismiss="modal"
            >
              <span>×</span>
            </button>
          </div>
          <div className="modal-body">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default ModalWrapper;
