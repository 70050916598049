import React from "react";
import { Link } from "react-router-dom";
import Select from "react-select";



// Common component only for 'Dashboard List Layout - (Recent Relocation & Recent Transaction List)'
const ListHeader = ({ options, title, viewAll = false, setFilterQuery }: any) => {

  const handleChange = (val: any) => {
    // console.log('handleChange hit', val);

    switch (val?.value) {
      case 'last_7_days':
        setFilterQuery('?time=7days&sort=DESC')
        break;

      case 'last_15_days':
        setFilterQuery('?time=15days&sort=DESC')
        break;

      case 'last_30_days':
        setFilterQuery('?time=30days&sort=DESC')
        break;

      case 'last_month':
        setFilterQuery('?time=lastMonth&sort=DESC')
        break;

      default:
        break;
    }
  }

  return (
    <div className="card-header">
      <div className="row align-items-center">
        <div className="col-sm-5">
          <h5>{title}</h5>
        </div>
        <div className="col-sm-7 text-sm-end">
          <div className="booking-select">
            <Select
              className=" select stylewidth"
              options={options}
              placeholder={"Select Day"}
              onChange={(val) => handleChange(val)}
              defaultValue={'last_7_days'}
            />
            {viewAll && (
              <Link to="#" className="view-link">
                View all Bookings
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListHeader;
