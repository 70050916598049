import React, { useEffect } from 'react';
import InputField from '../../../common/ui_components/InputFields/InputField';
import Button from '../../../common/ui_components/buttons/blueBtn';
import ModalWrapper from './ModalWrapper';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { deactivateAcc } from '../../../../redux/reducers/userReducer';
import useAuth from '../../../../utils/hooks/useAuth';



const DeactiveAccount = () => {
  const dispatch = useDispatch();
  const { logout } = useAuth();

  const code = useSelector((state: any) => state?.userReducer?.code);

  const formik = useFormik({
    initialValues: { password: '' },
    validationSchema: Yup.object({
      password: Yup.string().required('Password is required'),   // .min(8, 'Password must be at least 8 characters long')
    }),
    onSubmit: (values, { resetForm }) => {
      // console.log("Form values ---->", values);
      dispatch(deactivateAcc(values));
      resetForm();
    },
  });

  useEffect(() => {
    if (code === 'USER_DISABLED_SUCCESSFULLY') {
      // Close the modal
      const button: any = document.querySelector('#closeBtn');
      button?.click();

      logout();
    }
  }, [code])

  return (
    <ModalWrapper id="deactive_account" title="Deactivate Account">
      <div className="deactive-content">
        <p>
          Are you sure you want to deactivate This Account? To enable your account you need to contact your admin. To deactivate your account,
          type your password.
        </p>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <InputField
          label="Password"
          placeholder="..........."
          type="password"
          name='password'
          onChange={(e: any) => formik.setFieldValue('password', e.target.value)}
          required={true}
        />
        {formik.touched?.password && formik.errors?.password && (
          <div className="error text-danger font-short" style={{ marginLeft: '15px' }}>
            {formik.errors?.password}
          </div>
        )}

        <div className="modal-btn modal-btn-sm">
          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='closeBtn'>Cancel</button>
          <Button type="submit" label="Deactivate Account" className="btn btn-primary" data-bs-dismiss="modal" />
        </div>
      </form>
    </ModalWrapper>
  );
};

export default DeactiveAccount;
