import React from "react";

const RateItem = ({ label, amount, isTotal }: any) => (
  <li className={isTotal ? "total-rate" : ""}>
    <h6>{label}</h6>
    <h5>{amount}</h5>
  </li>
);

export default RateItem;
