import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../../router/all_routes";
import ImageWithBasePath from "../../../core/data/img/ImageWithBasePath";
import { useDispatch, useSelector } from "react-redux";
import { set_is_mobile_sidebar } from "../../../redux/reducers/mobileViewRedurer";
import Button from "../ui_components/buttons/Button";
import { RiArrowDropDownLine } from "react-icons/ri";
import UserOptionsModal from "./UserOptionsModal";
import { getCookie } from "../../../utils/cookieUtils";
import useAuth from "../../../utils/hooks/useAuth";



const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { logout } = useAuth();
  const isLoggedIn = getCookie('isLoggedIn');
  const routes = all_routes;

  const mobileSidebar = useSelector((state: any) => state?.mobileViewRedurer?.mobileSidebar);
  const UserDetails = useSelector((state: any) => state?.authReducer?.login_data);

  const [show_user_option, setShowUserOPtion] = useState(false);

  const handleClick = () => {
    dispatch(set_is_mobile_sidebar(!mobileSidebar));
  };

  const menuItems = [
    { title: "Home", path: routes.MainLanding, activePath: "index" },
    { title: "Services", path: '#', activePath: "listing" },
    { title: "Pricing", path: '#', activePath: "pages" },
    { title: "How It Works", path: '#', activePath: "user" },
    { title: "Contact Us", path: routes.contactUs, activePath: "contact" },
  ];

  return (
    <header className="header">
      <div className="container-fluid">
        <nav className="navbar navbar-expand-lg header-nav">
          <div className="navbar-header">
            <div id="mobile_btn" onClick={handleClick}>
              <span className="bar-icon">
                <span />
                <span />
                <span />
              </span>
            </div>
            <Link to={routes.MainLanding} className="navbar-brand logo">
              <ImageWithBasePath
                src="assets/img/logo.png"
                className="img-fluid"
                alt="Logo"
              />
            </Link>
            <Link to={routes.MainLanding} className="navbar-brand logo-small">
              <ImageWithBasePath
                src="assets/img/logo.png"
                className="img-fluid"
                alt="Logo"
              />
            </Link>
          </div>
          <div className={`main-menu-wrapper ${mobileSidebar ? "open" : ""}`}>
            <div className="menu-header">
              <Link to={routes.MainLanding} className="menu-logo">
                <ImageWithBasePath
                  src="assets/img/logo.png"
                  className="img-fluid"
                  alt="Logo"
                />
              </Link>
              <Link
                id="menu_close"
                className="menu-close"
                to="#"
                onClick={handleClick}
              >
                <i className="fas fa-times" />
              </Link>
            </div>
            <ul className="main-nav">
              {menuItems.map((item, index) => (
                <li key={index} className={location.pathname.includes(item.activePath) ? "active" : ""}>
                  <Link to={item.path}>{item.title}</Link>
                </li>
              ))}
              {!isLoggedIn &&
                // for mobile view
                <>
                  <li className="login-link"><Link to={routes.register}>Sign Up</Link></li>
                  <li className="login-link"><Link to={routes.login}>Sign In</Link></li>
                </>
              }
            </ul>
          </div>
          <ul className="nav header-navbar-rht">
            {isLoggedIn ? (
              <div className="user_info" onClick={() => setShowUserOPtion(!show_user_option)}>
                <div className="user_avatar"></div>
                <div>{UserDetails?.user_name} <RiArrowDropDownLine className="dropdown_icon" /></div>
                {show_user_option && <UserOptionsModal logout={logout} />}
              </div>
            ) : (
              <>
                <li className="nav-item">
                  <Button label="Sign In"
                    iconClass="fa-regular fa-user"
                    onClick={() => navigate(routes.login)}
                    className="header-login" />
                </li>
                <li className="nav-item">
                  <Button label="Sign Up"
                    iconClass="fa-solid fa-lock"
                    onClick={() => navigate(routes.register)}
                    className="header-reg" />
                </li>
              </>
            )}
          </ul>

        </nav>
      </div>
    </header>
  );
};

export default Header;