import React, { useEffect, useState } from "react";
import Breadcrumbs from "../common/ui_components/breadcrumbs";
import Stepper from "@mui/material/Stepper";
import StepLabel from "@mui/material/StepLabel";
import { Box, Step } from "@mui/material";
import BillingForm from "./Forms/BillingForm";
import BookingSidebar from "../components/BookingPage/BookingSidebar";
import CheckoutPage from "./Forms/CheckoutPage";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from "./Forms/CheckoutForm";
import DetailsForm from "./Forms/DetailsForm";
import LocationForm from "./Forms/LocationForm";
import { getCookie } from "../../utils/cookieUtils";

const steps = ["Location", "Details" , "Billing", "Checkout", "Payment"];  
const stripePromise = loadStripe(`${process.env.REACT_APP_PUBLIC_STRIPE_PUBLISHABLE_KEY}`);

const RelocateCar = () => {
  const [stepperState, setStepperState] = useState(0);
  const [secret_key, setSecretKey] = useState("");
  const [intent_id, setIntentId] =  useState("")

  const options = {
    clientSecret: secret_key, 
  };

  const Stepperstep = getCookie("stepperstep") //=== null ? stepperState : getCookie("stepperstep") 

  useEffect(()=>{
    if(Stepperstep !== null){
      setStepperState(Stepperstep)
    }
  },[])

  const renderForm = () => {    
    switch (stepperState) {
      case 1 :
        return <DetailsForm setStepperState={setStepperState}/>
      case 2 :
        return <BillingForm setStepperState={setStepperState}/>;
      case 3 :
        return <CheckoutPage setStepperState={setStepperState} setSecretKey={setSecretKey} setIntentId={setIntentId}/> 
      case 4 :
        return <Elements stripe={stripePromise} options={options}>
          <CheckoutForm intent_id={intent_id}/>
        </Elements>
      default:
        return <LocationForm setStepperState={setStepperState}/>; 
    }
  };

  return (
    <>
      <Breadcrumbs title={`Relocate your car`} subtitle={`Relocate car`} />
      <div className="total-container stepper">
        <div className="px-30 py-20 w-100 upper-container">
          <div className="p-4 d-flex flex-column flex-md-row justify-content-between justify-content-md-start rounded-2 bg-white shadow-lg gap-container align-items-center">
            <div className="d-flex flex-column gap-2 text-center text-md-start w-100">
              <p className="fw-bold fs-6">Relocate Your Car</p>
              <p>Complete the following steps</p>
            </div>
            <Box sx={{ width: "100%" }}>
              <Stepper activeStep={stepperState} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </div>
        </div>
        <div className="form-container">
          {stepperState !== 5 && <BookingSidebar />}
          {renderForm()}
        </div>
      </div>
    </>
  );
};

export default RelocateCar;