import React from 'react';
import { Link } from 'react-router-dom';
import ModalWrapper from './ModalWrapper';

type Props = {
  id: string;
  title: string;
  message: string;
  onDelete?: () => void;
  onCancel?: () => void;
}

const DeleteModal = ({ id, title, message, onDelete, onCancel }: Props) => {
  return (
    <ModalWrapper id={id} title={title}>
      <div className="delete-action">
        <div className="delete-header">
          <p>{message}</p>
        </div>
        <div className="modal-btn">
          <div className="row">
            <div className="col-6">
              <Link to="#" onClick={onDelete} data-bs-dismiss="modal" className="btn btn-secondary w-100"  >
                Delete
              </Link>
            </div>
            <div className="col-6">
              <Link to="#" onClick={onCancel} data-bs-dismiss="modal" className="btn btn-primary w-100" >
                Cancel
              </Link>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default DeleteModal;
