import React, { useState, useEffect } from "react";
import { all_routes } from "../../router/all_routes";
import Button from "../../common/ui_components/buttons/blueBtn";
import ImageWithBasePath from "../../../core/data/img/ImageWithBasePath";


const BannerSection = () => {
  const routes = all_routes;


  const images = [
    "assets/img/cars/banner1.png",
    "assets/img/cars/banner2.png",
    "assets/img/cars/banner3.png"
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <section className="banner-section banner-slider">
      <div className="home-banner">
        <div className="row align-items-center">
          <div className="col-lg-5" data-aos="fade-down">
            <p className="explore-text">
              100% Trusted car rental platform in the World
            </p>
            <h1>
              <span style={{ color: '#00CCFF' }}>Efficient</span> and <br />
              <span className="banner-gradient-text " style={{ color: '#0076D7' }}>Cost Effective</span> Fleet Relocation Solutions
            </h1>
            <p className="banner-desc">
              Streamline your fleet management with our tailored services for enterprises.
              Trust our expert solutions to ensure your fleet operates smoothly and complies
              with all regulations.
            </p>
            <div className="view-all">
              <Button label="Get a Quote"
                className="btn-view d-inline-flex align-items-center"
                to={routes.Relocation}
              />
            </div>
          </div>
          <div className="col-lg-7" data-aos="fade-down">
            <div className="banner-imgs">
              <ImageWithBasePath
                src={images[currentImageIndex]}
                className="img-fluid aos"
                alt="bannerimage"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BannerSection;