import React, { useState } from 'react';
import DropdownFilter from '../../common/ui_components/DropDown';
import ViewSwitcher from '../../common/ui_components/ViewSwitcher';
import { numberOptions, sortOptions } from '../../../core/data/json/common_static_data';

const TabListSection = ({ setCurrentView, currentView, totalCount}:any) => {
  const [selectedNumber, setSelectedNumber] = useState(null);
  const [selectedSort, setSelectedSort] = useState(null);

  return (
    <div className="card_container">
      <div className="sortby-sec">
        <div className="sorting-div">
          <div className="row d-flex align-items-center">
            <div className="col-xl-4 col-lg-3 col-sm-12 col-12">
              <div className="count-search">
                <p>Showing 1-{totalCount} of {totalCount} Cars</p>
              </div>
            </div>
            <div className="col-xl-8 col-lg-9 col-sm-12 col-12">
              <div className="product-filter-group">
                <div className="sortbyset">
                  <ul>
                    <li>
                      <span className="sortbytitle">Show: </span>
                      <div className="sorting-select select-one">
                        <DropdownFilter
                          value={selectedNumber}
                          onChange={setSelectedNumber}
                          options={numberOptions}
                          placeholder="5"
                          className="w-100"
                        />
                      </div>
                    </li>
                    <li>
                      <span className="sortbytitle">Sort By </span>
                      <div className="sorting-select select-two">
                        <DropdownFilter
                          value={selectedSort}
                          onChange={setSelectedSort}
                          options={sortOptions}
                          placeholder="Newest"
                          className="w-100"
                        />
                      </div>
                    </li>
                  </ul>
                </div>
                <ViewSwitcher setCurrentView={setCurrentView} currentView={currentView}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TabListSection;
