import React from 'react'
import InvoiceContainer from '../../InvoicePage/InvoiceContainer'
import { invoiceData } from '../../../../core/data/json/common_static_data'
import IconButton from '../../../common/ui_components/buttons/IconButton'

const InvoiceModel = () => {
  return (
    <div
      className="modal new-modal fade"
      id="view_invoice"
      data-keyboard="false"
      data-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered modal-xl">
        <div className="modal-content">
          <div className="modal-header border-0 m-0 p-0">
            <div className="invoice-btns">
              <IconButton label="Print" iconClass="feather icon-printer" />
              <IconButton label="Download Invoice" iconClass="feather icon-download" />
            </div>
          </div>
          <div className="invoice-section">
            <InvoiceContainer invoiceData={invoiceData} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default InvoiceModel