import React from 'react'
import { Link } from 'react-router-dom'
import { all_routes } from '../../router/all_routes';

const Policies = () => {
  const routes = all_routes;
  return (
    <div className="review-sec">
      <div className="review-header">
        <h4>Policies</h4>
      </div>
      <div className="policy-list">
        <div className="policy-item">
          <div className="policy-info">
            <h6>Privacy Policy</h6>
            <p>
              Cancellation charges will be applied as per the policy
            </p>
          </div>
          <Link to={routes.privacyPolicy}>Know More</Link>
        </div>
        <div className="policy-item">
          <div className="policy-info">
            <h6>Terms and Conditions</h6>
            <p>
              I hereby agree to the terms and conditions of the
              Lease Agreement with Host
            </p>
          </div>
          <Link to={routes.privacyPolicy}>View Details</Link>
        </div>
      </div>
    </div>
  )
}

export default Policies