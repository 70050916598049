import React from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';

interface CheckoutFormProps {
  // secret_key: string;
  intent_id: string;
}

const CheckoutForm: React.FC<CheckoutFormProps> = ({ intent_id }) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string | null>(null);
  const stripe = useStripe();
  const elements = useElements();
  const hostname = window.location.hostname;

  // React.useEffect(() => {

  //   if (!stripe) {
  //     return;
  //   }

  //   stripe.retrievePaymentIntent(secret_key).then(({ paymentIntent }: any) => {
  //     console.log("paymentIntent ##",paymentIntent)
  //     switch (paymentIntent.status) {
  //       case "succeeded":
  //         setMessage("Payment succeeded!");
  //         break;
  //       case "processing":
  //         setMessage("Your payment is processing.");
  //         break;
  //       case "requires_payment_method":
  //         setMessage("Your payment was not successful, please try again.");
  //         break;
  //       default:
  //         setMessage("");
  //     }
  //   });
  // }, [stripe, secret_key]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!stripe || !elements) return;

    setIsLoading(true);
    const returnUrl = hostname === 'localhost'
      ? `${process.env.REACT_APP_LOCAL_URL}?intent_id=${intent_id}`
      : `${process.env.REACT_APP_DEV_URL}?intent_id=${intent_id}`;
      intent_id
    const { error }:any = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: returnUrl,
      },
    });

    // if (error) {
    //   setMessage(error.message || "An unexpected error occurred.");
    //   setIsLoading(false);
    //   return;
    // }

    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }
    
    setIsLoading(false);
  };

  // const paymentElementOptions = {
  //   layout: "tabs"
  // }


  return (
    <form onSubmit={handleSubmit} className='payment-form'>
      {message && <p style={{ color: 'red', margin: '10px 0' }}>{message}</p>}
      <PaymentElement id="payment-element" />  {/* options={paymentElementOptions} */}
      <button disabled={isLoading || !stripe || !elements} id="submit" className='payment-button'>
        {isLoading ? "Loading..." : "Pay Now"}
      </button>
    </form>
  );
};

export default CheckoutForm;


// import React from 'react';
// import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';

// const CheckoutForm = ({ secret_key, intent_id }: any) => {
//   const [isLoading, setIsLoading]: any = React.useState(false);
//   const [message, setMessage]: any = React.useState(null);
//   const stripe = useStripe();
//   const elements = useElements();
//   const hostname = window.location.hostname;


//   React.useEffect(() => {
//     if (!stripe) {
//       return;
//     }
//     const clientSecret = secret_key;
//     if (!clientSecret) {
//       return;
//     }

//     stripe.retrievePaymentIntent(clientSecret).then((paymentIntent: any) => {
//       switch (paymentIntent.status) {
//         case "succeeded":
//           setMessage("Payment succeeded!");
//           break;
//         case "processing":
//           setMessage("Your payment is processing.");
//           break;
//         case "requires_payment_method":
//           setMessage("Your payment was not successful, please try again.");
//           break;
//         default:
//           setMessage("");
//           break;
//       }
//     });
//   }, [stripe]);


//   const handleSubmit = async (e: any) => {
//     e.preventDefault();

//     if (!stripe || !elements) {
//       return;
//     }

//     setIsLoading(true);
//     const returnUrl = hostname === 'localhost' ? `${process.env.REACT_APP_LOCAL_URL}?intent_id=${intent_id}` : `${process.env.REACT_APP_DEV_URL}?intent_id=${intent_id}`;

//     const error: any = await stripe.confirmPayment({
//       elements,
//       confirmParams: {
//         return_url: returnUrl
//       },
//     });
//     if (error.type === "card_error" || error.type === "validation_error") {
//       setMessage("An unexpected error occurred.");
//       setMessage(error.message);
//       setIsLoading(false);
//     } else {
//       setIsLoading(false);
//       console.log(error, "show stripe error")
//       setMessage(error?.error?.message);
//     }
//     setIsLoading(false);
//   };

//   return (
//     <form onSubmit={handleSubmit} className='payment-form'>
//       <div>
//         <p style={{ color: 'red', margin: '10px 0' }}>{message}</p>
//       </div>
//       <PaymentElement />
//       <button disabled={isLoading || !stripe || !elements} id="submit" className='payment-button'>
//         {isLoading ? "Loading..." : "Pay Now"}
//       </button>
//     </form>
//   );
// };

// export default CheckoutForm;