import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BookingHeader } from "./ViewBooking";
import DatePicker from "../../../common/ui_components/DatePicker";
import TimePicker from "../../../common/ui_components/TimePicker";
// import type { Dayjs } from "dayjs";
import ModalWrapper from "./ModalWrapper";

// Button component
const ModalButton = ({ children, className, ...props }:any) => (
  <Link {...props} className={`btn ${className}`}>
    {children}
  </Link>
);

// Input group component
const InputGroup = ({ label, placeholder, defaultValue }:any) => (
  <div className="modal-form-group">
    <label>{label}</label>
    <input
      type="text"
      className="form-control"
      placeholder={placeholder}
      defaultValue={defaultValue}
    />
  </div>
);

// Location selection component
const LocationSelection = () => (
  <div className="booking-wrapper">
    <div className="booking-title">
      <h6>Select Location</h6>
    </div>
    <div className="row">
      <div className="col-md-12">
        <div className="loc-wrap">
          <InputGroup label="Delivery Location" placeholder="Enter Location" />
          <div className="modal-form-group">
            <label className="d-sm-block">&nbsp;</label>
            <ModalButton to="#" className="btn-secondary">
              <i className="fa-solid fa-location-crosshairs" /> Current Location
            </ModalButton>
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <InputGroup
          label="Dropoff Location"
          defaultValue="78, 10th street Laplace USA"
        />
      </div>
    </div>
  </div>
);

// Rent type selection component
const RentTypeSelection = () => (
  <div className="booking-wrapper">
    <div className="booking-title">
      <h6>
        <span className="title-icon">
          <i className="fa-solid fa-location-dot" />
        </span>
        Select Booking type &amp; Time{" "}
        <Link to="#">
          <i className="feather icon-alert-circle" />
        </Link>
      </h6>
    </div>
    <div className="row">
      {["Hourly", "Day (8 Hrs)", "Weekly", "Monthly"].map((type, index) => (
        <div className="col-lg-3 col-md-6" key={index}>
          <div className={`modal-form-group rent-radio ${index === 0 ? "active" : ""}`}>
            <label className="custom_radio">
              <input
                type="radio"
                className="rent-types"
                name="rent_type"
                defaultChecked={index === 0}
              />
              <span className="checkmark" />
              <span className="rent-option">{type}</span>
            </label>
          </div>
        </div>
      ))}
    </div>
  </div>
);

// Date and Time selection component
const DateTimeSelection = ({ date1, date2, onDate1Change, onDate2Change, onTimeChange }:any) => (
  <div className="booking-wrapper">
    <div className="half-column-group">
      <DatePicker
        label="Pickup Date"
        value={date1}
        onChange={(e: any) => onDate1Change(e.value)}
        placeholder="04/11/2023"
      />
    </div>
    <div className="half-column-group">
      <TimePicker
        label="Pickup Time"
        placeholder="11:00 AM"
        onChange={onTimeChange}
      />
    </div>
    <div className="half-column-group">
      <DatePicker
        label="Return Date"
        value={date2}
        onChange={(e: any) => onDate2Change(e.value)}
        placeholder="04/11/2023"
      />
    </div>
    <div className="half-column-group">
      <TimePicker
        label="Dropoff Time"
        placeholder="11:00 AM"
        onChange={onTimeChange}
      />
    </div>
  </div>
);

// Extra services selection component
const ExtraServices = () => (
  <div className="booking-wrapper">
    <div className="booking-title">
      <h6>
        <span className="title-icon">
          <i className="fa-solid fa-medal" />
        </span>
        Extra Service
      </h6>
    </div>
    <div className="selectbox-cont">
      {[
        { label: "Baby Seat", price: "$10" },
        { label: "Mobile Charging", price: "$50", checked: true },
        { label: "Wi-Fi Hotspot", price: "$60" },
        { label: "Airport Shuttle Service", price: "$90" },
      ].map((service, index) => (
        <label className="custom_check w-100" key={index}>
          <input
            type="checkbox"
            name="username"
            defaultChecked={service.checked}
          />
          <span className="checkmark" /> {service.label} -{" "}
          <span className="amt">{service.price}</span>
        </label>
      ))}
    </div>
  </div>
);

// Main EditBooking component
const EditBooking = () => {
  const [date1, setDate1] = useState<Date | null>(null);
  const [date2, setDate2] = useState<Date | null>(null);

  const onTimeChange = () => {
    // const timeString = Array.isArray(dateString)
    //   ? dateString.join(",")
    //   : dateString;
    // console.log(date, timeString);
  };

  return (
    <ModalWrapper
      id="edit_booking"
      title="Edit Booking"
      modalSize="modal-lg"
    >
      <div className="modal-header border-0 pb-0">
        <button type="button" className="close-btn" data-bs-dismiss="modal">
          <span>×</span>
        </button>
        <div className="badge-item w-100 text-end">
          <span className="badge badge-light-warning">Inprogress</span>
        </div>
      </div>
      <div className="modal-body">
        <BookingHeader
          carImage="assets/img/cars/car-05.jpg"
          carModel="Chevrolet Camaro"
          location="Miami St, Destin, FL 32550, USA"
          amount="$4700"
        />
        <LocationSelection />
        <RentTypeSelection />
        <DateTimeSelection
          date1={date1}
          date2={date2}
          onDate1Change={setDate1}
          onDate2Change={setDate2}
          onTimeChange={onTimeChange}
        />
        <ExtraServices />
      </div>
      <div className="modal-btn modal-btn-sm text-end">
        <ModalButton to="#" className="btn-secondary" data-bs-dismiss="modal">
          Go Back
        </ModalButton>
        <ModalButton to="#" className="btn-primary" data-bs-dismiss="modal">
          Save & Continue
        </ModalButton>
      </div>
    </ModalWrapper>
  );
};

export default EditBooking;


