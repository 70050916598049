import React from "react";
import { useSelector } from "react-redux"; 

const menuItems = [
  { id: "Profile", icon: "user", label: "Profile" },
  { id: "Documents", icon: "file-text", label: "Freelance Documents" },
  { id: "Security", icon: "shield", label: "Security" },
  { id: "Notifications", icon: "bell", label: "Notifications" }
];

const SettingsSidebar = ({ setSelectedSection, selectedSection }: any) => {
  // const userRole = useSelector((state:any) => state?.login_data?.role);
  const userRole = useSelector((state: any) => state?.authReducer?.login_data?.role);
  
  // const menuItems = userRole !== "professionalDriver" ? CommonMenuItems : professionalDriverMenu;
  return (
    <div className="col-lg-3 theiaStickySidebar">
      <div className="stickybar">
        <div className="settings-widget">
          <div className="settings-menu">
            <ul>
              {menuItems
                .filter(({ id }) => {
                  if (id === "Documents" && userRole !== "professionalDriver") {
                    return false;
                  }
                  return true;
                })
                .map(({ id, icon, label }) => (
                  <li key={id}>
                    <button
                      onClick={() => setSelectedSection(id)}
                      className={selectedSection === id ? "active" : ""}
                    >
                      <i className={`feather icon-${icon}`} /> {label}
                    </button>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsSidebar;

