import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ImageWithBasePath from '../../../../core/data/img/ImageWithBasePath';
import ListingLocation from '../ListingLocation';
import IconButton from '../buttons/IconButton';
import { all_routes } from '../../../router/all_routes';
import ListingDetailsGroup from '../ListingDetailsGroup';


const GridCard = ({ car, className }: any) => {
  const navigate = useNavigate();
  const routes = all_routes;

  const handleClick = () => {
    navigate('/rent/details');
  };

  return (
    <div className={className}>
      <div className="listing-item">
        <div className="listing-img">
          <Link to={car.route}>
            <ImageWithBasePath
              src="assets/img/cars/car2.png"
              className="img-fluid"
              alt={car.name}
            />
          </Link>
        </div>
        <div className="listing-content">
          <div className="listing-features d-flex align-items-end justify-content-between">
            <div className="list-rating">
              <h3 className="listing-title">
                <Link to={routes.CarDetails}>
                  {car.modelName}
                </Link>
              </h3>
              <div className="list-rating">
                Category: {car?.carCategory?.name}
              </div>
            </div>
          </div>
          <div className="listing-details-group">
            <ListingDetailsGroup car={car}/>
          </div>
          <ListingLocation color='#000' searchdata={{pickupLocation:`${car?.pickupLocation?.street}`, 
                dropoffLocation:`${car?.dropoffLocation?.street}`,
                pickupDate:`${car?.pickupDate}`,
                dropoffDate:`${car?.dropoffDate}`}}/>
          <div className="listing-button" style={{ justifyContent: 'space-between', paddingTop: '18px', marginTop: '18px' }}>
            {/* <PriceInfo /> */}
            <IconButton onClick={handleClick} label='Rent Now' iconClass="feather icon-calendar me-2" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GridCard;
