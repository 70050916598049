import React, { useState } from 'react'
import MultiRangeCalender from '../../common/ui_components/MultiRangeCalender'
// import DateAvailability from '../../common/ui_components/DateAvailability'
import { format } from 'date-fns';



const AvailabilityCalender = ({ handleBookClick, carAvailabilityData, seatingCapacity }: any) => {
  const [selectedDateRange, setSelectedDateRange] = useState<string | null>(null);
  // const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const handleDateRangeSelect = (startDate: Date, endDate: Date) => {
    setSelectedDateRange(`${format(new Date(startDate), "MMM dd")} - ${format(new Date(endDate), "MMM dd")}`);
    // setIsButtonDisabled(false);
  };

  return (
    <div className="review-sec mt-0">
      <div className="review-header">
        <h4>Check Availability</h4>
      </div>
      <div className="price-list">
        <div>
          <p>SELECT DATE RANGE <span className="text-danger"> *</span></p>
          <div className="availablity_date">
            {selectedDateRange && selectedDateRange}
          </div>
        </div>
        <div className='availablity-wrapper'>
          <MultiRangeCalender
            onSelectDateRange={handleDateRangeSelect}
            carAvailabilityData={carAvailabilityData}
            // isButtonDisabled={isButtonDisabled}
            handleBookClick={handleBookClick}
            seatingCapacity={seatingCapacity}
          // setNoOfPersons={setNoOfPersons}
          />



          {/* <div className='days_info'>
            <DateAvailability />
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default AvailabilityCalender