import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { format } from 'date-fns';
import useHelperFuncs from "../../../../utils/hooks/useHelperFuncs";
import BookingList from "./BookingList";
import UserBookingCalender from "../../../common/ui_components/UserBookingCalender";
import DashboardLayout from "../DashboardLayout";
import SortAndFilterBar from "../UserBooking/SortAndFilterBar";
import UserBookingHeader from "../UserBooking/UserBookingHeader";
import { fetchBookings } from "../../../../redux/reducers/bookingReducer";
// import CompletedBooking from "../../CompletedBooking";
// import SuccessModal from "../UserSectionModals/SuccessModal";
// import { all_routes } from "../../../router/all_routes";
import ViewBooking from "../UserSectionModals/ViewBooking";
import useBooking from "../../../../utils/hooks/useBooking";
// import CancelBooking from "../UserSectionModals/CancelBooking";
// import EditBooking from "../UserSectionModals/EditBooking";
// import InprogressBooking from "../../InprogressBooking";
// import CanceledBooking from "../../CanceledBooking";
// import CancelReason from "../UserSectionModals/CancelReason";
// import RelocationStatus from "../UserSectionModals/BookingStatus";
// import DeleteBooking from "../UserSectionModals/DeleteModal";
// import CustomDateModal from "../UserSectionModals/CustomDateModal";



const BookingPage = () => {
  const dispatch = useDispatch();
  const { toTitleCase } = useHelperFuncs();
  const {
    statusArr, activeStatus, setActiveStatus,
    setTimeFilter,
    sortOptArr, setSortOpt,
  } = useBooking();

  const bookingList = useSelector((state: any) => state?.bookingReducer?.bookingList);

  const [viewType, setViewType] = useState("list");
  const [listData, setListData] = useState<any[]>([]);

  const getFilteredList = () => {
    switch (activeStatus) {
      case "Upcoming":
        return listData.filter((rowData) => rowData?.bookingStatus === "Upcoming");
      case "Ongoing":
        return listData.filter((rowData) => rowData?.bookingStatus === "Ongoing");
      case "Completed":
        return listData.filter((rowData) => rowData?.bookingStatus === "Completed");
      case "Cancelled":
        return listData.filter((rowData) => rowData?.bookingStatus === "Cancelled");
      default:
        return listData;
    }
  };
  const filteredListData = getFilteredList();

  // Initial list data fetch
  useEffect(() => {
    dispatch(fetchBookings({ endpoint: `bookings/driver?dataPerPage=1000&page=1` }));
  }, [])

  // Only for formating list data
  useEffect(() => {
    if (bookingList?.length > 0) {
      const formatedCarList = bookingList.map((booking: any) => {
        return {
          // route: all_routes.userDashboard,
          bookingId: booking?.bookingtempId,
          imgSrc: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/medias?key=${booking?.carId?.images?.[0]?.key}&width=${100}`,
          carName: booking?.carId?.modelName || 'Model Name',
          carType: booking?.carId?.carCategory?.name || 'Car Type',
          pickupLocation: booking?.carId?.pickupLocation?.street,
          pickupTime: booking?.carId?.pickupDate && `${format(new Date(booking?.carId?.pickupDate), "dd MMM yyyy, hh:mm a")}`,
          dropoffLocation: booking?.carId?.dropoffLocation?.street,
          dropoffTime: booking?.carId?.dropoffDate && `${format(new Date(booking?.carId?.dropoffDate), "dd MMM yyyy, hh:mm a")}`,
          bookedOn: booking?.createdAt ? `${format(new Date(booking?.createdAt), "dd MMM yyyy, hh:mm a")}` : 'NA',
          bookingStatus: booking?.status ? toTitleCase(booking?.status) : 'Not Booked Yet',
        };
      });
      setListData(formatedCarList);
    }
  }, [bookingList])

  return (
    <>
      <DashboardLayout title={'User Bookings'} subtitle={'Bookings'}>
        <UserBookingHeader title={'Booking List'} viewType={viewType} onViewChange={setViewType} />
        <SortAndFilterBar
          statusArr={statusArr}
          sortOptions={sortOptArr}
          onStatusChange={setActiveStatus}
          onTimeFilterChange={setTimeFilter}
          onSortChange={setSortOpt}
        />
        <div className="row">
          {viewType === "list" ? <BookingList title={activeStatus} tableData={filteredListData} /> : <UserBookingCalender />}
        </div>
      </DashboardLayout>

      <ViewBooking />

      {/* <CompletedBooking />
      <SuccessModal
        title="Successful"
        message="Your Ride has been successfully started."
        orderId="#50641"
        link={all_routes.userDashboard}
        linkText="Go to Dashboard"
        modalId="start_rides"
      />
      <ViewBooking />
      <EditBooking />
      <CancelBooking />
      <InprogressBooking />
      <CanceledBooking />
      <RelocationStatus />
      <CancelReason />
      <DeleteBooking id="delete_modal"
        title="Delete Booking"
        message="Are you sure you want to delete this booking?" />
      <CustomDateModal /> */}
    </>

  );
};

export default BookingPage;
