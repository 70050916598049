import React from 'react';

const UserBookingHeader = ({ title, viewType, onViewChange }:any) => {
  return (
    <div className="content-header d-flex align-items-center justify-content-between">
      <h4>{title}</h4>
      <ul className="booking-nav">
        <li>
          <button
            className={viewType === 'list' ? 'active' : ''}
            onClick={() => onViewChange('list')}
          >
            <i className="fa-solid fa-list" />
          </button>
        </li>
        <li>
          <button
            className={viewType === 'calendar' ? 'active' : ''}
            onClick={() => onViewChange('calendar')}
          >
            <i className="fa-solid fa-calendar-days" />
          </button>
        </li>
      </ul>
    </div>
  );
};

export default UserBookingHeader;
