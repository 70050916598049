import React from 'react'
// import { carsData } from '../../../core/data/json/common_static_data';
import ListViewCard from '../../common/ui_components/cards/ListViewCard';

const ListView = ({ carList, searchdata = null }: any) => {
  console.log('searchdata in ListView', searchdata);

  return (
    <div className="row">
      {carList.map((car: any, index: any) => (
        <ListViewCard key={index} car={car} searchdata={searchdata} />
      ))}
    </div>
  );
}

export default ListView