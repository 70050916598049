import React from 'react';
import { Calendar } from "primereact/calendar";
import { TimePicker } from "antd";
import dayjs from 'dayjs';

const LocationDateTime = ({ formik }: any) => {
    const today = new Date();
    return (
        <div className="row row-cols-1 row-cols-md-2 py-3">
            {/* Pickup Date */}
            <div className="column-group-main">
                <div className="input-block">
                    <label>Pickup Date</label>
                </div>
                <div className="input-block-wrapp">
                    <div className="input-block date-widget">
                        <div className="group-img flex flex-row">
                            <Calendar
                                className="datetimepicker bg-custom"
                                value={formik.values.pickupDate}
                                onChange={(e: any) => formik.setFieldValue("pickupDate", e.value)}
                                placeholder="Choose Date"
                                minDate={today}
                            />
                            {formik.touched.pickupDate && formik.errors.pickupDate && <div className="error text-danger font-short">{formik.errors.pickupDate}</div>}
                        </div>
                    </div>
                </div>
            </div>

            {/* Pickup Time */}
            <div className="column-group-main">
                <div className="input-block">
                    <label>Pickup Time</label>
                </div>
                <div>
                    <div className="d-flex justify-content-between align-items-center gap-2 pt-md-1">
                        <div className="d-flex flex-column align-items-center gap-2" style={{ width: '45%' }}>
                            <TimePicker
                                placeholder="Choose Time"
                                className="form-control timepicker w-30"
                                value={formik.values.pickupTime ? dayjs(formik.values.pickupTime, "HH:mm") : null} // Convert moment to dayjs
                                onChange={(time) => formik.setFieldValue("pickupTime", time ? time.format("HH:mm") : "")}
                                showSecond={false}
                            />
                            {formik.touched.pickupTime && formik.errors.pickupTime && <div className="error text-danger font-short">{formik.errors.pickupTime}</div>}
                        </div>
                        <p className={`w-40`}>to</p>
                        <div className="d-flex flex-column align-items-center gap-2" style={{ width: '45%' }}>
                            <TimePicker
                                placeholder="Choose Time"
                                className="form-control timepicker w-30"
                                value={formik.values.pickupTimeEnd ? dayjs(formik.values.pickupTimeEnd, "HH:mm") : null} // Convert moment to dayjs
                                onChange={(time) => formik.setFieldValue("pickupTimeEnd", time ? time.format("HH:mm") : "")}
                                showSecond={false}
                            />
                            {formik.touched.pickupTimeEnd && formik.errors.pickupTimeEnd && <div className="error text-danger font-short">{formik.errors.pickupTimeEnd}</div>}
                        </div>
                    </div>
                </div>
            </div>

            {/* Dropoff Date */}
            <div className="column-group-main mt-4 mt-md-0">
                <div className="input-block">
                    <label>Dropoff Date</label>
                </div>
                <div className="input-block-wrapp">
                    <div className="input-block date-widget">
                        <div className="group-img flex flex-row">
                            <Calendar
                                className="datetimepicker bg-custom"
                                value={formik.values.dropoffDate}
                                onChange={(e: any) => formik.setFieldValue("dropoffDate", e.value)}
                                placeholder="Choose Date"
                                minDate={today}
                            />
                            {formik.touched.dropoffDate && formik.errors.dropoffDate && <div className="error text-danger font-short">{formik.errors.dropoffDate}</div>}
                        </div>
                    </div>
                </div>
            </div>

            {/* Dropoff Time */}
            <div className="column-group-main">
                <div className="input-block">
                    <label>Dropoff Time</label>
                </div>
                <div>
                    <div className="d-flex justify-content-between align-items-center gap-2 pt-md-1">
                        <div className="d-flex align-items-center flex-column gap-2" style={{ width: '45%' }}>
                            <TimePicker
                                placeholder="Choose Time"
                                className="form-control timepicker w-30"
                                value={formik.values.dropoffTime ? dayjs(formik.values.dropoffTime, "HH:mm") : null} // Convert moment to dayjs
                                onChange={(time) => formik.setFieldValue("dropoffTime", time ? time.format("HH:mm") : "")}
                                showSecond={false}
                            />
                            {formik.errors.dropoffTime && <div className="error text-danger font-short">{formik.errors.dropoffTime}</div>}
                        </div>
                        <p className={`w-40`}>to</p>
                        <div className="d-flex align-items-center flex-column gap-2" style={{ width: '45%' }}>
                            <TimePicker
                                placeholder="Choose Time"
                                className="form-control timepicker w-30"
                                value={formik.values.dropoffTimeEnd ? dayjs(formik.values.dropoffTimeEnd, "HH:mm") : null} // Convert moment to dayjs
                                onChange={(time) => formik.setFieldValue("dropoffTimeEnd", time ? time.format("HH:mm") : "")}
                                showSecond={false}
                            />
                            {formik.errors.dropoffTimeEnd && <div className="error text-danger font-short">{formik.errors.dropoffTimeEnd}</div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LocationDateTime;
