import React, { useState, useRef, useEffect } from "react";
import ImageWithBasePath from "../../../core/data/img/ImageWithBasePath";
import AutoCompletePicker from "./AutoCompletePicker";
import { MdKeyboardArrowDown } from "react-icons/md";
import DatePicker from "react-multi-date-picker";
import { locations } from "../../../core/data/json/serving_locations";
import {
  // Box,
  Card,
  // MenuItem,
  // Select,
  styled
} from "@mui/material";
// import DropdownFilter from "./DropDown";
// import { ExpandMore } from '@mui/icons-material';

interface LocationOption {
  value: string;
  label: string;
}

const InfoForPricing = ({ selectedTab }: any) => {
  // console.log("selectedTab===>", selectedTab)
  const [pickupLocation, setPickupLocation] = useState<LocationOption | null>(null);
  const [dropLocation, setDropLocation] = useState<LocationOption | null>(null);
  const [pickupDate, setPickupDate] = useState<any>({ start: null, end: null });
  const [dropoffDate, setDropoffDate] = useState<any>({ start: null, end: null });
  const [fleetSize, setFleetSize] = useState<any>();

  const pickupDatePickerRef = useRef<any>(null);
  const dropoffDatePickerRef = useRef<any>(null);

  const swapLocation = () => {
    setPickupLocation(dropLocation);
    setDropLocation(pickupLocation);
  };

  const openPickupCalendar = () => {
    if (pickupDatePickerRef.current) {
      pickupDatePickerRef.current.openCalendar();
    }
  };

  const openDropoffCalendar = () => {
    if (dropoffDatePickerRef.current) {
      dropoffDatePickerRef.current.openCalendar();
    }
  };

  const handlePickupDateChange = (range: any) => {
    if (range) {
      const [startDate, endDate] = range;
      setPickupDate({ start: startDate, end: endDate });
      setDropoffDate({ start: startDate, end: endDate });
    } else {
      setPickupDate({ start: null, end: null });
      setDropoffDate({ start: null, end: null });
    }
  };

  const handleDropoffDateChange = (range: any) => {
    if (range) {
      const [startDate, endDate] = range;
      setDropoffDate({ start: startDate, end: endDate });
    } else {
      setDropoffDate({ start: null, end: null });
    }
  };

  const formatDateWithDay = (date: any) => {
    if (!date) return "Select Date";
    const formattedDate = new Date(date).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    const formattedDay = new Date(date).toLocaleDateString("en-GB", {
      weekday: "long",
    });
    return (
      <div>
        <span>{formattedDate}</span>
        <br />
        <span>{formattedDay}</span>
      </div>
    );
  };

  const location: any = document.getElementsByClassName('css-hlgwow');
  useEffect(() => {
    if (location?.length > 0) {
      location[0].style.padding = 0;
      location[1].style.padding = 0;
    }
  }, [location]);

  const fleetSizwOpts = [
    { value: "4 Fleet", label: "4 Fleet" },
    { value: "6 Fleet", label: "6 Fleet" },
  ];

  return (
    <div className="pricing-info">
      <div className="location-section">
        {/* Pick Up Location */}
        <div className="location">
          <p>From</p>
          <AutoCompletePicker
            placeholder={"Enter Pickup Location"}
            options={locations}
            selected={pickupLocation}
            setSelected={setPickupLocation}
          />
          {pickupLocation ? <p className="address">{pickupLocation.label}</p> : <p>Address</p>}
        </div>

        {/* Location Swap */}
        <SwapLocation variant="elevation" onClick={swapLocation}>
          <ImageWithBasePath className="img-fluid" src="assets/img/icons/exchange.png" alt="" />
        </SwapLocation>

        {/* Drop-off Location */}
        <div className="location drop-off">
          <p>To</p>
          <AutoCompletePicker
            placeholder={"Enter Drop-off Location"}
            options={locations}
            selected={dropLocation}
            setSelected={setDropLocation}
          />
          {dropLocation ? <p className="address">{dropLocation.label}</p> : <p>Address</p>}
        </div>
      </div>

      {/* Pick up Date */}
      <div
        className="select-date"
        style={{ borderRight: "1px solid #E6E6E6", width: selectedTab === "enterprise" ? '16%' : '24%' }}
        onClick={openPickupCalendar}
      >
        <DropdownHeadWrap>
          <p>Pick-up Date</p>
          <DropdownIcon />
        </DropdownHeadWrap>
        <h4 style={{ fontSize: "18px" }}>
          {pickupDate.start ? formatDateWithDay(pickupDate.start) : "Select Date"}
        </h4>
      </div>

      {/* Drop-off Date */}
      <div
        className="select-date"
        onClick={openDropoffCalendar}
        style={{ width: selectedTab === "enterprise" ? '16%' : '24%' }}
      >
        <DropdownHeadWrap>
          <p>Drop-off Date</p>
          <DropdownIcon />
        </DropdownHeadWrap>
        <h4 style={{ fontSize: "18px" }}>
          {dropoffDate.end ? formatDateWithDay(dropoffDate.end) : "Select Date"}
        </h4>
      </div>

      {/* Fleet Size */}
      {selectedTab === "enterprise" &&
        <div className="select-date" style={{ borderLeft: "1px solid #E6E6E6" }}>
          <DropdownHeadWrap>
            <p>Fleet Size</p>
            <DropdownIcon />
          </DropdownHeadWrap>
          <AutoCompletePicker
            placeholder={"Select"}
            options={fleetSizwOpts}
            selected={fleetSize}
            setSelected={setFleetSize}
          />
        </div>
      }

      <div className="for-pickUp">
        <DatePicker
          ref={pickupDatePickerRef}
          value={pickupDate.start ? [pickupDate.start, pickupDate.end] : []}
          onChange={handlePickupDateChange}
          range
          style={{ display: "none" }}
        />
      </div>

      <div className="for-pickUp">
        <DatePicker
          ref={dropoffDatePickerRef}
          value={dropoffDate.start ? [dropoffDate.start, dropoffDate.end] : []}
          onChange={handleDropoffDateChange}
          range
          style={{ display: "none" }}
        />
      </div>
    </div>
  );
};

const DropdownHeadWrap = styled('div')(() => ({ display: "flex", alignItems: "center", gap: "10px" }));
const DropdownIcon = styled(MdKeyboardArrowDown)(() => ({ height: "22px", width: "22px", color: "#0076D7" }));
const SwapLocation = styled(Card)(() => ({
  width: '60px',
  height: '60px',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  borderRadius: '50%',
  '&:hover': {
    cursor: 'pointer'
  },
  // '&:not(:last-child)': {},
  // '&.Mui-checked': {},
}));

export default InfoForPricing;
