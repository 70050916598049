import React from "react";
import { Link } from "react-router-dom";

const ListActionMenu = ({ actions }: any) => {
  return (
    <div className="dropdown dropdown-action">
      <Link
        to="#"
        className="dropdown-toggle"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <i className="fas fa-ellipsis-vertical me-1"></i>
      </Link>
      <div className="dropdown-menu dropdown-menu-end">
        {actions.map((action: any, index: any) => (
          <Link
            key={index}
            className="dropdown-item"
            to="#"
            data-bs-toggle="modal"
            data-bs-target={action.target}
          >
            <i className={`${action.icon} me-1`} /> {action.label}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default ListActionMenu;
