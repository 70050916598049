import React from "react";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../../../core/data/img/ImageWithBasePath";

// BookingHeader Component
export const BookingHeader = ({ carImage, carModel, location, amount }: any) => (
  <div className="booking-header">
    <div className="booking-img-wrap">
      <div className="book-img">
        <ImageWithBasePath src={carImage} alt="Car Image" />
      </div>
      <div className="book-info">
        <h6>{carModel}</h6>
        <p><i className="feather icon-map-pin" /> Location : {location}</p>
      </div>
    </div>
    <div className="book-amount">
      <p>Total Amount</p>
      <h6>
        {amount}{" "}
        <a href="#">
          <i className="feather icon-alert-circle" />
        </a>
      </h6>
    </div>
  </div>
);

// BookingDetails Component
const BookingDetails = ({ details }: any) => (
  <div className="booking-wrapper">
    <div className="booking-title">
      <h6>Booking Details</h6>
    </div>
    <div className="row">
      {details.map((detail: any, index: any) => (
        <div key={index} className="col-lg-4 col-md-6">
          <div className="booking-view">
            <h6>{detail.label}</h6>
            {Array.isArray(detail.values) ? (
              detail.values.map((value: any, idx: any) => <p key={idx}>{value}</p>)
            ) : (
              <p>{detail.values}</p>
            )}
          </div>
        </div>
      ))}
    </div>
  </div>
);

// PersonalDetails Component
const PersonalDetails = ({ personalInfo }: any) => (
  <div className="booking-wrapper">
    <div className="booking-title">
      <h6>Personal Details</h6>
    </div>
    <div className="row">
      {personalInfo.map((info: any, index: any) => (
        <div key={index} className="col-lg-4 col-md-6">
          <div className="booking-view">
            <h6>{info.label}</h6>
            {Array.isArray(info.values) ? (
              info.values.map((value: any, idx: any) => <p key={idx}>{value}</p>)
            ) : (
              <p>{info.values}</p>
            )}
          </div>
        </div>
      ))}
    </div>
  </div>
);

// ModalActions Component
const ModalActions = () => (
  <div className="modal-btn modal-btn-sm text-end">
    <Link
      to="#"
      data-bs-target="#cancel_ride"
      data-bs-toggle="modal"
      data-bs-dismiss="modal"
      className="btn btn-secondary"
    >
      Cancel Booking
    </Link>
    <Link
      to="#"
      data-bs-target="#start_rides"
      data-bs-toggle="modal"
      data-bs-dismiss="modal"
      className="btn btn-primary"
    >
      Start Ride
    </Link>
  </div>
);

// Main ViewBooking Component
const ViewBooking = () => {
  const bookingDetails = [
    { label: 'Booking Type', values: 'Delivery' },
    { label: 'Rental Type', values: 'Days (3 Days)' },
    { label: 'Extra Service', values: 'Mobile Charging' },
    { label: 'Delivery', values: ['45, Avenue ,Mark Street, USA', '11 Jan 2023, 03:30 PM'] },
    { label: 'Dropoff', values: ['78, 10th street Laplace,USA', '11 Jan 2023, 03:30 PM'] },
    { label: 'Status', values: <span className="badge badge-light-secondary">Upcoming</span> },
    { label: 'Booked On', values: '15 Sep 2023, 09:30 AM' },
    { label: 'Start Date', values: '18 Sep 2023, 09:30 AM' },
    { label: 'End Date', values: '20 Sep 2023, 09:30 AM' }
  ];

  const personalInfo = [
    { label: 'Details', values: ['Johna Melinda', '+1 56441 56464', 'Johna@example.com'] },
    { label: 'Address', values: ['78, 10th street', 'Laplace,USA', '316 654'] },
    { label: 'No of Person’s', values: '2 Adults, 1 Child' }
  ];

  return (
    <div
      className="modal new-modal multi-step fade"
      id="upcoming_booking"
      data-keyboard="false"
      data-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close-btn" data-bs-dismiss="modal">
              <span>×</span>
            </button>
          </div>
          <div className="modal-body">
            <BookingHeader
              carImage="assets/img/cars/car-05.jpg"
              carModel="Chevrolet Camaro"
              location="Miami St, Destin, FL 32550, USA"
              amount="$4700"
            />
            <BookingDetails details={bookingDetails} />
            <PersonalDetails personalInfo={personalInfo} />
            <ModalActions />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewBooking;




{/* <div className="cancel-reason">
                <h6>Cancel Reason</h6>
                <p>
                  On the booking date i have other work on my personal so i am
                  cancelling my bookingOn the booking date i have other work on
                  my personal so i am cancelling my booking On the booking date
                  i have other work on my personal so i am cancelling my booking
                </p>
              </div>
              <div className="cancel-box">
                <p>
                  Cancelled By User on 17 Sep 2023, Refund will deposited in
                  user account on 19 Sep 2023{" "}
                </p>
              </div>
            </div >
  <div className="modal-btn modal-btn-sm text-end">
    <a
      href="#"
      data-bs-target="#view_status"
      data-bs-toggle="modal"
      data-bs-dismiss="modal"
      className="btn btn-primary"
    >
      View status
    </a>
  </div> */}