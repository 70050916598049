import React from "react";
import InputField from "../../../common/ui_components/InputFields/InputField";
import Button from "../../../common/ui_components/buttons/blueBtn";
import ModalWrapper from "./ModalWrapper";

const ChangePassword = () => {
  return (
    <ModalWrapper id="change_password" title="Change Password">
      <form action="#">
        <div className="row">
          <div className="col-md-12">
            <InputField label="Password" placeholder="..........." required={true} type="password" />
          </div>
          <div className="col-md-12">
            <InputField  label="New Password"  placeholder="..........." required={true}  type="password" />
          </div>
          <div className="col-md-12">
            <InputField label="Confirm Password" placeholder="..........." required={true}  type="password" />
          </div>
        </div>
        <div className="modal-btn modal-btn-sm">
          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          <Button label="Submit" className="btn btn-primary" />
        </div>
      </form>
    </ModalWrapper>
  );
};

export default ChangePassword;
