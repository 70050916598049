import React from "react";
import ImageWithBasePath from "../../../../core/data/img/ImageWithBasePath";

const TestimonialCard = ({ testimonial }: any) => {

  return (
    <div key={testimonial.id} className="testimonial-item d-flex" data-aos="fade-down">
      <div className="card flex-fill">
        <div className="card-body">
          <div className="quotes-head" />
          <div className="review-box">
            <div className="review-profile">
              <div className="review-img">
                <ImageWithBasePath
                  src={testimonial.image}
                  className="img-fluid"
                  alt="Profile"
                />
              </div>
            </div>
            <div className="review-details">
              <h6>{testimonial.name}</h6>
              <div className="list-rating">
                <div className="list-rating-star">
                  {[...Array(Math.floor(testimonial.rating))].map((_, i) => (
                    <i key={i} className="fas fa-star filled" />
                  ))}
                </div>
                <p>
                  <span>({testimonial.rating})</span>
                </p>
              </div>
            </div>
          </div>
          <p>{testimonial.review}</p>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;
