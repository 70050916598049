import React from "react";
import { Calendar } from "primereact/calendar";

// type Props = {
//   label?: string;
//   value?: Date | null;
//   onChange?: (e: any) => void;
//   placeholder?: string;
// }

const DatePicker = ({ label, value, onChange, placeholder }: any) => {
  const today = new Date();
    
  return (
    <>
      <div className="input-block">
        <label>{label}</label>
      </div>
      <div className="input-block-wrapp">
        <div className="input-block date-widget">
          <div className="group-img">
            <Calendar value={value} onChange={onChange} placeholder={placeholder} minDate={today}/>
            <span>
              <i className="feather icon-calendar"></i>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default DatePicker;
