import React, { useState } from 'react';

const ColorPicker = ({ items }:any) => {
  const [selectedColors, setSelectedColors] = useState<string[]>([]);

  const handleColorSelect = (color: string) => {
    setSelectedColors(prevSelectedColors =>
      prevSelectedColors.includes(color)
        ? prevSelectedColors.filter(c => c !== color)
        : [...prevSelectedColors, color]
    );
  };

  return (
    <div className="color-picker">
      {items.map((color: any, index: any) => (
        <div
          key={index}
          onClick={() => handleColorSelect(color)}
          className={`color-box ${selectedColors.includes(color) ? 'selected' : 'unselected'}`}
          style={{ backgroundColor: color }}
        />
      ))}
    </div>
  );
};

export default ColorPicker;
