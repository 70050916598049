import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { doGetApiCall, doPostApiCall } from '../../utils/apiconfig';



const BASE_API_URL = process.env.REACT_APP_PUBLIC_BASE_API_URL;

// <------------------------- Thunk For Car Booking ------------------------->
export const bookCar: any = createAsyncThunk('/booking/bookCar', async (body: any) => {
    // console.log("body ===>", body);
    if (body) {
        const res: any = await doPostApiCall({ url: `${BASE_API_URL}/bookings`, bodyData: body });
        return res;
    }
});

// <------------------------- Thunk For Getting Carlist ------------------------->
export const fetchBookings: any = createAsyncThunk('/booking/fetchBookings', async (body?: any) => {
    // console.log("body ===>", body);

    if (body?.endpoint) {
        try {
            const res: any = await doGetApiCall({ url: `${BASE_API_URL}/${body.endpoint}` });
            // console.log("Api Response in thunk --------->", res);
            // if (!res.error) handleUserData(res?.result);
            // else handleError("Failed to fetch user data.");
            return res;
        } catch (err) {
            console.error("Error in fetching cars list:", err);
            // handleError("Error fetching user data.");
        }
    }

});

// <------------------------- Thunk For Getting Car Details ------------------------->
// export const fetchBookingById: any = createAsyncThunk('/booking/fetchBookingById', async (id: any) => {
//     // console.log("body ===>", body);

//     try {
//         const res: any = await doGetApiCall({ url: `${BASE_API_URL}/cars/${id}` });
//         // console.log("Api Response in thunk --------->", res);
//         // if (!res.error) handleResponse(res?.result);
//         // else handleError("Failed to fetch user data.");
//         return res;
//     } catch (err) {
//         console.error("Error in fetching car details:", err);
//         // handleError("Error in fetching car details.");
//     }
// });

interface BookingState {
    loading: 'idle' | 'pending' | 'succeeded' | 'failed',
    code: string,
    message: string,
    bookingList: any[],
    recentBooking: any;
}

const initialState: BookingState = {
    loading: 'idle',
    code: '',
    message: '',
    bookingList: [],
    recentBooking: {},
}

const bookingSlice = createSlice({
    name: 'booking',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed

        // <------ Reducers for bookCar ------> 
        builder.addCase(bookCar.pending, (state) => {
            state.loading = 'pending';
        });
        builder.addCase(bookCar.fulfilled, (state, action) => {
            // console.log("bookCar fulfilled action ----->", action);
            state.loading = 'succeeded';
            if (action?.payload) {
                const { code = '', error = false, result = {}, status = null } = action.payload;
                if (!error && status === 200 && code === 'BOOKING_CREATED') {
                    state.code = code;
                    state.message = 'Car booked successfully !!';
                    state.recentBooking = result;
                } else {
                    state.code = '';
                    state.message = '';
                    state.recentBooking = {};
                }
            }
        })
        builder.addCase(bookCar.rejected, (state, action) => {
            state.loading = 'failed';
            if (action?.payload?.error) state.recentBooking = { ...state.recentBooking };
        })

        // <------ Reducers for fetchBookings ------> 
        builder.addCase(fetchBookings.pending, (state) => {
            state.loading = 'pending';
        });
        builder.addCase(fetchBookings.fulfilled, (state, action) => {
            // console.log("fetchBookings fulfilled action ----->", action);
            state.loading = 'succeeded';
            if (action?.payload) {
                if (action?.payload) {
                    const { code = '', error = false, message = '', result = [], status = null } = action.payload;
                    state.code = code;
                    state.message = message;
                    if (!error && status === 200 && code === 'BOOKING_FETCHED') {
                        state.bookingList = result;
                    }
                }
            }
        })
        builder.addCase(fetchBookings.rejected, (state, action) => {
            state.loading = 'failed';
            if (action?.payload?.error) state.bookingList = [...state.bookingList];
        })

        // <------ Reducers for fetchBookingById ------> 
        // builder.addCase(fetchBookingById.pending, (state) => {
        //     state.loading = 'pending';
        // });
        // builder.addCase(fetchBookingById.fulfilled, (state, action) => {
        //     // console.log("fetchBookingById fulfilled action ----->", action);
        //     state.loading = 'succeeded';
        //     if (action?.payload) {
        //         const { code = '', error = false, message = '', result = [], status = null } = action.payload;
        //         state.code = code;
        //         state.message = message;
        //         if (!error && status === 200) state.singleCarDetails = result;
        //         else state.singleCarDetails = {};
        //     }
        // })
        // builder.addCase(fetchBookingById.rejected, (state, action) => {
        //     state.loading = 'failed';
        //     if (action?.payload?.error) state.carList = [...state.carList];
        // })
    },
})

// Action creators are generated for each case reducer function
// export const { set_signup_data, } = bookingSlice.actions;
export default bookingSlice.reducer;