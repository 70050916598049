import { configureStore } from '@reduxjs/toolkit'
import authReducer from './reducers/authReducer';
import userReducer from './reducers/userReducer';
import notificationReducer from './reducers/notificationReducer';
import mobileViewRedurer from './reducers/mobileViewRedurer';
import carReducer from './reducers/carReducer';
import transactionReducer from './reducers/transactionReducer';
import quoteReducer from './reducers/quoteReducer';
import bookingReducer from './reducers/bookingReducer';

const store = configureStore({
    reducer: {
        authReducer,
        userReducer,
        notificationReducer,
        mobileViewRedurer,
        carReducer,
        transactionReducer,
        quoteReducer,
        bookingReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;
export default store;