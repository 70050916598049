import React from 'react';
import ImageWithBasePath from '../../../core/data/img/ImageWithBasePath';

const ListingLocation = ({ color, searchdata }: any) => {

  const extractStreet = (location: string | undefined | null) => {
    return location?.split(',')[0] || 'N/A'; 
  };

  return (
    <div className='static-location' style={{ color }}>
      <div>
        <h4 style={{ color }}>{extractStreet(searchdata?.pickupLocation)}</h4>
        <div style={{ fontSize: '15px' }}>
          {searchdata?.pickupDate ? new Date(searchdata.pickupDate).toLocaleDateString() : 'Date not available'}
        </div>
      </div>
      <div>
        <ImageWithBasePath className="img-fluid" src="assets/img/icons/exchange.png" alt="" />
      </div>
      <div>
        <h4 style={{ color }}>{extractStreet(searchdata?.dropoffLocation)}</h4>
        <div style={{ fontSize: '15px' }}>
          {searchdata?.dropoffDate ? new Date(searchdata.dropoffDate).toLocaleDateString() : 'Date not available'}
        </div>
      </div>
    </div>
  );
};

export default ListingLocation;
