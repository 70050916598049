import React from "react";

const BookingInfoCard = ({ title, iconClass, children }: any) => {
  return (
    <div className="booking-information-card payment-info-card">
      <div className="booking-info-head">
        <div className="d-flex align-items-center">
          <span>
            <i className={iconClass} />
          </span>
          <h5>{title}</h5>
        </div>
      </div>
      <div className="booking-info-body">{children}</div>
    </div>
  );
};

export default BookingInfoCard;
