import React from 'react'
import InvoiceHeader from './InvoiceHeader'
import InvoiceBillingInfo from './InvoiceBillingInfo'
import InvoiceTable from './InvoiceTable'
import InvoicePaymentDetails from './InvoicePaymentDetails'
import InvoiceNoteFooter from './InvoiceNoteFooter'
import { useSelector } from 'react-redux'

const InvoiceContainer = ({invoiceData, carData}:any) => {
    const UserDetails = useSelector((state: any) => state?.authReducer?.login_data);
    return (
        <><InvoiceHeader />
            <InvoiceBillingInfo
                billTo={"Admin"}
                invoiceFrom={UserDetails}
                invoiceDetails={carData}
            />
            <InvoiceTable items={carData} />
            <InvoicePaymentDetails
                paymentMethod={invoiceData.paymentMethod}
                totalAmount={`$${carData?.rentAmount}`}
            />
            <InvoiceNoteFooter
                signature={invoiceData.signature}
                signerName={"Admin"}
            /></>

    )
}

export default InvoiceContainer