import React from "react";
import dayjs from 'dayjs';
// import { Link } from "react-router-dom";
// import { all_routes } from "../../../router/all_routes";

const BookingLocationCard = ({car}:any) => {
  // const routes = all_routes;
  return (
    <div className="booking-sidebar-card">
      <div className="booking-sidebar-head d-flex justify-content-between align-items-center">
        <h5>Location &amp; Time</h5>
        {/* <Link to={routes.bookingCheckout} className="d-flex align-items-center">
          <i className="bx bx-edit-alt me-2" />
          Edit
        </Link> */}
      </div>
      <div className="booking-sidebar-body">
        <ul className="location-address-info">
          <li>
            <h6>Pickup Location &amp; time</h6>
            <p>{car?.pickupLocation?.street}</p>
            <p>{dayjs(car?.pickupDate).format('YYYY-MM-DD')}</p>
          </li>
          <li>
            <h6>Delivery Location &amp; time</h6>
            <p>{car?.dropoffLocation?.street}</p>
            <p>{dayjs(car?.dropoffDate).format('YYYY-MM-DD')}</p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default BookingLocationCard;
