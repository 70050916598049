import React from "react";
import ImageWithBasePath from "../../../core/data/img/ImageWithBasePath";

const InvoiceNoteFooter = ({signature, signerName }:any) => {
  return (
    <div className="invoice-note-footer">
      <div className="row align-items-center">
        {/* <div className="col-lg-6 col-md-12">
          <div className="invocie-note">
            <h6>Notes</h6>
            <p>{notes}</p>
          </div>
          <div className="invocie-note mb-0">
            <h6>Terms and Conditions</h6>
            <p>{terms}</p>
          </div>
        </div> */}
        <div className="col-lg-12 col-md-12">
          <div className="invoice-sign">
            <ImageWithBasePath
              className="img-fluid d-inline-block"
              src={signature}
              alt="sign"
            />
            <span className="d-block">{signerName}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceNoteFooter;
