import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { doPostApiCall } from "../../utils/apiconfig";
import { all_routes } from "../../feature-module/router/all_routes";
import { set_signup_data } from "../../redux/reducers/authReducer";
import { closeNotification, openNotification } from "../../redux/reducers/notificationReducer";



export const useSignup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routes = all_routes;

  const [loading, setLoading] = useState(false);

  const openMessage = (
    alertType: string,
    message: string,
    subText: string,
    borderClass: string
  ) => {
    if (alertType) {
      const notificationObj = {
        open: true,
        message: message,
        alertType: alertType,
        subText: subText,
        borderClass: borderClass,
      }
      dispatch(openNotification(notificationObj));
      setTimeout(() => dispatch(closeNotification()), 4000);
    }
  };

  // const handleSignUp = async (values: any, resetForm: any) => {
  const handleSignUp = async (values: any) => {
    setLoading(true);
    const signupData = {
      url: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/auth/signup`,
      bodyData: {
        user_name: values.username,
        email: values.email,
        password: values.password,
        type: values.activeState,
        subtype: values.subtype,
      },
    };

    try {
      // dispatch(signUp(signupData));
      const response: any = await doPostApiCall(signupData);
      if (response?.error) {
        response?.code == 'USER_ALREADY_EXISTS' && openMessage("error", `Sign up failed, ${response?.message} !!`, '', "error");
        return;
      }
      openMessage("success", "Sign up successful !!", "", "success");
      dispatch(set_signup_data(response.result));
      navigate(routes.OtpVerification);
      // Optionally verify the user via PATCH request
      // await verifyUser(values.email);

    } catch (err) {
      console.error("An error occurred during signup.");
    } finally {
      setLoading(false);
    }
  };

  // const verifyUser = async (email: string) => {
  //   const verifyData = {
  //     url: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/auth/users/verify`,
  //     bodyData: { email },
  //   };

  //   console.log("verifyUser===>",verifyUser);
  //   try {
  //     await doPatchApiCall(verifyData);
  //     alert("User verified successfully.");
  //   } catch (err) {
  //     alert("User verification failed.");
  //   }
  // };

  return {
    openMessage,
    handleSignUp,
    loading,
  };
};
