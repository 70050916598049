import { countryList } from "../../../core/data/json/country";

const DropdownOptions = () => {
  const carOptions = ["Sedan", "Suv", "Hatchback","Coupe", "Convertible", "Wagon", "Minivan", "Electric", "Others"];
  const acOptions = ["AC", "Non-AC"];
  const doorOptions = ["4", "5"];
  const engineOptions = ["2.0", "2.5", "3.0"];
  const mileageOptions = ["8", "16"];
  const countryOptions = countryList; 

  return {
    carOptions,
    acOptions,
    doorOptions,
    engineOptions,
    mileageOptions,
    countryOptions
  };
};

export default DropdownOptions;
