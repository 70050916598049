import React from "react";
import { Link } from "react-router-dom";
import { all_routes } from "../../../router/all_routes";
import SuccessModal from "./SuccessModal";
import EmailVerificationModal from "./EmailVerificationModal";
import InputField from "../../../common/ui_components/InputFields/InputField";
import ModalWrapper from "./ModalWrapper";

const ChangeEmailAddress = () => {
  return (
    <div>
      <ModalWrapper id="change_email" title="Change Email Address">
        <form action="#">
          <InputField label="New Email Address" placeholder="Enter New Email Address" required={true} />
          <div className="modal-form-group">
            <p> <i className="feather icon-alert-circle" /> New Email Address Only Updated Once You Verified </p>
          </div>
        </form>
        <div className="modal-btn modal-btn-sm">
          <Link to="#" className="btn btn-secondary"  data-bs-dismiss="modal" >
            Cancel
          </Link>
          <Link to="#" className="btn btn-primary" data-bs-target="#email_verification" data-bs-toggle="modal" data-bs-dismiss="modal"  >
            Change Email
          </Link>
        </div>
      </ModalWrapper>
      <EmailVerificationModal />
      <SuccessModal
        title="Successful"
        message="Your Email has been successfully verified."
        link={all_routes.userSecurity}
        linkText="Back to Settings"
        modalId="email_verification_success"
      />
    </div>
  );
};

export default ChangeEmailAddress;
