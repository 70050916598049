import React, { useState } from "react";
// import DropdownFilter from "../../../common/ui_components/DropDown";

const SortingHeader = ({ onStatusChange }: any) => {
  const paymentStatusArr = [
    { name: "All Payments" },
    { name: "Pending" },
    { name: "Success" },
    { name: "Failed" },
  ];
  // const timeFilters = [
  //   { name: "This Week" },
  //   { name: "This Month" },
  //   { name: "Last 30 Days" },
  // ];
  // const sortOptions = [
  //   { name: "Sort By Relevance" },
  //   { name: "Sort By Ascending" },
  //   { name: "Sort By Descending" },
  //   { name: "Sort By Alphabet" },
  // ];

  const [activeStatus, setActiveStatus] = useState(paymentStatusArr[0].name);
  // const [selectedTimeFilter, setSelectedTimeFilter] = useState(timeFilters[0].name);
  // const [selectedSortOption, setSelectedSortOption] = useState(sortOptions[0].name);

  const handleStatusChange = (status: string) => {
    setActiveStatus(status);
    onStatusChange(status);
  };


  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="sorting-info">
          <div className="row d-flex align-items-center">

            {/* Filter By Status Section */}
            <div className="col-xl-7 col-lg-8 col-sm-12 col-12">
              <div className="booking-lists">
                <ul className="nav">
                  {paymentStatusArr.map((status) => (
                    <li key={status.name}>
                      <button
                        className={activeStatus === status.name ? "active" : ""}
                        onClick={() => handleStatusChange(status.name)}
                      >
                        {status.name}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {/* Dropdown Filter Section */}
            {/* <div className="col-xl-5 col-lg-4 col-sm-12 col-12">
              <div className="filter-group">
                <div className="sort-week sort">
                  <DropdownFilter
                    value={selectedTimeFilter}
                    onChange={(value: any) => setSelectedTimeFilter(value)}
                    options={timeFilters}
                    placeholder="Select Time Filter"
                    className="dropdown-filter"
                  />
                </div>
                <div className="sort-relevance sort">
                  <DropdownFilter
                    value={selectedSortOption}
                    onChange={(value: any) => setSelectedSortOption(value)}
                    options={sortOptions}
                    placeholder="Select Sort Option"
                    className="dropdown-filter"
                  />
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SortingHeader;