import React from "react";

const BookingCard = ({ title, children, className }: any) => {
  return (
    <div className={`book-card ${className}`}>
      <div className="book-head">
        <h6>{title}</h6>
      </div>
      <div className="book-body">{children}</div>
    </div>
  );
};

export default BookingCard;
