import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import ModalWrapper from './ModalWrapper';
import { Button } from '@mui/material';
import Countdown from 'react-countdown';

const OTPVerificationModal = ({ number = '' }: any) => {
  const [otp, setOtp] = useState('');
  const [resendOtp, setResendOtp] = useState(false);

  const renderer = ({ minutes, seconds, completed }: any) => {
    if (completed) {
      setResendOtp(true);
      return <span>OTP has expired</span>;
    } else {
      return (
        <p>
          <span>OTP expires in {minutes}:{seconds < 10 ? `0${seconds}` : seconds} sec</span>
        </p>
      );
    }
  };

  return (
    <ModalWrapper id="otp_verification" title="OTP Verification">
      <div className="otp-number">
        <p>
          Enter OTP sent to your mobile number <span>+ {number}</span>
        </p>
      </div>
      <div className="otp-box">
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={4}
          renderSeparator={<span>-</span>}
          renderInput={(props) => <input {...props} />}
        />
      </div>
      <div className="otp-resend">
        <Button
          variant="text"
          color="primary"
          onClick={() => setResendOtp(true)}
          disabled={!resendOtp}
          className="resend-otp"
        >
          Resend OTP
        </Button>
        
        <Countdown
          date={Date.now() + 1 * 10 * 1000}
          renderer={renderer}
        />

        {/* <Link to="#">Resend Again</Link>
        <p>Remaining 00:30s</p> */}
      </div>
      <div className="modal-btn modal-btn-group">
        <div className="row">
          <div className="col-6">
            <Link to="#" className="btn btn-secondary w-100" data-bs-dismiss="modal" >  Cancel  </Link>
          </div>
          <div className="col-6">
            <Link
              to="#"
              className="btn btn-primary w-100"
              data-bs-dismiss="modal"
              data-bs-target="#verification_success"
              data-bs-toggle="modal"
            >
              Verify
            </Link>
          </div>
        </div>
      </div>
      <div className="verified-box">
        <p>
          <i className="feather icon-check" /> Your phone number has been
          successfully verified
        </p>
      </div>
    </ModalWrapper>
  );
};

export default OTPVerificationModal;
