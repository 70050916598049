import React from 'react'

const AuthenticationFooter = () => {
  return (
    
    <footer className="log-footer">
    <div className="container-fluid">
      <div className="copyright">
        <div className="copyright-text">
          <p>© 2023 FLEXYMOVE. All Rights Reserved.</p>
        </div>
      </div>
    </div>
  </footer>
  )
}

export default AuthenticationFooter