import React, { useState } from "react";
import DropdownFilter from "../../../common/ui_components/DropDown";

const SortAndFilterBar = ({
  statusArr = [
    { name: "All Relocations" },
    { name: "Pending" },
    { name: "Success" },
    { name: "Failed" },
    { name: "Upcoming" },
  ],
  onStatusChange,
  timeFilters = [
    { name: "This Week", value: 'time=7days' },
    { name: "This Month", value: 'time=30days' },
    { name: "Last Month", value: 'time=lastMonth' },
  ],
  onTimeFilterChange,
  sortOptions = [
    // { name: "Sort By Relevance" },
    { name: "Sort By Ascending", value: 'sort=ASC' },
    { name: "Sort By Descending", value: 'sort=DESC' },
    { name: "Sort By Alphabet", value: 'sort=byAlphabet' },
  ],
  onSortChange
}: any) => {

  const [activeButton, setActiveButton] = useState(statusArr[0].name);
  const [selectedTimeFilter, setSelectedTimeFilter] = useState(timeFilters[0].name);
  const [selectedSortOption, setSelectedSortOption] = useState(sortOptions[0].name);

  const handleOnChange = (type: string, val: string) => {
    // console.log('handleOnChange Hit ------->', 'type', type, 'val', val);

    switch (type) {
      case 'statusFilter':
        setActiveButton(val);
        onStatusChange(val);
        break;

      case 'timeFilter':
        setSelectedTimeFilter(val);
        onTimeFilterChange(val);
        break;

      case 'sortFilter':
        setSelectedSortOption(val);
        onSortChange(val);
        break;

      default:
        break;
    }
  }

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="sorting-info">
          <div className="row d-flex align-items-center">
            <div className="col-xl-7 col-lg-8 col-sm-12 col-12">
              <div className="booking-lists">
                <ul className="nav">
                  {statusArr.map((status: any) => (
                    <li key={status.name}>
                      <button
                        className={activeButton === status.name ? "active" : ""}
                        onClick={() => handleOnChange('statusFilter', status.name)}
                      >
                        {status.name}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-xl-5 col-lg-4 col-sm-12 col-12">
              <div className="filter-group">
                <div className="sort-week sort">
                  <DropdownFilter
                    value={selectedTimeFilter}
                    onChange={(value: any) => handleOnChange('timeFilter', value)}
                    options={timeFilters}
                    placeholder="Select Time Filter"
                    className="dropdown-filter"
                  />
                </div>
                <div className="sort-relevance sort">
                  <DropdownFilter
                    value={selectedSortOption}
                    onChange={(value: any) => handleOnChange('sortFilter', value)}
                    options={sortOptions}
                    placeholder="Select Sort Option"
                    className="dropdown-filter"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SortAndFilterBar;
