import React from 'react'
import { Link } from 'react-router-dom'
import ImageWithBasePath from '../../../../core/data/img/ImageWithBasePath'
import { all_routes } from '../../../router/all_routes'

const AuthenticationHeader = () => {
    const routes = all_routes
  return (
    <header className="log-header">
        <Link to={routes.MainLanding}>
          <ImageWithBasePath
            className="img-fluid logo-dark"
            src="assets/img/logo.png"
            alt="Logo"
          />
        </Link>
      </header>
  )
}

export default AuthenticationHeader