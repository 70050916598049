import React, { useState } from "react";
import { Link } from "react-router-dom";
import { all_routes } from "../router/all_routes";
import AuthenticationHeader from "../common/ui_components/AuthenticationComponents/AuthenticationHeader";
import AuthenticationFooter from "../common/ui_components/AuthenticationComponents/AuthenticationFooter";
import UserStateSwitcher from "../common/ui_components/AuthenticationComponents/UserStateSwitcher";
import WrapperModal from "../common/ui_components/AuthenticationComponents/WrapperModal";
import { useSignup } from "../../services/auth/signup";
import SignUpForm from "../user/Forms/SignUpForm";

const routes = all_routes;

const userState = [
  { id: "flexiDriver", label: "Flexy Driver" },
  { id: "carOwner", label: "Car Owner" },
  { id: "professionalDriver", label: "Professional Driver" },
];

const SignUp = () => {
  const [activeState, setActiveState] = useState(userState[0].id);
  const { handleSignUp, loading } = useSignup();

  return (
    <div>
      <div className="main-wrapper login-body">
        <AuthenticationHeader />
        <div className="login-wrapper">
          <div className="fit-width">
            <UserStateSwitcher
              userStates={userState}
              activeState={activeState}
              setActiveState={setActiveState}
            />
          </div>
          <WrapperModal title="Sign Up" subtitle="We will send a confirmation code to your email.">
            <SignUpForm handleSignUp={handleSignUp} activeState={activeState} isSubmitting={loading} />
            <div className="text-center dont-have">
              Already have an Account? <Link to={routes.login}>Sign In</Link>
            </div>
          </WrapperModal>
        </div>
        <AuthenticationFooter />
      </div>
    </div>
  );
};

export default SignUp;
