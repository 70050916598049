import React from "react";
import IconInputField from "../../common/ui_components/InputFields/IconInputField";

const AvailabilityCheck = ({ dateTimeData }: any) => {


  return (
    <div className="review-sec mt-0">
      <div className="location-content">
        <div className="search-box-banner" style={{ padding: "0px" }}>
          <form>
            <ul className="align-items-center">
              <div className="relocation-card-column">
                <IconInputField
                  label="Pickup Location"
                  placeholder="Enter City, Airport, or Address"
                  iconClass="feather icon-map-pin"
                  value={dateTimeData?.pickupLocation}
                  readOnly
                />
              </div>
              <div className="relocation-card-column">
                <IconInputField
                  label="Return Location"
                  placeholder="Enter City, Airport, or Address"
                  iconClass="feather icon-map-pin"
                  value={dateTimeData?.dropoffLocation}
                  readOnly
                />
              </div>
              <div className="half-column-group">
                <IconInputField
                  label="Pickup Date"
                  iconClass="feather icon-calendar"
                  value={new Date(dateTimeData?.pickupDate).toLocaleDateString()}
                  readOnly
                />
              </div>
              <div className="half-column-group">
                <IconInputField
                  label="Pickup Time"
                  iconClass="feather icon-clock"
                  value={dateTimeData?.pickupTime?.from}
                  readOnly
                />
              </div>
              <div className="half-column-group">
                <IconInputField
                  label="Return Date"
                  iconClass="feather icon-calendar"
                  value={new Date(dateTimeData?.dropoffDate).toLocaleDateString()}
                  readOnly
                />
              </div>
              <div className="half-column-group">
                <IconInputField
                  label="Dropoff Time"
                  iconClass="feather icon-clock"
                  value={dateTimeData?.dropoffTime?.from}
                  readOnly
                />
              </div>
            </ul>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AvailabilityCheck;
