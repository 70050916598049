import React from 'react';
import { Link } from 'react-router-dom';
import ModalWrapper from './ModalWrapper';

const EmailVerificationModal = () => {
  return (
    <ModalWrapper id="email_verification" title="Email Verification">
      <div className="otp-number">
        <p> Please click the verification link sent to your email address{" "}
          <span>Triddse3w@example.com</span>
        </p>
      </div>
      <div className="otp-resend">
        <Link to="#">Resend Again</Link>
      </div>
      <div className="modal-btn">
        <div className="row">
          <div className="col-6">
            <Link to="#" className="btn btn-secondary w-100"  data-bs-dismiss="modal" > Close </Link>
          </div>
          <div className="col-6">
            <Link to="#" className="btn btn-primary w-100" data-bs-dismiss="modal" data-bs-target="#email_verification_success" data-bs-toggle="modal">
              Verify
            </Link>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default EmailVerificationModal;
