import React from 'react'
import ImageWithBasePath from '../../../core/data/img/ImageWithBasePath'

const SearchInput = () => {
  return (
    <div className="form-custom">
    <input
      type="text"
      className="form-control"
      id="member_search1"
      placeholder=""
    />
    <span>
      <ImageWithBasePath
        src="assets/img/icons/search.svg"
        alt="Search Icon"
      />
    </span>
  </div>
  )
}

export default SearchInput