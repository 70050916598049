import React from 'react'
import ImageWithBasePath from '../../../../core/data/img/ImageWithBasePath'

const SuccessMessage = ({handleOkClick}:any) => {
    return (
        <div className='confirmation_modal' style={{gap:'20px',padding:'20px 20px 40px 20px'}}>
            <ImageWithBasePath
                src="assets/img/icons/success_icon.png"
                alt="Icon"
            />
            <div className='modal_header'>Account Verification</div>
            <div className='modal_body'>{"Your account verification is in progress.Once the verification is done you can start using FLEXYMOVE's services."}</div>
            <div className='btn_container'>
                <div className='single_btn' onClick={handleOkClick}>Okay</div>
            </div>
        </div>
    )
}

export default SuccessMessage