import React from 'react';
import { useSelector } from 'react-redux';



const CarFeatures = () => {
  const carDetails = useSelector((state: any) => state?.carReducer?.singleCarDetails);

  return (
    <div className="review-sec listing-feature">
      <div className="review-header">
        <h4>Car Features</h4>
      </div>
      <div className="listing-description">
        <div className="row">
          {carDetails?.features?.map((feature: any, index: string) => (
            <div className="col-md-4" key={index}>
              <ul>
                <li >
                  <span><i className="bx bx-check-double" /></span>
                  {feature}
                </li>
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CarFeatures;
