import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import ImageWithBasePath from '../../../core/data/img/ImageWithBasePath';
// import { CarImageGallery } from '../../../core/data/json/common_static_data';
import { useSelector } from 'react-redux';

const CarGallery = () => {
  const carDetails = useSelector((state: any) => state?.carReducer?.singleCarDetails);

  const [carImages, setCarImages] = useState<any>([]);

  const [open, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const openLightbox = (index: number) => {
    setCurrentIndex(index);
    setOpen(true);
  };

  useEffect(() => {
    if (carDetails?.images?.length > 0) {
      setCarImages(carDetails?.images);
    }
  }, [carDetails])

  return (
    <div className="review-sec mb-0 pb-0">
      <div className="review-header">
        <h4>Gallery</h4>
      </div>
      <div className="small-gallery-list d-flex">
        <Lightbox
          open={open}
          close={() => setOpen(false)}
          slides={carImages?.map((image: any) => ({ src: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/medias?key=${image?.key}&width=${100}` }))}
          index={currentIndex}
        />
        {carImages?.map((image: any, index: any) => (
          <div key={index} className="gallery-widget">
            <Link
              to="#"
              data-fancybox="gallery"
              onClick={(e) => {
                e.preventDefault();
                openLightbox(index);
              }}
            >
              <ImageWithBasePath
                className="img-fluid"
                alt={`Image ${index + 1}`}
                srcType='new'
                src={`${process.env.REACT_APP_PUBLIC_BASE_API_URL}/medias?key=${image?.key}&width=${100}`}
              />
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CarGallery;
