import React, { useEffect, useRef, useState } from 'react';
import ImageWithBasePath from '../../../core/data/img/ImageWithBasePath';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useSelector } from 'react-redux';

const CarImagesCarousel = () => {
  const carDetails = useSelector((state: any) => state?.carReducer?.singleCarDetails);

  const [carImages, setCarImages] = useState<any>([]);

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const sliderRef1 = useRef(null);
  const sliderRef2 = useRef(null);

  useEffect(() => {
    setNav1(sliderRef1.current);
    setNav2(sliderRef2.current);
  }, []);

  const settings1 = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: nav2 || undefined,
    ref: (slider: any) => (sliderRef1.current = slider),
  };

  const settings2 = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    focusOnSelect: true,
    asNavFor: nav1 || undefined,
    ref: (slider: any) => (sliderRef2.current = slider),
  };

  useEffect(() => {
    if (carDetails?.images?.length > 0) {
      setCarImages(carDetails?.images);
    }
  }, [carDetails])

  return (
    <div className="detail-product">
      <div className="slider detail-bigimg">
        <Slider {...settings1}>
          {carImages?.map((image: any, index: any) => (
            <div key={index} className="product-img">
              <ImageWithBasePath srcType='new' src={`${process.env.REACT_APP_PUBLIC_BASE_API_URL}/medias?key=${image?.key}&width=${100}`} alt={`Slide ${index + 1}`} />
            </div>
          ))}
        </Slider>
      </div>
      <div className="slider slider-nav-thumbnails">
        <Slider {...settings2}>
          {carImages?.map((image: any, index: any) => (
            <div key={index}>
              <ImageWithBasePath
                srcType='new'
                src={`${process.env.REACT_APP_PUBLIC_BASE_API_URL}/medias?key=${image?.key}&width=${100}`}
                alt={`Thumbnail ${index + 1}`}
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default CarImagesCarousel;
