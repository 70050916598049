import { createSlice } from '@reduxjs/toolkit';



// <------------------------- Thunk For Login ------------------------->
// export const fetchCars: any = createAsyncThunk('/car/fetchCars', async (body?: any) => {
//     // console.log("body ===>", body);

//     // const requestOptions = {
//     //     method: "POST",
//     //     headers: { "Content-Type": "application/json" },
//     //     body: JSON.stringify(body),
//     // };

//     if (body?.url) {
//         const response: any = await doGetApiCall(body);
//         const res = await response.json();
//         console.log("Api Response --------->", res);
//         return res.result;
//     }
// });

interface NotificationState {
    // loading: 'idle' | 'pending' | 'succeeded' | 'failed'
    open: boolean,
    message: string,
    subText: string,
    alertType: 'success' | 'error' | 'warning' | 'info' | '',
    borderClass: string,
}

const initialState: NotificationState = {
    open: false,
    message: "",
    alertType: "",
    
    subText: "",
    borderClass: "",
}

const notificationSlice = createSlice({
    name: 'Notification',
    initialState,
    reducers: {
        openNotification(state, action) {
            state.open = true;
            state.message = action.payload.message;
            state.subText = action.payload.subText;
            state.alertType = action.payload.alertType;
            state.borderClass = action.payload.borderClass;
        },
        closeNotification(state) {
            state.open = false;
            state.message = '';
            state.subText = '';
            state.alertType = '';
            state.borderClass = '';
        },
    },
    // extraReducers: (builder) => {
    //     // Add reducers for additional action types here, and handle loading state as needed

    //     // <------ Reducers for fetchCars ------> 
    //     builder.addCase(fetchCars.pending, (state, action) => {
    //         console.log("pending action ==>", action);
    //         state.loading = 'pending';
    //     });
    //     builder.addCase(fetchCars.fulfilled, (state, action) => {
    //         console.log("succeeded action ==>", action);
    //         state.loading = 'succeeded';
    //     })
    //     builder.addCase(fetchCars.rejected, (state, action) => {
    //         console.log("rejected action ==>", action);
    //         state.loading = 'failed';
    //     })
    // },
})

// Action creators are generated for each case reducer function
export const { openNotification, closeNotification } = notificationSlice.actions;
export default notificationSlice.reducer;