import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Autocomplete from "react-google-autocomplete";
import DatePicker from "../DatePicker";
import TimePicker from "../TimePicker";
import Tabs from "../Tabs";
import Button from "../buttons/blueBtn";
import { useNavigate } from "react-router-dom";
import { all_routes } from "../../../router/all_routes";
import IconInputField from "../InputFields/IconInputField";
import { getCookie, removeCookie, setCookie } from "../../../../utils/cookieUtils";
import { useDispatch, useSelector } from "react-redux";
import { closeNotification, openNotification } from "../../../../redux/reducers/notificationReducer";
import { createQuotes } from "../../../../redux/reducers/quoteReducer";

const RelocationBannerCard = ({ selectedTab, onTabSelect }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = getCookie('token');
  const routes = all_routes;

  // const loggedInUser = useSelector((state: any) => state?.authReducer?.login_data);
  const { quoteLoading, quoteMessage } = useSelector((state: any) => state?.quoteReducer);

  const handleSubmit = () => {
    if (selectedTab === "Enterprise") handleEnterPriseSubmit(formik.values, formik);
    else handleIndividualSubmit(formik.values);
  };

  const validationSchema = Yup.object({
    pickupLocation: Yup.string().required("Pickup location is required"),
    dropoffLocation: Yup.string().required("Drop location is required"),
    name: Yup.string().when([], (value, schema) => {
      return selectedTab === "Enterprise" ? schema.required("Enterprise name is required") : schema.nullable();
    }),
    email: Yup.string().when([], (value, schema) => {
      return selectedTab === "Enterprise" ? schema.email("Invalid email").required("Email is required for Enterprise") : schema.nullable();
    }),
    NIFnum: Yup.string().when([], (value, schema) => {
      return selectedTab === "Enterprise" ? schema.required("NIF number is required for Enterprise") : schema.nullable();
    }),
    fleetSize: Yup.string().when([], (value, schema) => {
      return selectedTab === "Enterprise" ? schema.required("Fleet size is required for Enterprise") : schema.nullable();
    }),
    pickupDate: Yup.string().required("Pickup date is required"),
    dropoffDate: Yup.string()
      .required("Dropoff date is required")
      .test("is-greater", "Dropoff date can not less than pickup date", function (value) {
        const { pickupDate } = this.parent;
        return !pickupDate || !value || new Date(value) >= new Date(pickupDate);
      }),
    pickupTime: Yup.string().required("Pickup time is required"),
    dropoffTime: Yup.string()
      .required("Dropoff time is required")
      .test("is-valid-time", "Dropoff time should be greater than pickup time", function (value) {
        const { pickupDate, dropoffDate, pickupTime } = this.parent;
        if (pickupDate === dropoffDate && pickupTime && value) {
          const pickupTimeDate = new Date(`1970-01-01T${pickupTime}`);
          const dropoffTimeDate = new Date(`1970-01-01T${value}`);
          return dropoffTimeDate > pickupTimeDate;
        }
        return true; // Valid if dates are different or times are empty
      }),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      email: "",
      pickupLocation: "",
      pickupCountry: "",
      pickupCity: "",
      pickupPincode: "",
      dropoffLocation: "",
      dropoffCountry: "",
      dropoffCity: "",
      dropoffPincode: "",
      pickupDate: "",
      dropoffDate: "",
      pickupTime: "",
      dropoffTime: "",
      NIFnum: "",
      fleetSize: "",
      pickupLat: "",
      pickupLng: "",
      dropoffLat: "",
      dropoffLng: ""
    },
    validationSchema,
    onSubmit: handleSubmit
  });

  const handleEnterPriseSubmit = async (values: any, { setSubmitting, resetForm }: any) => {
    const formattedData = {
      email: values.email,
      name: values.name,
      pickupLocation: values.pickupLocation,
      dropoffLocation: values.dropoffLocation,
      pickupDate: `${(new Date(values.pickupDate)).toISOString().split('T')[0]}`,
      dropoffDate: `${(new Date(values.dropoffDate)).toISOString().split('T')[0]}`,
      NIFnum: values.NIFnum,
      fleetSize: values.fleetSize
    };
    dispatch(createQuotes(formattedData));
    resetForm();
    setSubmitting(false);
  };

  const handleIndividualSubmit = (values: any) => {
    if (token) {
      const data = {
        pickupStreetAddress: values.pickupLocation,
        pickupCountry: values.pickupCountry,
        pickupCity: values.pickupCity,
        pickupPincode: values.pickupPincode,
        dropoffStreetAddress: values.dropoffLocation,
        dropoffCountry: values.dropoffCountry,
        dropoffCity: values.dropoffCity,
        dropoffPincode: values.dropoffPincode,
        pickupDate: values.pickupDate,
        dropoffDate: values.dropoffDate,
        pickupTime: values.pickupTime,
        dropoffTime: values.dropoffTime,
        pickupLat: values.pickupLat,
        pickupLng: values.pickupLng,
        dropoffLat: values.dropoffLat,
        dropoffLng: values.dropoffLng
      };
      setCookie('locationData', data, 1);
      removeCookie('CarId');
      removeCookie('stepperstep');
      removeCookie('rentAmount');
      navigate(routes.relocateCar);
    } else navigate(routes.login);
  };

  const getAddressComponent = (addressComponents: any, type: any) => {
    const component = addressComponents?.find((component: any) =>
      component.types.includes(type)
    );
    return component ? component.long_name : "";
  };

  useEffect(() => {
    if (quoteLoading === 'succeeded') {
      dispatch(openNotification({
        open: true,
        message: quoteMessage,
        // message: "Thank you for reaching out! We will get back to you soon.",
        alertType: 'success',
        subText: '',
        borderClass: 'success'
      }));
      setTimeout(() => dispatch(closeNotification()), 4000);
    }
  }, [quoteLoading])

  return (
    <div className="relocation-banner-card">
      {!getCookie('isLoggedIn') &&
        <Tabs
          tabs={["Enterprise", "Individual"]}
          onSelect={onTabSelect}
          selectedTab={selectedTab}
        />
      }
      <div className="search-box-banner" style={{ padding: "0px" }}>
        <form onSubmit={formik.handleSubmit} >
          <ul className="align-items-center">
            {selectedTab === "Enterprise" && (<>
              {/* Enterprise Name */}
              <div className="relocation-card-column">
                <IconInputField
                  label="Name"
                  placeholder="Enterprise Name"
                  iconClass="feather icon-user"
                  value={formik.values.name}
                  onChange={(e) => formik.setFieldValue("name", e.target.value)}
                />
                {formik.touched.name && formik.errors.name && <div className="error text-danger font-short"> {formik.errors.name} </div>}
              </div>

              {/* Enterprise Email */}
              <div className="relocation-card-column">
                <IconInputField
                  label="Email Id"
                  placeholder="Enter Email Id"
                  iconClass="feather icon-mail"
                  value={formik.values.email}
                  onChange={(e) => formik.setFieldValue("email", e.target.value)}
                />
                {formik.touched.email && formik.errors.email && <div className="error text-danger font-short"> {formik.errors.email} </div>}
              </div>
            </>)
            }

            {/* Pickup Location - pickupLocation */}
            <div className="relocation-card-column">
              <div className="input-block">
                <label>Pickup Location</label>
                <div className="group-img">
                  <Autocomplete
                    apiKey={process.env.REACT_APP_PUBLIC_GOOGLE_API_KEY}
                    onPlaceSelected={(place: any) => {
                      const addressComponents = place?.address_components;
                      const streetAddress = place?.formatted_address;
                      const country = getAddressComponent(addressComponents, "country");
                      const city = getAddressComponent(addressComponents, "locality") || getAddressComponent(addressComponents, "administrative_area_level_1"); // Fallback for certain regions
                      const pincode = getAddressComponent(addressComponents, "postal_code");
                      formik.setFieldValue("pickupLocation", streetAddress);
                      formik.setFieldValue("pickupLat", place?.geometry?.location?.lat());
                      formik.setFieldValue("pickupLng", place?.geometry?.location?.lng());
                      formik.setFieldValue("pickupCountry", country);
                      formik.setFieldValue("pickupCity", city);
                      formik.setFieldValue("pickupPincode", pincode);
                    }}
                    placeholder="Enter Street Address"
                    className="form-control"
                    options={{ types: ["address"] }}
                  />
                  <span> <i className="feather icon-map-pin" /></span>
                </div>
                {formik.touched.pickupLocation && formik.errors.pickupLocation && <div className="error text-danger font-short">{formik.errors.pickupLocation} </div>}
              </div>
            </div>

            {/* Drop Location - dropoffLocation */}
            <div className="relocation-card-column">
              <div className="input-block">
                <label>Drop Location</label>
                <div className="group-img">
                  <Autocomplete
                    apiKey={process.env.REACT_APP_PUBLIC_GOOGLE_API_KEY}
                    onPlaceSelected={(place: any) => {
                      const addressComponents = place?.address_components;
                      const streetAddress = place?.formatted_address;
                      const country = getAddressComponent(addressComponents, "country");
                      const city = getAddressComponent(addressComponents, "locality") || getAddressComponent(addressComponents, "administrative_area_level_1"); // Fallback for certain regions
                      const pincode = getAddressComponent(addressComponents, "postal_code");
                      formik.setFieldValue("dropoffLocation", streetAddress);
                      formik.setFieldValue("dropoffLat", place?.geometry?.location?.lat());
                      formik.setFieldValue("dropoffLng", place?.geometry?.location?.lng());
                      formik.setFieldValue("dropoffCountry", country);
                      formik.setFieldValue("dropoffCity", city);
                      formik.setFieldValue("dropoffPincode", pincode);
                    }}
                    placeholder="Enter Street Address"
                    className="form-control"
                    options={{ types: ["address"] }}
                  />
                  <span> <i className="feather icon-map-pin" /></span>
                </div>
                {formik.touched.dropoffLocation && formik.errors.dropoffLocation && <div className="error text-danger font-short"> {formik.errors.dropoffLocation}</div>}
              </div>
            </div>

            {/* pickupDate */}
            <div className="half-column-group">
              <DatePicker
                label="Pickup Date"
                value={formik.values.pickupDate}
                placeholder="04/11/2023"
                onChange={(e: any) => { formik.setFieldValue("pickupDate", e.value); }}
              />
              {formik.touched.pickupDate && formik.errors.pickupDate && <div className="error text-danger font-short" style={{ marginLeft: '10px' }}>{formik.errors.pickupDate} </div>}
            </div>

            {/* pickupTime */}
            <div className="half-column-group">
              <TimePicker
                label="Pickup Time"
                placeholder="11:00 AM"
                // value={formik.values.pickupTime}
                onChange={(time) => formik.setFieldValue("pickupTime", time ? time.format("HH:mm") : "")}
              />
              {formik.touched.pickupTime && formik.errors.pickupTime && <div className="error text-danger font-short" style={{ marginLeft: '10px' }}>{formik.errors.pickupTime} </div>}
            </div>

            {/* dropoffDate */}
            <div className="half-column-group">
              <DatePicker
                label="Drop off Date"
                value={formik.values.dropoffDate} placeholder="04/11/2023"
                onChange={(e: any) => { formik.setFieldValue("dropoffDate", e.value) }}
              />
              {formik.touched.dropoffDate && formik.errors.dropoffDate && <div className="error text-danger font-short" style={{ marginLeft: '10px' }}>{formik.errors.dropoffDate} </div>}
            </div>

            {/* dropoffTime */}
            <div className="half-column-group">
              <TimePicker label="Dropoff Time" placeholder="11:00 AM" // value={dropoffTime}
                onChange={(time: any) => { formik.setFieldValue("dropoffTime", time ? time.format("HH:mm") : "") }}
              />
              {formik.touched.dropoffTime && formik.errors.dropoffTime && <div className="error text-danger font-short" style={{ marginLeft: '10px' }}>{formik.errors.dropoffTime} </div>}
            </div>

            {selectedTab === "Enterprise" && (
              <>
                {/* NIFnum */}
                <div className="half-column-group">
                  <IconInputField
                    label="NIF number"
                    placeholder="Enter NIF number"
                    value={formik.values.NIFnum}
                    onChange={(e) => formik.setFieldValue("NIFnum", e.target.value)}
                  />
                  {formik.touched.NIFnum && formik.errors.NIFnum && <div className="error text-danger font-short"> {formik.errors.NIFnum}</div>}
                </div>

                {/* fleetSize */}
                <div className="half-column-group">
                  <IconInputField
                    label="Fleet Size"
                    placeholder="Enter Fleet Size"
                    value={formik.values.fleetSize}
                    onChange={(e) => formik.setFieldValue("fleetSize", e.target.value)}
                  />
                  {formik.touched.fleetSize && formik.errors.fleetSize && <div className="error text-danger font-short">{formik.errors.fleetSize} </div>}
                </div>
              </>
            )}

            <div className="relocation-card-column">
              <Button label={selectedTab === "Enterprise" ? "Get a Quote" : "Submit"} className="relocation-search-btn" type="submit" />
            </div>
          </ul>
        </form>
      </div>
    </div>
  );
};

export default RelocationBannerCard;
