import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { doGetApiCall } from '../../utils/apiconfig';



const BASE_API_URL = process.env.REACT_APP_PUBLIC_BASE_API_URL;

// <------------------------- Thunk For Getting Carlist ------------------------->
export const fetchCars: any = createAsyncThunk('/car/fetchCars', async (body?: any) => {
    // console.log("body ===>", body);

    if (body?.endpoint) {
        try {
            const res: any = await doGetApiCall({ url: `${BASE_API_URL}/${body.endpoint}` });
            // console.log("Api Response in thunk --------->", res);
            // if (!res.error) handleUserData(res?.result);
            // else handleError("Failed to fetch user data.");
            return res;
        } catch (err) {
            console.error("Error in fetching cars list:", err);
            // handleError("Error fetching user data.");
        }
    }

});

// <------------------------- Thunk For Getting Car Details ------------------------->
export const fetchCarById: any = createAsyncThunk('/car/fetchCarById', async (id: any) => {
    // console.log("body ===>", body);

    try {
        const res: any = await doGetApiCall({ url: `${BASE_API_URL}/cars/${id}` });
        // console.log("Api Response in thunk --------->", res);
        // if (!res.error) handleResponse(res?.result);
        // else handleError("Failed to fetch user data.");
        return res;
    } catch (err) {
        console.error("Error in fetching car details:", err);
        // handleError("Error in fetching car details.");
    }
});

interface CarState {
    loading: 'idle' | 'pending' | 'succeeded' | 'failed',
    code: string,
    error: boolean,
    message: string,
    carList: any[],
    totalCars: number;
    singleCarDetails: any;
}

const initialState: CarState = {
    loading: 'idle',
    code: '',
    error: false,
    message: '',
    carList: [],
    totalCars: 0,
    singleCarDetails: {}
}

const carSlice = createSlice({
    name: 'car',
    initialState,
    reducers: {
        // set_signup_data(state, action) {
        //     state.carList = action?.payload;
        // },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed

        // <------ Reducers for fetchCars ------> 
        builder.addCase(fetchCars.pending, (state) => {
            state.loading = 'pending';
        });
        builder.addCase(fetchCars.fulfilled, (state, action) => {
            // console.log("fetchCars fulfilled action ----->", action);
            state.loading = 'succeeded';
            if (action?.payload) {
                const { code = '', error = false, message = '', result = [], dataCount = 0, status = null } = action.payload;
                state.code = code;
                state.error = error;
                state.message = message;
                if (status === 200) {
                    state.carList = result;
                    state.totalCars = dataCount;
                }
            }
        })
        builder.addCase(fetchCars.rejected, (state, action) => {
            state.loading = 'failed';
            if (action?.payload?.error) state.carList = [...state.carList];
        })

        // <------ Reducers for fetchCarById ------> 
        builder.addCase(fetchCarById.pending, (state) => {
            state.loading = 'pending';
        });
        builder.addCase(fetchCarById.fulfilled, (state, action) => {
            // console.log("fetchCarById fulfilled action ----->", action);
            state.loading = 'succeeded';
            if (action?.payload) {
                const { code = '', error = false, message = '', result = [], status = null } = action.payload;
                state.code = code;
                state.error = error;
                state.message = message;
                if (status === 200) state.singleCarDetails = result;
                else state.singleCarDetails = {};
            }
        })
        builder.addCase(fetchCarById.rejected, (state, action) => {
            state.loading = 'failed';
            if (action?.payload?.error) state.carList = [...state.carList];
        })
    },
})

// Action creators are generated for each case reducer function
// export const { set_signup_data, } = carSlice.actions;
export default carSlice.reducer;