import React from "react";
import IconButton from "../../../common/ui_components/buttons/IconButton";

type Props = {
  title?: string;
  searchInput?: string;
  setSearchInput: (value: string) => void;
  navigatePath?: () => void;
  itemCount?: number;
  show_btn?: boolean;
  addBtnLabel?: string;
}

const UserBookingTableHeader = ({ title, searchInput, setSearchInput, navigatePath, itemCount, show_btn, addBtnLabel = 'Relocate Car' }: Props) => {
  return (
    <div className="card-header">
      <div className="row align-items-center">
        <div className="col-md-5">
          <h4>
            {title} <span>{itemCount}</span>
          </h4>
        </div>
        <div className="col-md-7 text-md-end">
          <div className="table-search">
            <div id="tablefilter">
              <label>
                <input
                  type="text"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  placeholder="Search"
                  className="inputsearch"
                />
              </label>
            </div>
            {show_btn &&
              <IconButton
                onClick={navigatePath}
                label={addBtnLabel}
                iconClass="feather icon-plus-circle"
              />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserBookingTableHeader;
