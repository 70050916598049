import React from "react";

const SuccessMessage = ({status, orderNumber}:any) => {
  return (
    <div className="success-book">
      <span className="success-icon" >
        {status === "success" ? <i className="fa-solid fa-check-double" /> : <i className="fa-solid fa-exclamation"></i>}
        {/* <i className="fa-solid fa-check-double" /> */}
        {/* <i className="fa-solid fa-exclamation"></i> */}
      </span>
      <h5>{status === "success" ? "Thank you! Your Order has been Received !" : "Transaction failed"}</h5>
      <h5 className="order-no">
        Order Number : <span>{orderNumber}</span>
      </h5>
    </div>
  );
};

export default SuccessMessage;
