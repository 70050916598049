import React from 'react'
import { NotificationCheckboxItem, NotificationToggle } from './NotificationCheckboxItem'

const NotificationSection = () => {
    return (
        <div className="notification-grid">
            <div className="notification-checkbox">
                <h5>Notify me when...</h5>
                <ul className="nav">
                    <NotificationCheckboxItem label="Special Offers & Discounts" checked={true} />
                    <NotificationCheckboxItem label="Booking Confirmations" checked={true} />
                    <NotificationCheckboxItem label="When new car added" checked={true} />
                </ul>
            </div>
            <NotificationToggle label="Mobile Push notifications" description="Receive push notification when you allow the option" checked={true} />
            <NotificationToggle label="Desktop notifications" description="Receive desktop notification when you allow the option" checked={true} />
            <NotificationToggle label="Email notifications" description="Receive notifications through mails when you allow the option" checked={true} />
        </div>
    )
}

export default NotificationSection