import React, { useEffect, useState } from 'react'
import DashboardLayout from '../DashboardLayout'
import QuoteList from './QuoteList'
import SortingHeader from '../UserPayments/SortingHeader'
import { useDispatch, useSelector } from 'react-redux'
import { closeNotification, openNotification } from '../../../../redux/reducers/notificationReducer'

const EnterpriseDashboard = () => {
    const dispatch = useDispatch();
    
    const { quoteLoading, quoteMessage, quoteList } = useSelector((state: any) => state?.quoteReducer);

    const [listData, setListData] = useState<any[]>([]);
    const [activeStatus, setActiveStatus] = useState("All");

    useEffect(() => {
        if (quoteLoading === 'succeeded') {
            dispatch(openNotification({
                open: true,
                message: quoteMessage,
                // message: "Thank you for reaching out! We will get back to you soon.",
                alertType: 'success',
                subText: '',
                borderClass: 'success'
            }));
            setTimeout(() => dispatch(closeNotification()), 4000);
        }
    }, [quoteLoading])

    useEffect(() => {
        console.log('quoteList ------->', quoteList);
        if (quoteList?.length > 0) setListData(quoteList)
    }, [quoteList])

    return (
        <DashboardLayout title="Enterprise Dashboard" subtitle="Enterprise Dashboard">
            <div className="content-header">
                <h4>Dashboard</h4>
            </div>
            <SortingHeader onStatusChange={setActiveStatus} />
            {listData?.length > 0 ? <QuoteList tableData={listData} activeStatus={activeStatus} /> : 'No data found'}
        </DashboardLayout>
    )
}

export default EnterpriseDashboard