import React from 'react'
import ImageWithBasePath from '../../../core/data/img/ImageWithBasePath';

type Props = {
    title: string;
    value: string | number;
    iconSrc: string;
    linkTo: string;
    linkText: string;
    iconClass?: string;
  }
import { Link } from 'react-router-dom';

const DashBoardItem = ({title, value,iconSrc,linkTo,linkText, iconClass = ''}:Props) => {
    return (
      <div className="col-lg-3 col-md-6 d-flex">
        <div className="widget-box flex-fill">
          <div className="widget-header">
            <div className="widget-content">
              <h6>{title}</h6>
              <h3>{value}</h3>
            </div>
            <div className="widget-icon">
              <span className={iconClass}>
                <ImageWithBasePath src={iconSrc} alt="icon" />
              </span>
            </div>
          </div>
          <Link to={linkTo} className="view-link">
            {linkText} <i className="feather icon-arrow-right" />
          </Link>
        </div>
      </div>
    );
}

export default DashBoardItem