import React from 'react'
import Button from '../../../common/ui_components/buttons/blueBtn'
import FileUploader from '../../../common/ui_components/FileUploader'

const FreelanceDocumnets = () => {
    return (
        <form action="#">
            <div className="profile-info-grid">
                <div className="profile-info-header">
                    <h5>Freelance Documents</h5>
                    <p>Documents</p>
                </div>
                <div className="profile-inner">
                    <div className="row">
                        <div className="col-md-12">
                            <FileUploader label="Upload Document" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="profile-submit-btn">
                <Button label="Cancel" className="btn btn-secondary" />
                <Button label="Save Changes" className="btn btn-primary" />
            </div>
        </form>
    )
}

export default FreelanceDocumnets