import React from 'react'
import SecurityItem from './SecurityItem';
import ChangePassword from '../UserSectionModals/changepassword';
import ChangePhoneNumber from '../UserSectionModals/changephonenumber';
import DeactiveAccount from '../UserSectionModals/deactiveaccount';
import ChangeEmailAddress from '../UserSectionModals/changeemailaddress';
import DeleteModal from '../UserSectionModals/DeleteModal';

const SecuritySection = () => {

    const securityItems = [
        {
            title: "Password",
            description: "Last Changed 15 Oct 2023, 09:00 AM",
            btnLabel: "Change",
            btnClass: "btn-secondary",
            modalTarget: "#change_password",
        },
        // {
        //     title: "Phone Number Verification",
        //     description: "Verified Mobile Number : 7261937185",
        //     btnLabel: "Change",
        //     btnClass: "btn-secondary",
        //     modalTarget: "#change_phone_number",
        //     additionalBtn: true,
        // },
        // {
        //     title: "Email Verification",
        //     description: "Verified Email : info@example.com",
        //     btnLabel: "Change",
        //     btnClass: "btn-secondary",
        //     modalTarget: "#change_email",
        //     additionalBtn: true,
        // },
        {
            title: "Deactivate Account",
            description: "Last Changed 16 Nov 2023, 02:00 PM",
            btnLabel: "Deactivate",
            btnClass: "btn-secondary",
            modalTarget: "#deactive_account",
        },
        // {
        //     title: "Delete Account",
        //     description: "Last Changed 30 Dec 2023, 08:40 PM",
        //     btnLabel: "Delete",
        //     btnClass: "btn-danger",
        //     modalTarget: "#delete_account",
        // },
    ];

    return (
        <>
            <div className="row">
                {securityItems.map((item, index) => (
                    <SecurityItem key={index} {...item} />
                ))}
            </div>
            <ChangePassword />
            <ChangePhoneNumber />
            <ChangeEmailAddress />
            <DeactiveAccount />
            <DeleteModal id="delete_account"
                title="Delete Account"
                message="Are you sure want to delete?" />
        </>

    )
}

export default SecuritySection