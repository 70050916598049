import React from 'react'
import { Link } from 'react-router-dom'
import ImageWithBasePath from '../../../core/data/img/ImageWithBasePath'

const SocialLoginComponent = () => {
  return (
    <><div className="login-or">
    <span className="or-line" />
    <span className="span-or-log">
      Or, log in with your email
    </span>
  </div>
  {/* Social Login */}
  <div className="social-login">
    <Link
      to="#"
      className="d-flex align-items-center justify-content-center input-block btn google-login w-100"
    >
      <span>
        <ImageWithBasePath
          src="assets/img/icons/google.svg"
          className="img-fluid"
          alt="Google"
        />
      </span>
      Log in with Google
    </Link>
  </div>
  <div className="social-login">
    <Link
      to="#"
      className="d-flex align-items-center justify-content-center input-block btn google-login w-100"
    >
      <span>
        <ImageWithBasePath
          src="assets/img/icons/facebook.svg"
          className="img-fluid"
          alt="Facebook"
        />
      </span>
      Log in with Facebook
    </Link>
  </div>
  {/* /Social Login */}</>
  )
}

export default SocialLoginComponent