// WorkingProcessSection.js
import React from "react";
import { cardData } from "../../../core/data/json/common_static_data";
import ProcessCard from "../../common/ui_components/cards/ProcessCard";

const WorkingProcessSection = () => {
  return (
    <section className="section services-new popular-explore">
      <div className="card_container">
        <div className="section-heading" data-aos="fade-down">
          <h2>How it works</h2>
          <p>Lorem Ipsum has been the industry standard dummy text ever since the 1500s,</p>
        </div>
        <div className="row">
          {cardData.map((card, index) => (
            <ProcessCard key={index} card={card} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default WorkingProcessSection;








// services old code followed by template----->

// import React from "react";
// import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";

// const workingProcessSection = () => {

  
//   return (
//     <section className="section services">
//         <div className="service-right">
//           <ImageWithBasePath
//             src="assets/img/bg/service-right.svg"
//             className="img-fluid"
//             alt="services right"
//           />
//         </div>
//         <div className="container">
//           {/* Heading title*/}
//           <div className="section-heading" data-aos="fade-down">
//             <h2>How It Works</h2>
//             <p>
//               Booking a car rental is a straightforward process that typically
//               involves the following steps
//             </p>
//           </div>
//           {/* /Heading title */}
//           <div className="services-work">
//             <div className="row">
//               <div
//                 className="col-lg-4 col-md-4 col-12 d-flex"
//                 data-aos="fade-down"
//               >
//                 <div className="services-group service-date flex-fill">
//                   <div className="services-icon border-secondary">
//                     <ImageWithBasePath
//                       className="icon-img bg-secondary"
//                       src="assets/img/icons/services-icon-01.svg"
//                       alt="Choose Locations"
//                     />
//                   </div>
//                   <div className="services-content">
//                     <h3>1. Choose Date & Locations</h3>
//                     <p>
//                       Determine the date & location for your car rental.
//                       Consider factors such as your travel itinerary,
//                       pickup/drop-off locations (e.g., airport, city center),
//                       and duration of rental.
//                     </p>
//                   </div>
//                 </div>
//               </div>
//               <div
//                 className="col-lg-4 col-md-4 col-12  d-flex"
//                 data-aos="fade-down"
//               >
//                 <div className="services-group service-loc flex-fill">
//                   <div className="services-icon border-warning">
//                     <ImageWithBasePath
//                       className="icon-img bg-warning"
//                       src="assets/img/icons/services-icon-02.svg"
//                       alt="Choose Locations"
//                     />
//                   </div>
//                   <div className="services-content">
//                     <h3>2. Pick-Up Locations</h3>
//                     <p>
//                       Check the availability of your desired vehicle type for
//                       your chosen dates and location. Ensure that the rental
//                       rates, taxes, fees, and any additional charges.
//                     </p>
//                   </div>
//                 </div>
//               </div>
//               <div
//                 className="col-lg-4 col-md-4 col-12 d-flex"
//                 data-aos="fade-down"
//               >
//                 <div className="services-group service-book flex-fill">
//                   <div className="services-icon border-dark">
//                     <ImageWithBasePath
//                       className="icon-img bg-dark"
//                       src="assets/img/icons/services-icon-03.svg"
//                       alt="Choose Locations"
//                     />
//                   </div>
//                   <div className="services-content">
//                     <h3>3. Book your Car</h3>
//                     <p>
//                       Once you`&lsquo;`ve found car rental option, proceed to
//                       make a reservation. Provide the required information,
//                       including your details, driver`&lsquo;`s license, contact
//                       info, and payment details.
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//   );
// }
// export default workingProcessSection;
