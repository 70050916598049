import React, { useState } from "react";
import Breadcrumbs from "../../common/ui_components/breadcrumbs";
import BillingSteps from "./BillingSteps";
import BookingSidebar from "./BookingSidebar";
import BookingDetails from "./BookingDetails";
import BookingSuccess from "./BookingConfirmation";
import BookingCheckout from "./BookingPayment";
// import { useLocation } from "react-router-dom";
// import { useSelector } from "react-redux";

// const carData = {
//   imageSrc: "assets/img/car-list-4.jpg",
//   name: "Chevrolet Camaro",
//   registration_no: "Registration Number - 012A22",
//   car_type:"Car Type - Sedan",
//   estimatedTotal: "$3541",
// };

const MainBookingPage = () => {
  // const location = useLocation();
  // const { bookingFormValues } = location.state;

  // const carDetails = useSelector((state: any) => state?.carReducer?.singleCarDetails);

  // const [headerLocationData, setHeaderLocationData] = useState<any>();

  // useEffect(() => {
  //   if (Object.keys(carDetails).length > 0) {
  //     setHeaderLocationData({
  //       pickupLocation: carDetails?.pickupLocation?.street,
  //       pickupDate: carDetails?.pickupDate,
  //       dropoffLocation: carDetails?.dropoffLocation?.street,
  //       dropoffDate: carDetails?.dropoffDate
  //     });
  //   }
  // }, [carDetails])

  const [currentStep, setCurrentStep] = useState(1); // 1: Details, 2: Checkout, 3: Booking Confirmed

  const steps = [
    {
      iconSrc: "assets/img/icons/booking-head-icon-03.svg",
      title: "Details",
      isCompleted: currentStep > 1, 
    },
    {
      iconSrc: "assets/img/icons/booking-head-icon-04.svg",
      title: "Checkout",
      isCompleted: currentStep > 2,
    },
    {
      iconSrc: "assets/img/icons/booking-head-icon-05.svg",
      title: "Booking Confirmed",
      isCompleted: currentStep > 3,
    },
  ];

  const renderStepComponent = () => {
    switch (currentStep) {
      case 1:
        return <BookingDetails onNext={() => setCurrentStep(2)} />;
      case 2:
        return (
          <BookingCheckout
            onNext={() => setCurrentStep(3)}
            onBack={() => setCurrentStep(1)}
          />
        );
      case 3:
        return <BookingSuccess />;
      default:
        return null;
    }
  };

  // useEffect(() => {
  //   if(Object.keys(bookingFormValues)?.length > 0){
  //     setCurrentStep(3)
  //   }
  // }, [bookingFormValues])


  return (
    <div>
      <Breadcrumbs title="Checkout" subtitle="Checkout" maintitle={""}
        // searchdata={headerLocationData}
      />
      <div className="booking-new-module">
        <div className="dashboard_container">
          <div className="booking-wizard-head">
            <div className="row align-items-center">
              <div className="col-xl-4 col-lg-3">
                <div className="booking-head-title">
                  <h4>Reserve Your Car</h4>
                  <p>Complete the following steps</p>
                </div>
              </div>
              <BillingSteps steps={steps} />
            </div>
          </div>
          <div className="booking-detail-info">
            <div className="row">
              {currentStep !== 3 && <BookingSidebar />}
              {renderStepComponent()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainBookingPage;

