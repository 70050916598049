import React from "react";
import PaymentMethodOption from "./PaymentMethodOption";

const PaymentMethodSelector = () => {
  const paymentMethods = [
    {
      src: "assets/img/icons/payment-method-01.svg",
      alt: "Payment Method 1",
      name: "payment_type",
      isChecked: true,
    },
    {
      src: "assets/img/icons/payment-method-02.svg",
      alt: "Payment Method 2",
      name: "payment_type",
      isChecked: false,
    },
  ];

  return (
    <div className="payment-method-types">
      <h5>Choose your Payment Method</h5>
      <ul>
        {paymentMethods.map((method, index) => (
          <PaymentMethodOption key={index} {...method} />
        ))}
      </ul>
    </div>
  );
};

export default PaymentMethodSelector;
