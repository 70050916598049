import React from 'react'
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";

const UserBookingCalender = () => {

    const events = [{ title: "Meeting", start: new Date() }];
    function renderEventContent(eventInfo: any) {
        return (
            <>
                <b>{eventInfo.timeText}</b>
                <i>{eventInfo.event.title}</i>
            </>
        );
    }
    return (
        <div className="col-lg-12 ">
            <div className="card calendar-card mb-0">
                <div className="card-body">
                    <div id="calendar" />
                    <FullCalendar
                        plugins={[dayGridPlugin]}
                        initialView="dayGridMonth"
                        weekends={false}
                        events={events}
                        eventContent={renderEventContent}
                    />
                </div>
            </div>
        </div>
    )
}

export default UserBookingCalender