import React from "react";
import BookingBannerSection from "./BookingBannerSection";


export const BookingLandingPage = () => {
  return (
    <>
      <BookingBannerSection />
    </>
  );
}
