import React from 'react';

const ViewSwitcher = ({ setCurrentView, currentView }:any) => {
  return (
    <div className="grid-listview">
      <ul>
        <li onClick={() => setCurrentView('grid')}>
          <a className={`feather icon-grid ${currentView === 'grid' ? 'active' : ''}`} />
        </li>
        <li onClick={() => setCurrentView('list')}>
          <a className={`feather icon-list ${currentView === 'list' ? 'active' : ''}`} />
        </li>
        <li onClick={() => setCurrentView('map')}>
          <a className={`feather icon-map-pin ${currentView === 'map' ? 'active' : ''}`} />
        </li>
      </ul>
    </div>
  );
};

export default ViewSwitcher;
