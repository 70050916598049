import React, { useState } from 'react'
import UserBookingTableHeader from '../UserBooking/UserBookingTableHeader'
import ListingTable from '../../../common/ui_components/ListingTable';
import BookingAvatar from '../../../common/ui_components/BookingAvatar';

import ListActionMenu from '../../../common/ui_components/ListActionMenu';
import useHelperFuncs from '../../../../utils/hooks/useHelperFuncs';
// import BookingActionDropdown from '../../../common/ui_components/BookingActionDropdown';
// import BookingStatus from '../../../common/ui_components/UserBookingStatus';


const PaymentListSection = ({ tableData, activeStatus }: any) => {
    const [searchInput, setSearchInput] = useState("");
    const { toTitleCase, getStatusClass } = useHelperFuncs();

    const filteredData = tableData?.filter((rowData: any) => {
        const values = rowData && Object.values(rowData).map((value: any) => value.toString().toLowerCase());
        return values?.some((value: any) => value?.includes(searchInput.toLowerCase()));
    });

    const actionButtons = [
        { icon: "feather icon-file-plus", label: "View Invoice", target: "#view_invoice" },
        // { icon: "feather icon-trash-2", label: "Delete", target: "#delete_modal" },
    ];

    const columnConfig = [
        // {
        //     field: "checkbox",
        //     header: "",
        //     body: () => (
        //         <label className="custom_check w-100">
        //             <input type="checkbox" name="username" />
        //             <span className="checkmark" />
        //         </label>
        //     ),
        // },
        {
            field: "bookingID",
            header: "Booking ID",
        },
        {
            field: "carName",
            header: "Car Name",
            body: (rowData: any) => <BookingAvatar img={rowData?.imgSrc} carName={rowData?.carName} deliveryStatus={rowData?.carType} />,
        },
        {
            field: "paidOn",
            header: "Paid on",
        },
        {
            field: "total",
            header: "Total",
        },
        {
            field: "mode",
            header: "Mode",
        },
        {
            field: "status",
            header: "Status",
            body: (rowData: any) => <span className={getStatusClass(rowData.status)}>{toTitleCase(rowData.status)}</span>,
        },
        {
            field: "action",
            header: "Action",
            body: () => <ListActionMenu actions={actionButtons} />,
        },
    ];

    return (
        <div className="row">
            <div className="col-lg-12 d-flex">
                <div className="card book-card flex-fill mb-0">
                    <UserBookingTableHeader
                        title={activeStatus}
                        searchInput={searchInput}
                        setSearchInput={setSearchInput}
                        itemCount={filteredData.length}
                    />
                    <ListingTable filteredData={filteredData} columnConfig={columnConfig} />
                </div>
            </div>
        </div>
    )
}

export default PaymentListSection