import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Autocomplete from "react-google-autocomplete";
import DatePicker from "../../common/ui_components/DatePicker";
import IconButton from "../../common/ui_components/buttons/IconButton";
import { all_routes } from "../../router/all_routes";

const validationSchema = Yup.object().shape({
  pickupLocation: Yup.string().required("Pickup Location is required"),
  dropoffLocation: Yup.string().required("Return Location is required"),
  pickupDate: Yup.date().required("Pickup Date is required"),
  // dropoffDate: Yup.date().required("Return Date is required"),
  dropoffDate: Yup.string()
    .required("Dropoff date is required")
    .test("is-greater", "Dropoff date can not less than pickup date", function (value) {
      const { pickupDate } = this.parent;
      return !pickupDate || !value || new Date(value) > new Date(pickupDate);
    }),
});

const SearchSection = ({ searchdata }: any) => {
  const navigate = useNavigate();
  const routes = all_routes;

  useEffect(() => {
    // console.log('searchdata in search component -------', searchdata);
    
    if (searchdata) {
      formik.setValues({
        pickupLat: searchdata?.pickupLat || null,
        pickupLng: searchdata?.pickupLng || null,
        pickupLocation: searchdata?.pickupLocation || "",
        pickupCountry: searchdata?.pickupCountry || "",
        pickupCity: searchdata?.pickupCity || "",
        pickupPincode: searchdata?.pickupPincode || "",
        dropoffLat: searchdata?.dropoffLat || null,
        dropoffLng: searchdata?.dropoffLng || null,
        dropoffLocation: searchdata?.dropoffLocation || "",
        dropoffCountry: searchdata?.dropoffCountry || "",
        dropoffCity: searchdata?.dropoffCity || "",
        dropoffPincode: searchdata?.dropoffPincode || "",
        pickupDate: searchdata?.pickupDate || null,
        dropoffDate: searchdata?.dropoffDate || null,
      });
    }
  }, [])

  const formik = useFormik({
    initialValues: {
      pickupLat: null,
      pickupLng: null,
      pickupLocation: "",
      pickupCountry: "",
      pickupCity: "",
      pickupPincode: "",
      dropoffLat: null,
      dropoffLng: null,
      dropoffLocation: "",
      dropoffCountry: "",
      dropoffCity: "",
      dropoffPincode: "",
      pickupDate: null,
      dropoffDate: null,
    },
    validationSchema,
    onSubmit: () => {
      navigate(routes.CarList, { state: { searchdata: formik.values } });
    },
  });

  const handleSearchClick = () => {
    formik.handleSubmit(); // Call the Formik submit function directly
  };

  const getAddressComponent = (addressComponents: any, type: any) => {
    const component = addressComponents?.find((component: any) =>
      component.types.includes(type)
    );
    return component ? component.long_name : "";
  };

  return (
    <div className="banner-search">
      <div className="search_container">
        <div className="search-box-banner">
          <form onSubmit={formik.handleSubmit}>
            <ul className="align-items-center">
              <div className="column-group-main">
                <div className="input-block" style={{ position: 'relative' }}>
                  <label>Pickup Location</label>
                  <div className="group-img">
                    <Autocomplete
                      apiKey={process.env.REACT_APP_PUBLIC_GOOGLE_API_KEY}
                      onPlaceSelected={(place: any) => {
                        const addressComponents = place?.address_components;
                        const streetAddress = place?.formatted_address;
                        const country = getAddressComponent(addressComponents, "country");
                        const city =
                          getAddressComponent(addressComponents, "locality") ||
                          getAddressComponent(
                            addressComponents,
                            "administrative_area_level_1"
                          ); // Fallback for certain regions
                        const pincode = getAddressComponent(addressComponents, "postal_code");
                        formik.setFieldValue("pickupLocation", streetAddress);
                        formik.setFieldValue("pickupLat", place?.geometry?.location?.lat());
                        formik.setFieldValue("pickupLng", place?.geometry?.location?.lng());
                        formik.setFieldValue("pickupCountry", country);
                        formik.setFieldValue("pickupCity", city);
                        formik.setFieldValue("pickupPincode", pincode);
                      }}
                      placeholder="Enter City, Airport, or Address"
                      className="form-control"
                      options={{ types: ["address"] }}
                      value={formik.values.pickupLocation}
                      onChange={(e: any) => formik.setFieldValue("pickupLocation", e.target.value)}
                    />
                    <span>
                      <i className="feather icon-map-pin" />
                    </span>
                  </div>
                  {formik.touched.pickupLocation && formik.errors.pickupLocation ? (
                    <div className="error text-danger font-short" style={{ position: 'absolute' }}>
                      {formik.errors.pickupLocation}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="column-group-main">
                <div className="input-block" style={{ position: 'relative' }}>
                  <label>Return Location</label>
                  <div className="group-img">
                    <Autocomplete
                      apiKey={process.env.REACT_APP_PUBLIC_GOOGLE_API_KEY}
                      onPlaceSelected={(place: any) => {
                        const addressComponents = place?.address_components;
                        const streetAddress = place?.formatted_address;
                        const country = getAddressComponent(addressComponents, "country");
                        const city =
                          getAddressComponent(addressComponents, "locality") ||
                          getAddressComponent(
                            addressComponents,
                            "administrative_area_level_1"
                          ); // Fallback for certain regions
                        const pincode = getAddressComponent(addressComponents, "postal_code");
                        formik.setFieldValue("dropoffLocation", streetAddress);
                        formik.setFieldValue("dropoffLat", place?.geometry?.location?.lat());
                        formik.setFieldValue("dropoffLng", place?.geometry?.location?.lng());
                        formik.setFieldValue("dropoffCountry", country);
                        formik.setFieldValue("dropoffCity", city);
                        formik.setFieldValue("dropoffPincode", pincode);
                      }}
                      placeholder="Enter Street Address"
                      className="form-control"
                      options={{ types: ["address"] }}
                      value={formik.values.dropoffLocation}
                      onChange={(e: any) => formik.setFieldValue("dropoffLocation", e.target.value)}
                    />
                    <span>
                      <i className="feather icon-map-pin" />
                    </span>
                  </div>
                  {formik.touched.dropoffLocation && formik.errors.dropoffLocation ? (
                    <div className="error text-danger font-short" style={{ position: 'absolute' }}>
                      {formik.errors.dropoffLocation}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="column-group-last" style={{ position: 'relative' }}>
                <DatePicker
                  label="Pickup Date"
                  value={formik.values.pickupDate}
                  onChange={(e: any) => formik.setFieldValue("pickupDate", e.value)}
                  placeholder="04/11/2023"
                />
                {formik.touched.pickupDate && formik.errors.pickupDate ? (
                  <div className="error text-danger font-short" style={{ position: 'absolute' }}>
                    {formik.errors.pickupDate}
                  </div>
                ) : null}
              </div>
              <div className="column-group-last" style={{ position: 'relative' }}>
                <DatePicker
                  label="Return Date"
                  value={formik.values.dropoffDate}
                  onChange={(e: any) => formik.setFieldValue("dropoffDate", e.value)}
                  placeholder="04/11/2023"
                />
                {formik.touched.dropoffDate && formik.errors.dropoffDate ? (
                  <div className="error text-danger font-short" style={{ position: 'absolute' }}>
                    {formik.errors.dropoffDate}
                  </div>
                ) : null}
              </div>

              <div className="column-group-last">
                <IconButton
                  onClick={handleSearchClick}
                  label="Search"
                  iconClass="fa fa-search"
                />
              </div>
            </ul>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SearchSection;
