import React from "react";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../../core/data/img/ImageWithBasePath";
import useHelperFuncs from "../../../utils/hooks/useHelperFuncs";

const BookingAvatar = ({ link = '#', device = 'lg', img, carName, deliveryStatus }: any) => {
  const { toTitleCase } = useHelperFuncs();

  return (
    <div className="table-avatar">
      <Link to={link} className={`avatar avatar-${device} flex-shrink-0`}>
        <ImageWithBasePath className="avatar-img" src={img} srcType='new' alt="Image" />
      </Link>
      <div className="table-head-name flex-grow-1">
        <Link to={link}>{toTitleCase(carName)}</Link>
        <p>{toTitleCase(deliveryStatus)}</p>
      </div>
    </div>
  );
};

export default BookingAvatar;
