import React from "react";
import RelocationBannerSection from "./BannerForRelocation";


export const RelocationLandingPage = () => {
  return (
    <>
      <RelocationBannerSection />
    </>
  );
}
