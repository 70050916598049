import React, { useEffect, useState } from 'react';
import ImageWithBasePath from '../../../core/data/img/ImageWithBasePath';
import { useSelector } from 'react-redux';
import useHelperFuncs from '../../../utils/hooks/useHelperFuncs';



const CarExtraServices = () => {
  const { getCarExtraServices } = useHelperFuncs();
  const carDetails = useSelector((state: any) => state?.carReducer?.singleCarDetails);

  const [carExtraServices, setCarExtraServices] = useState<any>([]);

  useEffect(() => {
    if (Object.keys(carDetails).length > 0) {
      setCarExtraServices(getCarExtraServices(carDetails?.specifications));
    }
  }, [carDetails])


  return (
    <div className="review-sec pb-0">
      <div className="review-header">
        <h4>Extra Service</h4>
      </div>
      <div className="lisiting-service">
        <div className="row">
          {carExtraServices?.map((service: any) => (
            <div
              key={service.id}
              className="servicelist d-flex align-items-center col-xxl-3 col-xl-4 col-sm-6"
            >
              <div className="service-img">
                <ImageWithBasePath src={service.imgSrc} alt="Icon" />
              </div>
              <div className="service-info">
                <p>{service.label}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CarExtraServices;
