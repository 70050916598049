// import React, { useEffect, useState } from 'react'
// import { FormControl} from "@mui/material";
// import ImageWithBasePath from '../../../core/data/img/ImageWithBasePath';
// import { doGetApiCall } from '../../../utils/apiconfig';
// import CheckboxLabels from "../../common/ui_components/CheckBox";
// import CircularIndeterminate from '../../common/ui_components/CircularProgress';

// const ExtraServicesRelocation = ({ formik }: any) => {
//   const [loading, setLoading] = useState(true);
//   const [specifications, setSpecification] = useState([])

//   useEffect(() => {
//     getSpecification();
//   }, [])


//   const getSpecification = async () => {
//     const data = {
//       url: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/masterdatas/${"specifications"}`,
//     };
//     await doGetApiCall(data)
//       .then((res: any) => {
//         console.log("res=====>", res)
//         if (!res.error) {
//           setSpecification(res.result)
//         }
//       })
//       .catch((err) => {
//         // alert("something went wrong !")
//         console.error(err, '<<-- err');
//       })
//       .finally(() => {
//         setLoading(false);
//       });
//   }

//   console.log("specifications===>", specifications)

//   return (
//     <div style={{marginBottom:'20px'}}>
//       <p>Extra Service</p>
//       <div className="d-flex align-items-center over-mt">
//         <div className="border-line"></div>
//         <div className={`exc-border`}></div>
//       </div>
//       {
//         loading === true ? <CircularIndeterminate /> : (specifications.map((item, index) => {
//           console.log("item==>",item)
//           return (<div className="d-flex flex-column gap-2" key={index}>
//             <div className="radio-options">
//               <div className="d-flex gap-2 align-items-center width-div">
//                 <ImageWithBasePath src='assets/img/icons/extraService1.png' alt="Icon" className="p-2 d-flex align-items-center justify-content-center rounded-2 shadow-lg border border-2" />
//                 <p>GPS Navigation Systems</p>
//               </div>
//               <FormControl>
//                 <CheckboxLabels label="" />
//               </FormControl>
//             </div>
//             {formik.touched.gps && formik.errors.gps ? (
//               <div className="error text-danger font-short">
//                 {formik.errors.gps}
//               </div>
//             ) : null}
//           </div>)
//         }))
//       }
//     </div>
//   )
// }

// export default ExtraServicesRelocation


import React, { useEffect, useState } from 'react';
import { FormControl } from "@mui/material";
import { doGetApiCall } from '../../../utils/apiconfig';
import CheckboxLabels from "../../common/ui_components/CheckBox";
import CircularIndeterminate from '../../common/ui_components/CircularProgress';
// import { fetchMediaData } from '../../../services/auth/MediaData';
import ImageWithBasePath from '../../../core/data/img/ImageWithBasePath';

const ExtraServicesRelocation = ({ formik }: any) => {
  const [loading, setLoading] = useState(true);
  const [specifications, setSpecifications] = useState<any[]>([]);

  useEffect(() => {
    getSpecifications();
  }, []);

  const getSpecifications = async () => {
    const data = {
      url: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/masterdatas/type?type=specifications`,
    };
    await doGetApiCall(data)
      .then((res: any) => {
        if (!res.error) {
          setSpecifications(res.result);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCheckboxChange = (checked: boolean, id: string) => {
    const updatedSpecs = checked
      ? [...formik.values.specifications, id]
      : formik.values.specifications.filter((specId: string) => specId !== id);

    formik.setFieldValue("specifications", updatedSpecs);
  };

  return (
    <div style={{ marginBottom: '20px' }}>
      <p>Extra Service</p>
      <div className="d-flex align-items-center over-mt">
        <div className="border-line"></div>
        <div className={`exc-border`}></div>
      </div>
      {
        loading ? <div className='spinner_container'><CircularIndeterminate /></div> : (
          specifications.map((item) => (
            <div className="d-flex flex-column gap-2" key={item._id}>
              <div className="radio-options">
                <div className="d-flex gap-2 align-items-center width-div">
                <ImageWithBasePath src='assets/img/icons/extraService1.png' alt="Icon" className="p-2 d-flex align-items-center justify-content-center rounded-2 shadow-lg border border-2" />
                  <p>{item.name}</p>
                </div>
                <FormControl>
                  <CheckboxLabels
                    label=""
                    checked={formik.values.specifications.includes(item._id)}
                    onChange={(e) => handleCheckboxChange(e.target.checked, item._id)}
                  />
                </FormControl>
              </div>
              {formik.touched.specifications && formik.errors.specifications ? (
                <div className="error text-danger font-short">
                  {formik.errors.specifications}
                </div>
              ) : null}
            </div>
          ))
        )
      }
    </div>
  );
};

export default ExtraServicesRelocation;
