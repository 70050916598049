import { all_routes } from "../../../feature-module/router/all_routes";

export const servicesData = [
  {
    title: "Free Car Rent",
    description:
      "Rent a car for free by delivering it to its next destination. Experience the convenience and adventure of driving a car to its next location without the usual costs.",
    buttonText: "Get a Free Rental",
    imageSrc: "assets/img/service1.png",
    route: "/rent",
  },
  {
    title: "Move Your Car",
    description:
      "Easily move your vehicle with our professional drivers or flexible options. Enjoy a stress-free moving experience with our reliable and convenient service.",
    buttonText: "Move Your Car",
    imageSrc: "assets/img/service3.png",
    route: "/relocate",
  },
  {
    title: "Drive and Win",
    description:
      "Drive cars and earn rewards by completing missions and inviting other drivers.Enjoy the adventure while benefiting from our unique reward system.",
    buttonText: "Start Driving",
    imageSrc: "assets/img/service2.png",
    route: "/professional-driver",
  },
];

export const vehicle_name = [
  { id: "1", name: "Sedan" },
  // { id: "2", name: "SUV (Sport Utility Vehicle)" },
  // { id: "3", name: "Hatchback" },
  // { id: "4", name: "Convertible" },
  // { id: "5", name: "Coupe" },
  // { id: "6", name: "Wagon" },
  // { id: "7", name: "Pickup Truck" },
  // { id: "8", name: "Minivan" },
  // { id: "9", name: "Crossover" },
  // { id: "10", name: "Luxury Car" },
];

export const cardData = [
  {
    imgSrc: "assets/img/icons/wp1.png",
    title: "Fill out Pricing Calculator",
    content:
      "Input your details to receive estimates and make informed decisions. Simplify planning with precise cost calculations at your fingertips.",
    imgClass: "choose-black",
  },
  {
    imgSrc: "assets/img/icons/wp2.png",
    title: "Get instant Quote",
    content:
      "Request a personalized quote by providing your details through our simple form. Receive a tailored estimate based on your specific needs and preferences.",
    imgClass: "choose-primary",
  },
  {
    imgSrc: "assets/img/icons/wp3.png",
    title: "Confirm and Schedule",
    content:
      "Choose your preferred date and time for a smooth and organized travel experience. Finalize everything to ensure your journey goes as planned.",
    imgClass: "choose-black",
  },
];

export const features = [
  {
    imgSrc: "assets/img/icons/s1.png",
    title: "Secure and Reliable",
    description:
      "Once you've found car rental option, proceed to make a reservation",
  },
  {
    imgSrc: "assets/img/icons/s2.png",
    title: "Real-Time Monitoring",
    description:
      "Once you've found car rental option, proceed to make a reservation",
  },
  {
    imgSrc: "assets/img/icons/s3.png",
    title: "Verified Users",
    description:
      "Once you've found car rental option, proceed to make a reservation",
  },
  {
    imgSrc: "assets/img/icons/s4.png",
    title: "Highly Qualified Drivers",
    description:
      "Once you've found car rental option, proceed to make a reservation",
  },
  {
    imgSrc: "assets/img/icons/s5.png",
    title: "Most Economical Service",
    description:
      "Once you've found car rental option, proceed to make a reservation",
  },
  {
    imgSrc: "assets/img/icons/s6.png",
    title: "Sustainable & Eco-Friendly",
    description:
      "Once you've found car rental option, proceed to make a reservation",
  },
  {
    imgSrc: "assets/img/icons/s7.png",
    title: "Transparent Pricing",
    description:
      "Once you've found car rental option, proceed to make a reservation",
  },
  {
    imgSrc: "assets/img/icons/s8.png",
    title: "Professional Platform",
    description:
      "Once you've found car rental option, proceed to make a reservation",
  },
];

export const numberOptions = [
  { name: "5" },
  { name: "10" },
  { name: "15" },
  { name: "20" },
  { name: "25" },
  { name: "30" },
];

export const sortOptions = [
  { name: "Newest" },
  { name: "Relevance" },
  { name: "Low to High" },
  { name: "High to Low" },
  { name: "Best Rated" },
  { name: "Distance" },
  { name: "Popularity" },
];

export const carsData = [
  {
    route: "/rent/details",
    imageSrc: "assets/img/cars/car2.png",
    name: "Audi A3 2019 new",
    category: "Audi",
    details: [
      {
        icon: "assets/img/icons/car-parts-02.svg",
        text: "10 KM",
        alt: "10 KM",
      },
      {
        icon: "assets/img/icons/car-parts-03.svg",
        text: "Petrol",
        alt: "Petrol",
      },
      {
        icon: "assets/img/icons/car-parts-04.svg",
        text: "Power",
        alt: "Power",
      },
      {
        icon: "assets/img/icons/car-parts-06.svg",
        text: "4 Persons",
        alt: "Persons",
      },
    ],
  },
  {
    route: "/rent/details",
    imageSrc: "assets/img/cars/car3.png",
    name: "Audi A3 2019 new",
    category: "Audi",
    details: [
      {
        icon: "assets/img/icons/car-parts-02.svg",
        text: "10 KM",
        alt: "10 KM",
      },
      {
        icon: "assets/img/icons/car-parts-03.svg",
        text: "Petrol",
        alt: "Petrol",
      },
      {
        icon: "assets/img/icons/car-parts-04.svg",
        text: "Power",
        alt: "Power",
      },
      {
        icon: "assets/img/icons/car-parts-06.svg",
        text: "4 Persons",
        alt: "Persons",
      },
    ],
  },
  {
    route: "/rent/details",
    imageSrc: "assets/img/cars/car2.png",
    name: "Audi A3 2019 new",
    category: "Audi",
    details: [
      {
        icon: "assets/img/icons/car-parts-02.svg",
        text: "10 KM",
        alt: "10 KM",
      },
      {
        icon: "assets/img/icons/car-parts-03.svg",
        text: "Petrol",
        alt: "Petrol",
      },
      {
        icon: "assets/img/icons/car-parts-04.svg",
        text: "Power",
        alt: "Power",
      },
      {
        icon: "assets/img/icons/car-parts-06.svg",
        text: "4 Persons",
        alt: "Persons",
      },
    ],
  },
  {
    route: "/rent/details",
    imageSrc: "assets/img/cars/car4.png",
    name: "Audi A3 2019 new",
    category: "Audi",
    details: [
      {
        icon: "assets/img/icons/car-parts-02.svg",
        text: "10 KM",
        alt: "10 KM",
      },
      {
        icon: "assets/img/icons/car-parts-03.svg",
        text: "Petrol",
        alt: "Petrol",
      },
      {
        icon: "assets/img/icons/car-parts-04.svg",
        text: "Power",
        alt: "Power",
      },
      {
        icon: "assets/img/icons/car-parts-06.svg",
        text: "4 Persons",
        alt: "Persons",
      },
    ],
  },
  {
    route: "/rent/details",
    imageSrc: "assets/img/cars/car5.png",
    name: "Audi A3 2019 new",
    category: "Audi",
    details: [
      {
        icon: "assets/img/icons/car-parts-02.svg",
        text: "10 KM",
        alt: "10 KM",
      },
      {
        icon: "assets/img/icons/car-parts-03.svg",
        text: "Petrol",
        alt: "Petrol",
      },
      {
        icon: "assets/img/icons/car-parts-04.svg",
        text: "Power",
        alt: "Power",
      },
      {
        icon: "assets/img/icons/car-parts-06.svg",
        text: "4 Persons",
        alt: "Persons",
      },
    ],
  },
  {
    route: "/rent/details",
    imageSrc: "assets/img/cars/car6.png",
    name: "Audi A3 2019 new",
    category: "Audi",
    details: [
      {
        icon: "assets/img/icons/car-parts-02.svg",
        text: "10 KM",
        alt: "10 KM",
      },
      {
        icon: "assets/img/icons/car-parts-03.svg",
        text: "Petrol",
        alt: "Petrol",
      },
      {
        icon: "assets/img/icons/car-parts-04.svg",
        text: "Power",
        alt: "Power",
      },
      {
        icon: "assets/img/icons/car-parts-06.svg",
        text: "4 Persons",
        alt: "Persons",
      },
    ],
  },
  {
    route: "/rent/details",
    imageSrc: "assets/img/cars/car7.png",
    name: "Audi A3 2019 new",
    category: "Audi",
    details: [
      {
        icon: "assets/img/icons/car-parts-02.svg",
        text: "10 KM",
        alt: "10 KM",
      },
      {
        icon: "assets/img/icons/car-parts-03.svg",
        text: "Petrol",
        alt: "Petrol",
      },
      {
        icon: "assets/img/icons/car-parts-04.svg",
        text: "Power",
        alt: "Power",
      },
      {
        icon: "assets/img/icons/car-parts-06.svg",
        text: "4 Persons",
        alt: "Persons",
      },
    ],
  },
  // Add more car objects here...
];

export const data = [
  {
    id: "01",
    car_brand: "Ferrai",
    car_name: "Ferrari 458 MM Special",
    car_image: "assets/img/car-list-1.jpg",
    reviews: "(4.0) 160",
    address: "Newyork, USA",
    km: "3.0m",
    amount: "$160",
    lat: 53.470692,
    lng: -2.220328,
    icons: "'assets/img/icons/car-marker-01.svg'",
    profile_link: "listing-details.html",
    image: "assets/img/profiles/avatar-04.jpg",
  },
  {
    id: "02",
    car_brand: "BMW",
    car_name: "BMW 640 XI Gran Turismo",
    car_image: "assets/img/car-list-2.jpg",
    reviews: "(4.0) 165 ",
    address: "Pattaya, Thailand",
    km: "3.7m",
    amount: "$160",
    lat: 53.469189,
    lng: -2.199262,
    icons: "'assets/img/icons/car-marker-01.svg'",
    profile_link: "listing-details.html",
    image: "assets/img/profiles/avatar-03.jpg",
  },
  {
    id: "03",
    car_brand: "Ford",
    car_name: "Ford Mustang, Blue 2014",
    car_image: "assets/img/car-list-3.jpg",
    reviews: "(4.0) 165 ",
    address: "Lasvegas, USA",
    km: "4.0m",
    amount: "$150",
    lat: 53.468665,
    lng: -2.189269,
    icons: "default",
    profile_link: "listing-details.html",
    image: "assets/img/profiles/avatar-06.jpg",
  },
  {
    id: "04",
    car_brand: "Audi",
    car_name: "Audi A3 2019 new",
    car_image: "assets/img/car-list-5.jpg",
    reviews: "(4.0) 150 ",
    address: "Newyork, USA",
    km: "3.5m",
    amount: "$45",
    lat: 53.463894,
    lng: -2.17788,
    icons: "default",
    profile_link: "listing-details.html",
    image: "assets/img/profiles/avatar-03.jpg",
  },
  {
    id: "05",
    car_brand: "Ford",
    car_name: "Ford Mustang 4.0 AT",
    car_image: "assets/img/car-list-6.jpg",
    reviews: "(4.0) 170  ",
    address: "Lasvegas, USA",
    km: "4.1m",
    amount: "$90",
    lat: 53.466359,
    lng: -2.213314,
    icons: "default",
    profile_link: "listing-details.html",
    image: "assets/img/profiles/avatar-03.jpg",
  },
];

export const carImages = [
  { src: "assets/img/cars/car_d1.png" },
  { src: "assets/img/cars/car_d1.png" },
  { src: "assets/img/cars/car_d1.png" },
  { src: "assets/img/cars/car_d1.png" },
  { src: "assets/img/cars/car_d1.png" },
];

export const ExtraServices = [
  {
    id: 1,
    imgSrc: "assets/img/icons/extraService1.png",
    label: "GPS Navigation Systems",
  },
  {
    id: 2,
    imgSrc: "assets/img/icons/extraService2.png",
    label: "Wi-Fi Hotspot",
  },
  {
    id: 3,
    imgSrc: "assets/img/icons/extraService3.png",
    label: "Child Safety Seats",
  },
  {
    id: 4,
    imgSrc: "assets/img/icons/extraService4.png",
    label: "Fuel Options",
  },
  {
    id: 5,
    imgSrc: "assets/img/icons/extraService5.png",
    label: "Roadside Assistance",
  },
  {
    id: 6,
    imgSrc: "assets/img/icons/extraService6.png",
    label: "Satellite Radio",
  },
  {
    id: 7,
    imgSrc: "assets/img/icons/extraService7.png",
    label: "Additional Accessories",
  },
  {
    id: 8,
    imgSrc: "assets/img/icons/extraService8.png",
    label: "Express Check-in/out",
  },
];

export const specifications = [
  {
    id: 1,
    imgSrc: "assets/img/icons/speci1.png",
    label: "Body",
    value: "Sedan",
  },
  {
    id: 2,
    imgSrc: "assets/img/icons/speci2.png",
    label: "Make",
    value: "Nissan",
  },
  {
    id: 3,
    imgSrc: "assets/img/icons/speci3.png",
    label: "Transmission",
    value: "Automatic",
  },
  {
    id: 4,
    imgSrc: "assets/img/icons/speci4.png",
    label: "Fuel Type",
    value: "Diesel",
  },
  {
    id: 5,
    imgSrc: "assets/img/icons/speci5.png",
    label: "Mileage",
    value: "16 Km",
  },
  {
    id: 6,
    imgSrc: "assets/img/icons/speci6.png",
    label: "Drivetrain",
    value: "Front Wheel",
  },
  {
    id: 7,
    imgSrc: "assets/img/icons/speci7.png",
    label: "Year",
    value: "2018",
  },
  {
    id: 8,
    imgSrc: "assets/img/icons/speci8.png",
    label: "AC",
    value: "Air Condition",
  },
  {
    id: 9,
    imgSrc: "assets/img/icons/speci9.png",
    label: "VIN",
    value: "45456444",
  },
  {
    id: 10,
    imgSrc: "assets/img/icons/speci10.png",
    label: "Door",
    value: "4 Doors",
  },
  {
    id: 11,
    imgSrc: "assets/img/icons/speci11.png",
    label: "Brake",
    value: "ABS",
  },
  {
    id: 12,
    imgSrc: "assets/img/icons/speci12.png",
    label: "Engine (Hp)",
    value: "3,000",
  },
];

export const featuresData = [
  ["Multi-zone A/C", "Heated front seats", "Android Auto", "Navigation system"],
  ["Premium sound system", "Bluetooth", "Keyless Start", "Memory seat"],
  [
    "6 Cylinders",
    "Adaptive Cruise Control",
    "Intermittent wipers",
    "4 power windows",
  ],
];

export const CarImageGallery = [
  {
    thumb: "assets/img/cars/car2.png",
    big: "assets/img/cars/car2.png",
  },
  {
    thumb: "assets/img/cars/car2.png",
    big: "assets/img/cars/car2.png",
  },
  {
    thumb: "assets/img/cars/car2.png",
    big: "assets/img/cars/car2.png",
  },
  {
    thumb: "assets/img/cars/car2.png",
    big: "assets/img/cars/car2.png",
  },
];

export const SuggestedCarsData = [
  {
    route: "/rent/details",
    imageSrc: "assets/img/cars/sugg_car1.png",
    name: "Audi A3 2019 new",
    category: "Audi",
    details: [
      {
        icon: "assets/img/icons/car-parts-02.svg",
        text: "10 KM",
        alt: "10 KM",
      },
      {
        icon: "assets/img/icons/car-parts-03.svg",
        text: "Petrol",
        alt: "Petrol",
      },
      {
        icon: "assets/img/icons/car-parts-04.svg",
        text: "Power",
        alt: "Power",
      },
      {
        icon: "assets/img/icons/car-parts-06.svg",
        text: "4 Persons",
        alt: "Persons",
      },
    ],
  },
  {
    route: "/rent/details",
    imageSrc: "assets/img/cars/sugg_car2.png",
    name: "Audi A3 2019 new",
    category: "Audi",
    details: [
      {
        icon: "assets/img/icons/car-parts-02.svg",
        text: "10 KM",
        alt: "10 KM",
      },
      {
        icon: "assets/img/icons/car-parts-03.svg",
        text: "Petrol",
        alt: "Petrol",
      },
      {
        icon: "assets/img/icons/car-parts-04.svg",
        text: "Power",
        alt: "Power",
      },
      {
        icon: "assets/img/icons/car-parts-06.svg",
        text: "4 Persons",
        alt: "Persons",
      },
    ],
  },
  {
    route: "/rent/details",
    imageSrc: "assets/img/cars/sugg_car3.png",
    name: "Audi A3 2019 new",
    category: "Audi",
    details: [
      {
        icon: "assets/img/icons/car-parts-02.svg",
        text: "10 KM",
        alt: "10 KM",
      },
      {
        icon: "assets/img/icons/car-parts-03.svg",
        text: "Petrol",
        alt: "Petrol",
      },
      {
        icon: "assets/img/icons/car-parts-04.svg",
        text: "Power",
        alt: "Power",
      },
      {
        icon: "assets/img/icons/car-parts-06.svg",
        text: "4 Persons",
        alt: "Persons",
      },
    ],
  },
  {
    route: "/rent/details",
    imageSrc: "assets/img/cars/sugg_car1.png",
    name: "Audi A3 2019 new",
    category: "Audi",
    details: [
      {
        icon: "assets/img/icons/car-parts-02.svg",
        text: "10 KM",
        alt: "10 KM",
      },
      {
        icon: "assets/img/icons/car-parts-03.svg",
        text: "Petrol",
        alt: "Petrol",
      },
      {
        icon: "assets/img/icons/car-parts-04.svg",
        text: "Power",
        alt: "Power",
      },
      {
        icon: "assets/img/icons/car-parts-06.svg",
        text: "4 Persons",
        alt: "Persons",
      },
    ],
  },
  {
    route: "/rent/details",
    imageSrc: "assets/img/cars/sugg_car2.png",
    name: "Audi A3 2019 new",
    category: "Audi",
    details: [
      {
        icon: "assets/img/icons/car-parts-02.svg",
        text: "10 KM",
        alt: "10 KM",
      },
      {
        icon: "assets/img/icons/car-parts-03.svg",
        text: "Petrol",
        alt: "Petrol",
      },
      {
        icon: "assets/img/icons/car-parts-04.svg",
        text: "Power",
        alt: "Power",
      },
      {
        icon: "assets/img/icons/car-parts-06.svg",
        text: "4 Persons",
        alt: "Persons",
      },
    ],
  },
  {
    route: "/rent/details",
    imageSrc: "assets/img/cars/sugg_car3.png",
    name: "Audi A3 2019 new",
    category: "Audi",
    details: [
      {
        icon: "assets/img/icons/car-parts-02.svg",
        text: "10 KM",
        alt: "10 KM",
      },
      {
        icon: "assets/img/icons/car-parts-03.svg",
        text: "Petrol",
        alt: "Petrol",
      },
      {
        icon: "assets/img/icons/car-parts-04.svg",
        text: "Power",
        alt: "Power",
      },
      {
        icon: "assets/img/icons/car-parts-06.svg",
        text: "4 Persons",
        alt: "Persons",
      },
    ],
  },
  {
    route: "/rent/details",
    imageSrc: "assets/img/cars/sugg_car1.png",
    name: "Audi A3 2019 new",
    category: "Audi",
    details: [
      {
        icon: "assets/img/icons/car-parts-02.svg",
        text: "10 KM",
        alt: "10 KM",
      },
      {
        icon: "assets/img/icons/car-parts-03.svg",
        text: "Petrol",
        alt: "Petrol",
      },
      {
        icon: "assets/img/icons/car-parts-04.svg",
        text: "Power",
        alt: "Power",
      },
      {
        icon: "assets/img/icons/car-parts-06.svg",
        text: "4 Persons",
        alt: "Persons",
      },
    ],
  },
];

export const invoiceData = {
  invoiceNumber: "In983248782",
  billTo: {
    CustomerName: "abc xyz",
    phoneNo: "9087484288",
    address: "Zip code, City - Country",
  },
  invoiceFrom: {
    CompanyName: "abcdef pqr",
    phoneNo: "9087484288",
    address: "Address line 2 ,81111, City - Country",
  },
  invoiceDetails: [
    { label: "Issue Date", value: "27 Jul 2022" },
    { label: "Due Date", value: "27 Aug 2022" },
    { label: "Due Amount", value: "$ 1,54,22" },
    { label: "Recurring Invoice", value: "15 Months" },
    { label: "PO Number", value: "54515454" },
  ],
  items: [
    {
      Description: "Dreams Rental Cars",
      Category: "Kia Soul",
      Rate: "$1,110",
      Quantity: 2,
      Discount: "2 %",
      Amount: "$2,220",
    },
    // more items...
  ],
  paymentMethod: "Debit Card\nXXXXXXXXXXXX-2541\nHDFC Bank",
  paymentDetails: [
    { label: "Taxable", amount: "$6,660.00" },
    { label: "Additional Charges", amount: "$6,660.00" },
    { label: "Discount", amount: "- $ 3,300.00" },
    { label: "Sub total", amount: "$ 3,300.00" },
  ],
  totalAmount: "$143,300.00",
  notes: "Enter customer notes or any other details",
  terms: "Enter customer notes or any other details",
  signature: "assets/img/signature.png",
  signerName: "Harristemp",
};

export const bookingData = {
  orderNumber: "#123456",
  car: {
    name: "Chevrolet Camaro",
    location: "Miami St, Destin, FL 32550, USA",
    imageUrl: "assets/img/cars/car-05.jpg",
    totalAmount: "$4700",
  },
  pricingItems: [
    {
      description: "Doorstep delivery",
      subText: "(1 day)",
      isDanger: true,
      dangerText: "This does not include fuel",
      amount: "+ $60",
    },
    { description: "Trip Protection Fees", amount: "+ $25" },
    { description: "Convenience Fees", amount: "+ $2" },
    { description: "Tax", amount: "+ $2" },
    { description: "Refundable Deposit", amount: "+$1200" },
  ],
  pricingTotal: "+$1604",
  locationAndTimeItems: [
    { title: "Booking Type", details: ["Self Pickup"] },
    { title: "Rental Type", details: ["Hourly"] },
    {
      title: "Pickup",
      details: [
        "1230 E Springs Rd, Los Angeles, CA, USA",
        "04/18/2024 - 14:00",
      ],
    },
    {
      title: "Return",
      details: [
        "1230 E Springs Rd, Los Angeles, CA, USA",
        "04/18/2024 - 14:00",
      ],
    },
  ],
  billingInfoItems: [
    "Darren Jurel",
    "Mak Infotech",
    "1230 E Springs Rd, Los Angeles, CA, USA",
    "+1 124554 45445",
    "dj@example.com",
  ],
  paymentDetailsItems: [
    { title: "Payment Mode", details: ["Debit Card"] },
    { title: "Transaction ID", details: ["#13245454455454"] },
  ],
  additionalInfo: `Rental companies typically require customers to return the vehicle with a full tank of fuel. If the vehicle is returned with less than a full tank, customers may be charged for refueling the vehicle at a premium rate, often higher than local fuel prices.`,
};

export const bookings = [
  {
    route: all_routes.userBookings,
    imgSrc: "assets/img/cars/d1.png",
    carName: "Ferrari 458 MM Speciale",
    rentType: "Hourly",
    startDate: "15 Sep 2023, 11:30 PM",
    endDate: "15 Sep 2023, 1:30 PM",
    price: "$200",
    status: "Upcoming",
    statusClass: "secondary",
  },
  {
    route: all_routes.userBookings,
    imgSrc: "assets/img/cars/d2.png",
    carName: "Kia Soul 2016",
    rentType: "Hourly",
    startDate: "15 Sep 2023, 09:00 AM",
    endDate: "15 Sep 2023, 1:30 PM",
    price: "$300",
    status: "Upcoming",
    statusClass: "secondary",
  },
  {
    route: all_routes.userBookings,
    imgSrc: "assets/img/cars/d3.png",
    carName: "Toyota Camry SE 350",
    rentType: "Day",
    startDate: "18 Sep 2023, 09:00 AM",
    endDate: "18 Sep 2023, 05:00 PM",
    price: "$600",
    status: "Inprogress",
    statusClass: "warning",
  },
  {
    route: all_routes.userBookings,
    imgSrc: "assets/img/cars/d4.png",
    carName: "Audi A3 2019 new",
    rentType: "Weekly",
    startDate: "10 Oct 2023, 10:30 AM",
    endDate: "16 Oct 2023, 10:30 AM",
    price: "$800",
    status: "Completed",
    statusClass: "success",
  },
  {
    route: all_routes.userBookings,
    imgSrc: "assets/img/cars/d5.png",
    carName: "Acura Sport Version",
    rentType: "Monthly",
    startDate: "01 Dec 2023, 08:15 AM",
    endDate: "01 Jan 2024, 08:15 AM",
    price: "$1000",
    status: "Cancelled",
    statusClass: "danger",
  },
  {
    route: all_routes.userBookings,
    imgSrc: "assets/img/cars/d6.png",
    carName: "Ferrari 458 MM Speciale",
    rentType: "Hourly",
    startDate: "15 Sep 2023, 11:30 PM",
    endDate: "15 Sep 2023, 1:30 PM",
    price: "$200",
    status: "Upcoming",
    statusClass: "secondary",
  },
];

// Right now unused
export const Dashboardtransactions = [
  {
    carName: "Ferrari 458 MM Speciale",
    rentType: "Hourly",
    imageSrc: "assets/img/cars/d7.png",
    status: "Upcoming",
    statusClass: "badge-light-secondary",
    statusText: "On 15 Sep 2023, 11:30 PM",
  },
  {
    carName: "Chevrolet Pick Truck 3.5L",
    rentType: "Day",
    imageSrc: "assets/img/cars/d8.png",
    status: "Refund started",
    statusClass: "badge-light-warning",
    statusText: "Yet to receive",
  },
  {
    carName: "Toyota Tacoma 4WD",
    rentType: "Weekly",
    imageSrc: "assets/img/cars/d3.png",
    status: "Cancelled",
    statusClass: "badge-light-danger",
    statusText: "On 15 Sep 2023, 11:30 PM",
  },
  {
    carName: "Ford Mustang 4.0 AT",
    rentType: "Monthly",
    imageSrc: "assets/img/cars/d4.png",
    status: "Completed",
    statusClass: "badge-light-success",
    statusText: "On 20 Dec 2023, 05:20 PM",
  },
];



export const userBookings = [
  {
    bookingId: "#1001",
    carName: "Ferrari 458 MM Speciale",
    deliveryStatus: "Delivery",
    rentalType: "Hourly",
    pickupDeliveryLocation1: "45, Avenue ,Mark Street,",
    pickupDeliveryLocation2: "USA 15 Sep 2023, 09:30 AM",
    dropoffLocation1: "21, Avenue, Windham,",
    dropoffLocation2: "USA 15 Sep 2023, 11:30 AM",
    bookedOn: "15 Sep 2023, 09:00 AM",
    total: "$300",
    status: "Upcoming",
    img: "assets/img/cars/d1.png",
  },
  {
    bookingId: "#1002",
    carName: "Toyota Camry SE 350",
    deliveryStatus: "Self Pickup",
    rentalType: "Day",
    pickupDeliveryLocation1: "1646 West St, Grand Rapids",
    pickupDeliveryLocation2: "18 Sep 2023, 09:00 AM",
    dropoffLocation1: "26 Platinum Drive,Canonsburg",
    dropoffLocation2: "15 Sep 2023, 11:30 AM",
    bookedOn: "18 Sep 2023, 08:10 PM",
    total: "$500",
    status: "Inprogress",
    img: "assets/img/cars/d2.png",
  },
  {
    bookingId: "#1003",
    carName: "Kia Soul 2016",
    deliveryStatus: "Delivery",
    rentalType: "Weekly",
    pickupDeliveryLocation1: "14 Straford Park, Pittsburg",
    pickupDeliveryLocation2: "03 Oct 2023, 10:15 AM",
    dropoffLocation1: "11 Pleasant Hill,Pittsburg",
    dropoffLocation2: "10 Oct 2023, 10:15 AM",
    bookedOn: "21 Sep 2023, 04:15 PM",
    total: "$600",
    status: "Cancelled",
    img: "assets/img/cars/d3.png",
  },
  {
    bookingId: "#1004",
    carName: "Audi A3 2019 new",
    deliveryStatus: "Self Pickup",
    rentalType: "Monthly",
    pickupDeliveryLocation1: "63 White Pine Lane, Martinsville",
    pickupDeliveryLocation2: "05 Nov 2023, 02:30 PM",
    dropoffLocation1: "14 Roane Avenue, Herndon",
    dropoffLocation2: "05 Dec 2023, 02:30 PM",
    bookedOn: "04 Oct 2023, 08:00 AM",
    total: "$1500",
    status: "Inprogress",
    img: "assets/img/cars/d4.png",
  },
  {
    bookingId: "#1005",
    carName: "2018 Chevrolet Camaro",
    deliveryStatus: "Delivery",
    rentalType: "Day",
    pickupDeliveryLocation1: "24 Better Street, Kansas City",
    pickupDeliveryLocation2: "16 Nov 2023, 10:20 AM",
    dropoffLocation1: "77 Geraldine Lane, Newyork",
    dropoffLocation2: "17 Nov 2023, 10:20 AM",
    bookedOn: "16 Oct 2023, 12:30 PM",
    total: "$450",
    status: "Cancelled",
    img: "assets/img/cars/d6.png",
  },
  {
    bookingId: "#1006",
    carName: "Acura Sport Version",
    deliveryStatus: "Self pickup",
    rentalType: "Hourly",
    pickupDeliveryLocation1: "78 Cityview Drive, Glenolden",
    pickupDeliveryLocation2: "24 Nov 2023, 06:40 AM",
    dropoffLocation1: "66 Ottis Street, Shawnee",
    dropoffLocation2: "24 Nov 2023, 08:40 AM",
    bookedOn: "24 Oct 2023, 05:40 PM",
    total: "$250",
    status: "Completed",
    img: "assets/img/cars/d7.png",
  },
  {
    bookingId: "#1007",
    carName: "Toyota Tacoma 4WD",
    deliveryStatus: "Delivery",
    rentalType: "Monthly",
    pickupDeliveryLocation1: "41 Duke Lane, Branchburg",
    pickupDeliveryLocation2: "15 Dec 2023, 04:30 PM",
    dropoffLocation1: "80 Glory Road, Nashville",
    dropoffLocation2: "15 Jan 2024, 04:30 PM",
    bookedOn: "02 Nov 2023, 07:30 AM",
    total: "$1000",
    status: "Cancelled",
    img: "assets/img/cars/d8.png",
  },
];



export const userPaymentData = [
  {
    bookingID: "#1001",
    carName: "Ferrari 458 MM Speciale",
    paidOn: "15 Sep 2023, 09:30 AM",
    total: "$300",
    mode: "Wallet",
    feature: "Delivery",
    img: "assets/img/cars/d1.png",
    status: "Completed",
  },
  {
    bookingID: "#1002",
    carName: "Toyota Camry SE 350",
    paidOn: "23 Oct 2023, 12:00 PM",
    total: "$500",
    mode: "Paypal",
    feature: "Self Pickup",
    img: "assets/img/cars/d2.png",
    status: "Completed",
  },
  {
    bookingID: "#1003",
    carName: "Kia Soul 2016",
    paidOn: "02 Nov 2023, 10:30 AM",
    total: "$600",
    mode: "Stripe",
    feature: "Delivery",
    img: "assets/img/cars/d3.png",
    status: "Completed",
  },
  {
    bookingID: "#1004",
    carName: "Audi A3 2019 new",
    paidOn: "18 Dec 2023, 02:30 PM",
    total: "$1500",
    mode: "Stripe",
    feature: "Self Pickup",
    img: "assets/img/cars/d4.png",
    status: "Pending",
  },
  {
    bookingID: "#1005",
    carName: "2018 Chevrolet Camaron",
    paidOn: "05 Jan 2024, 08:00 AM",
    total: "$450",
    mode: "Wallet",
    feature: "Delivery",
    img: "assets/img/cars/d6.png",
    status: "Failed",
  },
  {
    bookingID: "#1006",
    carName: "Acura Sport Version",
    paidOn: "20 Feb 2024, 11:30 PM",
    total: "$250",
    mode: "Stripe",
    feature: "Self Pickup",
    img: "assets/img/cars/d7.png",
    status: "Completed",
  },
  {
    bookingID: "#1007",
    carName: "Toyota Tacoma 4WD",
    paidOn: "12 Mar 2024, 10:00 PM",
    total: "$1000",
    mode: "Paypal",
    feature: "Delivery",
    img: "assets/img/cars/d8.png",
    status: "Pending",
  },
]