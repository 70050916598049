import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

type Props = {
  label?: string;
  count?: number;
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const CheckboxItem = ({ label, count, checked, onChange }: Props) => {
  const displayLabel = count ? `${label} (${count})` : label;

  return (
    <FormGroup>
      <FormControlLabel
        control={<Checkbox checked={checked} onChange={onChange} />}
        label={displayLabel}
      />
    </FormGroup>
  );
};

export default CheckboxItem;
