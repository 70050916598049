import React from "react";
import { Link } from "react-router-dom";
import { all_routes } from "../../../router/all_routes";
import InputField from "../../../common/ui_components/InputFields/InputField";
import Button from "../../../common/ui_components/buttons/blueBtn";
import OTPVerificationModal from "./OTPVerificationModal";
import SuccessModal from "./SuccessModal";
import ModalWrapper from "./ModalWrapper";

const ChangePhoneNumber = () => {
  return (
    <div>
      <ModalWrapper id="change_phone_number" title="Change Phone Number">
        <form action="#">
          <InputField label="Current Phone Number" placeholder="Enter Phone Number" required={true} />
          <InputField label="New Phone Number" placeholder="Enter Phone Number" required={true} />
          <div className="modal-form-group">
            <p>
              <i className="feather icon-alert-circle" /> New Phone Number Only
              Updated Once You Verified
            </p>
          </div>
        </form>
        <div className="modal-btn modal-btn-sm">
          <Button label="Cancel" className="btn btn-secondary" />
          <Link
            to="#"
            className="btn btn-primary"
            data-bs-target="#otp_verification"
            data-bs-toggle="modal"
            data-bs-dismiss="modal"
          >
            Change Number
          </Link>
        </div>
      </ModalWrapper>
      <OTPVerificationModal />
      <SuccessModal
        title="Successful"
        message="Your Phone number has been successfully verified."
        link={all_routes.userDashboard}
        linkText="Go to Dashboard"
        modalId="verification_success"
      />
    </div>
  );
};

export default ChangePhoneNumber;
