import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "../buttons/blueBtn";
import InputField from "../InputFields/InputField";
import { useNavigate } from "react-router-dom";
import { all_routes } from "../../../router/all_routes";
import Autocomplete from "react-google-autocomplete";
import BookingFormSection from "../BookingFormSection";
import { getCookie } from "../../../../utils/cookieUtils";

const DriverBannerCard = () => {
  const navigate = useNavigate();
  const token = getCookie('token');
  const routes = all_routes;

  const validationSchema = Yup.object({
    fullName: Yup.string().required("Full Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    cityLocation: Yup.string().required("City location is required"),
  });

  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      cityLocation: "",
    },
    validationSchema,
    onSubmit: () => {
      handleRegister();
    },
  });

  const handleRegister = () => {
    if (token) {
      navigate(routes.userSettings);
    } else {
      navigate(routes.login);
    }
  };

  return (
    <div className="relocation-banner-card">
      <h4>For car drivers</h4>
      <div>
        Enter the details below to register yourself. Our team will contact you
        in the next 24 hours.
      </div>
      <div className="search-box-banner" style={{ padding: "0px" }}>
        <form onSubmit={formik.handleSubmit}>
          <ul className="align-items-center">
            <div className="relocation-card-column">
              <InputField
                label="Full Name"
                placeholder="Enter Name"
                value={formik.values.fullName}
                required
                onChange={(e: any) =>
                  formik.setFieldValue("fullName", e.target.value)
                }
              />
              {formik.touched.fullName && formik.errors.fullName && (
                <div className="error text-danger font-short" style={{marginLeft:'15px'}}>
                  {formik.errors.fullName}
                </div>
              )}
            </div>

            <div className="relocation-card-column">
              <InputField
                label="Email Address"
                placeholder="Enter Email"
                value={formik.values.email}
                required
                onChange={(e: any) =>
                  formik.setFieldValue("email", e.target.value)
                }
              />
              {formik.touched.email && formik.errors.email && (
                <div className="error text-danger font-short" style={{marginLeft:'15px'}}>
                  {formik.errors.email}
                </div>
              )}
            </div>

            <div className="relocation-card-column">
              <BookingFormSection>
                <label className="form-label"> CIty Location
                  <span className="text-danger"> *</span>
                </label>
                <div className="input-container">
                  <Autocomplete
                    apiKey={process.env.REACT_APP_PUBLIC_GOOGLE_API_KEY}
                    onPlaceSelected={(place) =>
                      formik.setFieldValue(
                        "cityLocation",
                        place.formatted_address
                      )
                    }
                    options={{ types: ["(cities)"] }}
                    placeholder="Enter City"
                    className="form-control"
                  />
                </div>
              </BookingFormSection>
              {formik.touched.cityLocation && formik.errors.cityLocation && (
                <div className="error text-danger font-short" style={{marginLeft:'15px'}}>
                  {formik.errors.cityLocation}
                </div>
              )}
            </div>

            <div className="relocation-card-column">
              <Button
                label="Register"
                className="relocation-search-btn"
              />
            </div>
          </ul>
        </form>
      </div>
    </div>
  );
};

export default DriverBannerCard;
