// SpecializationSection.js
import React from "react";
import { features } from "../../../core/data/json/common_static_data";
import FeatureCard from "../../common/ui_components/cards/FeatureCard";

const SpecializationSection = () => {
  return (
    <section className="section why-choose popular-explore">
      <div className="card_container">
        <div className="section-heading" data-aos="fade-down">
          <h2>Why We are <span style={{ color: '#0076D7' }}>Specialized!</span> Why Choose Us?</h2>
          <p>Flexymove is a win-win solution where all parties benefit. Our service is fully insured, and all fleets are covered.</p>
        </div>
        <div className="why-choose-group">
          <div className="row">
            {features.map((feature, index) => (
              <FeatureCard key={index} feature={feature} index={index} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SpecializationSection;
