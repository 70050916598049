import React from "react";

const ViewCarLocation = () => {
  return (
    <div className="review-sec share-car mt-0">
      <div className="review-header">
        <h4>View Car Location</h4>
      </div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3682.870405699623!2d88.46225287597733!3d22.621314079457033!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f89f003469095b%3A0xa63d75f3a277675c!2sUnderscore!5e0!3m2!1sen!2sin!4v1723025091940!5m2!1sen!2sin"
        loading="lazy"
        className="iframe-video"
      ></iframe>
      {/* <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6509170.989457427!2d-123.80081967108484!3d37.192957227641294!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fb9fe5f285e3d%3A0x8b5109a227086f55!2sCalifornia%2C%20USA!5e0!3m2!1sen!2sin!4v1669181581381!5m2!1sen!2sin"
        className="iframe-video"
      /> */}
    </div>
  );
};

export default ViewCarLocation;
