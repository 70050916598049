import { useDispatch } from 'react-redux';
import { removeAllCookies } from '../cookieUtils';
import { set_login_data } from '../../redux/reducers/authReducer';
import { all_routes } from '../../feature-module/router/all_routes';
import { useNavigate } from 'react-router-dom';
// import { useState } from 'react';

const useAuth = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const logout = () => {
        removeAllCookies();
        dispatch(set_login_data({}));
        navigate(all_routes.MainLanding)
    };

    return {
        logout,
    }
}

export default useAuth