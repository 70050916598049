import React from "react";
import ImageWithBasePath from "../../../core/data/img/ImageWithBasePath";

const PaymentMethodOption = ({ src, alt, name, isChecked }: any) => {
  return (
    <li>
      <label className="payment_custom_check">
        <input type="radio" name={name} defaultChecked={isChecked} />
        <span className="payment_checkmark">
          <span className="checked-title">
            <ImageWithBasePath src={src} alt={alt} />
          </span>
        </span>
      </label>
    </li>
  );
};

export default PaymentMethodOption;
