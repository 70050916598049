import React from "react";
import ImageWithBasePath from "../../../core/data/img/ImageWithBasePath";

const InvoiceHeader = () => {
  return (
    <div className="invoice-item">
      <div className="row">
        <div className="col-md-6">
          <div className="invoice-logo">
            <ImageWithBasePath src="assets/img/logo.png" alt="logo" />
          </div>
        </div>
        <div className="col-md-6">
          <div className="invoice-info">
            <h1>Invoice</h1>
            {/* <h6>
              Invoice Number : <span>{invoiceNumber}</span>
            </h6> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceHeader;
