

import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../common/ui_components/breadcrumbs";
import { invoiceData } from "../../../core/data/json/common_static_data";
import InvoiceContainer from "./InvoiceContainer";
import { useLocation, useNavigate } from "react-router-dom";
import { doGetApiCall } from "../../../utils/apiconfig";
import { all_routes } from "../../router/all_routes";

const InvoiceDetails = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const routes = all_routes;
  const params = new URLSearchParams(location.search);
  const car_id = params.get('car_id')
  const [carData, setCarData] = useState<any>({});

  useEffect(()=>{
    if(car_id !== null){
      getConfirmationData()
    }else{
      navigate(routes.MainLanding)
    }
  },[])


  const getConfirmationData = async () => {
    const data = {
      url: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/cars/${car_id}`,
    };
    await doGetApiCall(data)
      .then((res: any) => {
        if (!res.error) {
          setCarData(res.result)
        }
      })
      .catch((err) => {
        console.error(err);
      })
  };


  return (
    <div className="main-wrapper">
      <Breadcrumbs title="Invoice" subtitle="Relocate" maintitle={""} loactiondata='false' />
      <div className="invoice-section">
        <div className="container">
          <InvoiceContainer invoiceData={invoiceData} carData={carData}/>
        </div>
      </div>
    </div>
  );
};

export default InvoiceDetails;

