import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'

const CarDescription = () => {
  const carDetails = useSelector((state: any) => state?.carReducer?.singleCarDetails);

  return (
    <div className="review-sec mb-0">
      <div className="review-header">
        <h4>Description of Car</h4>
      </div>
      <div className="description-list">
        <p>{carDetails?.description}</p>

        {/* Previous Static data */}
        {/* <p>
          Lorem Ipsum is simply dummy text of the printing and
          typesetting industry. Lorem Ipsum has been the
          industry`&apos;`s standard dummy text ever since the
          1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived
          not only five centuries, but also the leap into electronic
          typesetting, remaining essentially unchanged.
        </p>
        <p>
          It was popularised in the 1960s with the release of
          Letraset sheets containing Lorem Ipsum passages, and more
          recently with desktop publishing software like Aldus
          PageMaker including versions of Lorem Ipsum.It was
          popularised in the 1960s with the release of Letraset
          sheets containing Lorem Ipsum passages, and more recently
          with desktop publishing software like Aldus PageMaker
          including versions of Lorem Ipsum.
        </p>
        <p>
          It was popularised in the 1960s with the release of
          Letraset sheets containing Lorem Ipsum passages, and more
          recently with desktop publishing software like Aldus
          PageMaker including versions of Lorem Ipsum.
        </p> */}
        <div className="read-more">
          {/* Previous Static data */}
          {/* <div className="more-text">
            <p>
              It was popularised in the 1960s with the release of
              Letraset sheets containing Lorem Ipsum passages, and
              more recently with desktop publishing software like
              Aldus PageMaker including versions of Lorem Ipsum.It
              was popularised in the 1960s with the release of
              Letraset sheets containing Lorem Ipsum passages, and
              more recently with desktop publishing software like
              Aldus PageMaker including versions of Lorem Ipsum.
            </p>
          </div> */}
          <Link to="#" className="more-link">Show More</Link>
        </div>
      </div>
    </div>
  )
}

export default CarDescription