import React from 'react'
import { useSelector } from 'react-redux';
// import PriceInfo from '../../common/ui_components/PriceInfo'

const DetailsHeader = () => {
  const carDetails = useSelector((state: any) => state?.carReducer?.singleCarDetails);

  return (
    <section className="product-detail-head">
    <div className="header_container">
      <div className="detail-page-head">
        <div className="detail-headings">
          <div className="star-rated">
            <ul className="list-rating">
              <li>
                <div className="car-brand">
                  {carDetails?.carCategory?.name}
                </div>
              </li>
              <li>
                <span className="year">{carDetails?.year}</span>
              </li>
            </ul>
            <div className="camaro-info">
              <h3>{carDetails?.modelName}</h3>
              <div className="camaro-location">
                <div className="camaro-location-inner">
                  <span>Car Registration No : {carDetails?.registration}</span>
                </div>
                <div className="camaro-location-inner">
                  <i className="bx bx-car" />
                  <span>{carDetails?.seatingCapacity} Seater</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <PriceInfo /> */}
      </div>
    </div>
  </section>
  )
}

export default DetailsHeader