import React from 'react'
import DropdownOptions from './OptionDropdown';
import { Dropdown } from "primereact/dropdown";
import ImageWithBasePath from '../../../core/data/img/ImageWithBasePath';
import useDropdownOptions from '../../../services/hooks/DropDownOption';

const SpecializationField = ({ formik }: any) => {

  const {
    // transmissionOptions,
    // fuelOptions,
    // acOptions,
    doorOptions,
    // brakeOptions,
    engineOptions,
    // mileageOptions,
  } = DropdownOptions();

  const { options: makeOptions } = useDropdownOptions('make');
  const { options: transmissionOptions } = useDropdownOptions('transmissionType');
  const { options: fuelOptions } = useDropdownOptions('fuelType');
  const { options: brakeOptions } = useDropdownOptions('brakeType');

  return (
    <>
      <p>Specifications</p>
      <div className="d-flex align-items-center over-mt">
        <div className="border-line"></div>
        <div className={`exc-border`}></div>
      </div>
      <div className="py-3 row row-cols-1 row-cols-md-2">
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center gap-3">
            <ImageWithBasePath src='assets/img/icons/speci3.png' alt="Icon" className="p-3 rounded-2 d-flex justify-content-center align-items-center border border-2" />
            <div className="input-block w-100 mt-2">
              <label className="form-label">Transmission</label>
              <Dropdown
                value={formik.values.transmissionType}
                onChange={(e: any) =>
                  formik.setFieldValue("transmissionType", e.value)
                }
                onBlur={() => formik.setFieldTouched("transmissionType", true)}
                options={transmissionOptions}
                className="w-100"
              />
            </div>
          </div>
          {formik.touched.transmissionType && formik.errors.transmissionType ? (
            <div className="error text-danger font-short">
              {formik.errors.transmissionType}
            </div>
          ) : null}
        </div>
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center gap-3">
            <ImageWithBasePath src='assets/img/icons/speci4.png' alt="Icon" className="p-3 rounded-2 d-flex justify-content-center align-items-center border border-2" />
            <div className="input-block w-100 mt-2">
              <label className="form-label">Fuel Type</label>
              <Dropdown
                value={formik.values.fuelType}
                onChange={(e: any) => formik.setFieldValue("fuelType", e.value)}
                onBlur={() => formik.setFieldTouched("fuelType", true)}
                options={fuelOptions}
                className="w-100"
              />
            </div>
          </div>
          {formik.touched.fuelType && formik.errors.fuelType ? (
            <div className="error text-danger font-short">
              {formik.errors.fuelType}
            </div>
          ) : null}
        </div>
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center gap-3">
            <ImageWithBasePath src='assets/img/icons/speci10.png' alt="Icon" className="p-3 rounded-2 d-flex justify-content-center align-items-center border border-2" />
            <div className="input-block w-100 mt-2">
              <label className="form-label">Door</label>
              <Dropdown
                value={formik.values.door}
                onChange={(e: any) => formik.setFieldValue("door", e.value)}
                onBlur={() => formik.setFieldTouched("door", true)}
                options={doorOptions}
                className="w-100"
              />
            </div>
          </div>
          {formik.touched.door && formik.errors.door ? (
            <div className="error text-danger font-short">
              {formik.errors.door}
            </div>
          ) : null}
        </div>
        {/* <div className="d-flex flex-column">
          <div className="d-flex align-items-center gap-3">
            <ImageWithBasePath src='assets/img/icons/speci8.png' alt="Icon" className="p-3 rounded-2 d-flex justify-content-center align-items-center border border-2" />
            <div className="input-block w-100 mt-2">
              <label className="form-label">AC</label>
              <Dropdown
                value={formik.values.ac}
                onChange={(e: any) => formik.setFieldValue("ac", e.value)}
                onBlur={() => formik.setFieldTouched("ac", true)}
                options={acOptions}
                className="w-100"
              />
            </div>
          </div>
          {formik.touched.ac && formik.errors.ac ? (
            <div className="error text-danger font-short">
              {formik.errors.ac}
            </div>
          ) : null}
        </div> */}
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center gap-3">
            <ImageWithBasePath src='assets/img/icons/speci2.png' alt="Icon" className="p-3 rounded-2 d-flex justify-content-center align-items-center border border-2" />
            <div className="input-block w-100 mt-2">
              <label className="form-label">Make</label>
              <Dropdown
                value={formik.values.makeId}
                onChange={(e: any) => formik.setFieldValue("makeId", e.value)}
                onBlur={() => formik.setFieldTouched("makeId", true)}
                options={makeOptions}
                className="w-100"
              />
            </div>
          </div>
          {formik.touched.makeId && formik.errors.makeId ? (
            <div className="error text-danger font-short">
              {formik.errors.makeId}
            </div>
          ) : null}
        </div>
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center gap-3">
            <ImageWithBasePath src='assets/img/icons/speci11.png' alt="Icon" className="p-3 rounded-2 d-flex justify-content-center align-items-center border border-2" />
            <div className="input-block w-100 mt-2">
              <label className="form-label">Brake</label>
              <Dropdown
                value={formik.values.brake}
                onChange={(e: any) =>
                  formik.setFieldValue("brake", e.value)
                }
                onBlur={() => formik.setFieldTouched("brake", true)}
                options={brakeOptions}
                className="w-100"
              />
            </div>
          </div>
          {formik.touched.brake && formik.errors.brake ? (
            <div className="error text-danger font-short">
              {formik.errors.brake}
            </div>
          ) : null}
        </div>
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center gap-3">
            <ImageWithBasePath src='assets/img/icons/speci12.png' alt="Icon" className="p-3 rounded-2 d-flex justify-content-center align-items-center border border-2" />
            <div className="input-block w-100 mt-2">
              <label className="form-label">Engine (Hp)</label>
              <Dropdown
                value={formik.values.engine}
                onChange={(e: any) =>
                  formik.setFieldValue("engine", e.value)
                }
                onBlur={() => formik.setFieldTouched("engine", true)}
                options={engineOptions}
                className="w-100"
              />
            </div>
          </div>
          {formik.touched.engine && formik.errors.engine ? (
            <div className="error text-danger font-short">
              {formik.errors.engine}
            </div>
          ) : null}
        </div>
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center gap-3">
            <ImageWithBasePath src='assets/img/icons/speci5.png' alt="Icon" className="p-3 rounded-2 d-flex justify-content-center align-items-center border border-2" />
            <div className="input-block w-100 mt-2">
              <label className="form-label">Mileage (Km)</label>
              {/* <Dropdown
                value={formik.values.mileage}
                onChange={(e: any) =>
                  formik.setFieldValue("mileage", e.value)
                }
                onBlur={() => formik.setFieldTouched("mileage", true)}
                options={mileageOptions}
                className="w-100"
              /> */}
              <input
                type="number"
                name="mileage"
                className="form-control"
                value={formik.values.mileage}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
          {formik.touched.mileage && formik.errors.mileage ? (
            <div className="error text-danger font-short">
              {formik.errors.mileage}
            </div>
          ) : null}
        </div>
      </div>
    </>
  )
}

export default SpecializationField