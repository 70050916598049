import React from 'react';
import TransactionItem from './DashboradTransactionItem';



type Props = {
  transactionList: Array<{
    imageSrc: string;
    carName: string;
    carType: string;
    status: string;
    dateTime: string;
    route: string;
  }>;
}

const DashboardTransactionTable = ({ transactionList }: Props) => {

  return (
    <div className="table-responsive dashboard-table dashboard-table-info">
      <table className="table">
        <tbody>
          {transactionList.map((transaction, index) => (
            <React.Fragment key={index}>
              <TransactionItem {...transaction} />
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DashboardTransactionTable;
