// import React from "react";
// import { all_routes } from "../../../router/all_routes";
// import DashboardMenuItem from "../../../common/ui_components/DashboardMenuItem";

// const DashboardMenu = () => {
//   const routes = all_routes;

//   return (
//     <div className="dashboard-section">
//       <div className="dashboard_container">
//         <div className="row">
//           <div className="col-lg-12">
//             <div className="dashboard-menu">
//               <ul>
//                 <DashboardMenuItem
//                   route={routes.userDashboard}
//                   iconSrc="assets/img/icons/dashboard-icon.svg"
//                   label="Dashboard"
//                   isActive={(route) => location.pathname === route}
//                 />
//                 <DashboardMenuItem
//                   route={routes.userBookings}
//                   iconSrc="assets/img/icons/booking-icon.svg"
//                   label="My Bookings"
//                   isActive={(route) => location.pathname === route}
//                 />
//                 <DashboardMenuItem
//                   route={routes.userPayment}
//                   iconSrc="assets/img/icons/payment-icon.svg"
//                   label="Payments"
//                   isActive={(route) => location.pathname === route}
//                 />
//                 <DashboardMenuItem
//                   route={routes.userSettings}
//                   iconSrc="assets/img/icons/settings-icon.svg"
//                   label="Settings"
//                   isActive={(route) => location.pathname === route}
//                   additionalRoutes={[
//                     routes.userSecurity,
//                     routes.preference,
//                     routes.notification,
//                     routes.integration,
//                   ]}
//                 />
//               </ul>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };
// export default DashboardMenu;


import React from "react";
import { useSelector } from "react-redux";
import { all_routes } from "../../../router/all_routes";
import DashboardMenuItem from "../../../common/ui_components/DashboardMenuItem";

type UserType = 'flexiDriver' | 'carOwner' | 'professionalDriver';

// After login - Menu list according user type
const DashboardMenu = () => {
  const routes = all_routes;
  // const userType = useSelector((state: any) => state?.login_data?.role) as UserType;
  const userType = useSelector((state: any) => state?.authReducer?.login_data?.role) as UserType;

  const menuItems: Record<UserType, { route: string; icon: string; label: string; }[]> = {
    flexiDriver: [
      { route: routes.driverBookings, icon: "booking-icon.svg", label: "My Bookings" },
      { route: routes.userSettings, icon: "settings-icon.svg", label: "Settings" }
      // { route: routes.userDashboard, icon: "dashboard-icon.svg", label: "Dashboard" },
      // { route: routes.userPayment, icon: "payment-icon.svg", label: "Payments" },
    ],
    carOwner: [
      { route: routes.userDashboard, icon: "dashboard-icon.svg", label: "Dashboard" },
      { route: routes.userRelocation, icon: "booking-icon.svg", label: "My Relocations" },
      { route: routes.userPayment, icon: "payment-icon.svg", label: "Payments" },
      { route: routes.userSettings, icon: "settings-icon.svg", label: "Settings" }
    ],
    professionalDriver: [
      { route: routes.driverBookings, icon: "booking-icon.svg", label: "My Bookings" },
      { route: routes.userSettings, icon: "settings-icon.svg", label: "Settings" }
    ]
  };

  const userMenuItems = menuItems[userType] || [];

  return (
    <div className="dashboard-section">
      <div className="dashboard_container">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-menu">
              <ul>
                {userMenuItems.map((item, index) => (
                  <DashboardMenuItem
                    key={index}
                    route={item.route}
                    iconSrc={`assets/img/icons/${item.icon}`}
                    label={item.label}
                    isActive={(route) => location.pathname === route}
                    additionalRoutes={item.label === "Settings" ? [
                      routes.userSecurity,
                      routes.preference,
                      routes.notification,
                      routes.integration
                    ] : []}
                  />
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardMenu;
