import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
// import { set_login_data } from "../../core/data/redux/action";
import { set_login_data } from "../../redux/reducers/authReducer";
import { doGetApiCall, doPostApiCall } from "../../utils/apiconfig";
import { setCookie } from "../../utils/cookieUtils";
import { all_routes } from "../../feature-module/router/all_routes";
import { fetchMediaData } from "./MediaData";
import { closeNotification, openNotification } from "../../redux/reducers/notificationReducer";

const useLogin = () => {
  const [loginError, setLoginError] = useState("");
  const [errorState, setErrorState] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routes = all_routes;

  const handleLoginSubmit = async (values: any, { setSubmitting, resetForm }: any) => {
    const loginData = {
      url: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/auth/login`,
      bodyData: {
        email: values.email,
        password: values.password,
      },
    };

    try {
      const response: any = await doPostApiCall(loginData);
      if (!response.error) {
        const notificationObj = {
          open: true,
          message: 'Logged in successfully !!',
          alertType: 'success',
          subText: '',
          borderClass: 'success',
        }
        dispatch(openNotification(notificationObj));
        setTimeout(() => dispatch(closeNotification()), 4000);
        resetForm();
        setCookie("token", response.token, 7);
        setCookie("isLoggedIn", true);
        await fetchUserData();
      } else {
        handleError(`Login failed. ${response?.message}.`);
      }
    } catch (err: any) {
      handleError(err.message || "Something went wrong!");
    } finally {
      setSubmitting(false);
    }
  };

  const fetchUserData = async () => {
    const userDataRequest = {
      url: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/users/details`,
    };

    try {
      const response: any = await doGetApiCall(userDataRequest);
      if (!response.error) {
        handleUserData(response?.result);
      } else {
        handleError("Failed to fetch user data.");
      }
    } catch (err) {
      handleError("Error fetching user data.");
      console.error("Error fetching user data:", err);
    }
  };

  const handleUserData = async (userData: any) => {
    dispatch(set_login_data(userData));
    setCookie("userdata", userData, 7);

    if (userData?.profileUpdated === 0) {
      navigate(routes?.userSettings);
    } else {
      if (userData?.profilePicture) {
        await fetchMediaData(userData?.profilePicture.key);
      }
      navigate(routes.MainLanding);
    }
  };

  const handleError = (message: string) => {
    setLoginError(message);
    setErrorState(true);
  };

  return {
    handleLoginSubmit, loginError, errorState, setErrorState,
  };
};

export default useLogin;
