import React from "react";
import ModalWrapper from "./ModalWrapper"; 

const CustomDateModal = () => {
  return (
    <ModalWrapper id="custom_date" title="Custom Date">
      <form action="#">
        <div className="modal-form-group">
          <label>
            From Date <span className="text-danger">*</span>
          </label>
          <input type="date" className="form-control" />
        </div>
        <div className="modal-form-group">
          <label>
            To Date <span className="text-danger">*</span>
          </label>
          <input type="date" className="form-control" />
        </div>
        <div className="modal-btn modal-btn-sm text-end">
          <a
            href="#"
            data-bs-dismiss="modal"
            className="btn btn-secondary"
          >
            Cancel Booking
          </a>
          <a
            href="#"
            data-bs-dismiss="modal"
            className="btn btn-primary"
          >
            Start Ride
          </a>
        </div>
      </form>
    </ModalWrapper>
  );
};

export default CustomDateModal;
