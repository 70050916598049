import { createSlice } from '@reduxjs/toolkit';
// import { doPostApiCall } from '../../utils/apiconfig';



// <------------------------- Thunk For Resettign Password ------------------------->
// export const signUp = createAsyncThunk('/auth/signup', async (body: any) => {
//     // console.log("body ===>", body);

//     // const requestOptions = {
//     //     method: "POST",
//     //     headers: { "Content-Type": "application/json" },
//     //     body: JSON.stringify(body),
//     // };

//     const response:any = await doPostApiCall(body);
//     const res = await response.json();
//     // console.log("Api Response --------->", res);
//     return res.result;
// });

interface MobileViewState {
    loading: 'idle' | 'pending' | 'succeeded' | 'failed'
    mobileSidebar: boolean
}

const initialState: MobileViewState = {
    loading: 'idle',
    mobileSidebar: false
}

const mobileViewSlice = createSlice({
    name: 'mobileView',
    initialState,
    reducers: {
        set_is_mobile_sidebar(state, action) {
            state.mobileSidebar = action.payload;
        },
    },
    extraReducers: () => {
        // Add reducers for additional action types here, and handle loading state as needed

        // <------ Reducers for signUp ------> 
        // builder.addCase(signUp.pending, (state, action) => {
        //     state.loading = 'pending';
        // });
        // builder.addCase(signUp.fulfilled, (state, action) => {
        //     state.loading = 'succeeded';
        // })
        // builder.addCase(signUp.rejected, (state, action) => {
        //     state.loading = 'failed';
        // })
    },
})

// Action creators are generated for each case reducer function
export const { set_is_mobile_sidebar } = mobileViewSlice.actions;
export default mobileViewSlice.reducer;