import React from 'react';

interface TabsProps {
    tabs: string[];
    onSelect: (tab: string) => void;
    selectedTab: string;
}

const Tabs: React.FC<TabsProps> = ({ tabs, onSelect, selectedTab }) => {
    return (
        <div className="header-section">
            {tabs.map((tab) => (
                <div
                    key={tab}
                    className={`single-section ${selectedTab === tab ? 'active' : ''}`}
                    onClick={() => onSelect(tab)}
                >
                    {tab}
                </div>
            ))}
        </div>
    );
};

export default Tabs;
