import React, { useState } from "react";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { useNavigate } from "react-router-dom";
import { all_routes } from "../../../router/all_routes";
import UserBookingTableHeader from "./UserBookingTableHeader"
import ListActionMenu from "../../../common/ui_components/ListActionMenu";
import BookingAvatar from "../../../common/ui_components/BookingAvatar";
import RelocationStatus from "../../../common/ui_components/UserBookingStatus";
import ListingTable from "../../../common/ui_components/ListingTable";
import { removeCookie } from "../../../../utils/cookieUtils";

const CommonBookingList = ({ tableData, title }: any) => {
  const navigate = useNavigate();

  const routes = all_routes;

  const [searchInput, setSearchInput] = useState("");

  const filteredData = tableData?.filter((rowData: any) => {
    const values = rowData && Object.values(rowData).map((value: any) => value?.toString().toLowerCase());
    return values?.some((value: any) => value?.includes(searchInput.toLowerCase()));
  });

  const navigatePath = () => {
    removeCookie('CarId');
    removeCookie('stepperstep');
    removeCookie('rentAmount');
    navigate(routes.relocateCar);
  }

  const actionButtons = [
    { icon: "feather icon-eye", label: "View", target: "#upcoming_booking" },
    // { icon: "feather icon-edit-2", label: "Edit", target: "#edit_booking" },
    // { icon: "feather icon-trash-2", label: "Delete", target: "#delete_modal" },
  ];

  const locationStyle = {
    whiteSpace: 'nowrap',      /* Prevents the text from wrapping to a new line */
    overflow: 'hidden',         /* Hides the overflowed content */
    textOverflow: 'ellipsis',  /* Adds the ellipsis (...) at the end */
    maxWidth: '200px',         /* Set the max width as per your requirement */
  }

  const columnConfig = [
    // {
    //   field: "",
    //   header: (
    //     <label className="custom_check w-100">
    //       <input type="checkbox" name="username" />
    //       <span className="checkmark" />
    //     </label>
    //   ),
    //   body: () => (
    //     <label className="custom_check w-100">
    //       <input type="checkbox" name="username" />
    //       <span className="checkmark" />
    //     </label>
    //   ),
    // },
    // {
    //   field: "bookingId",
    //   header: "Booking ID",
    //   body: (rowData: any) => (
    //     <Link to="#" data-bs-toggle="modal" data-bs-target="#upcoming_booking" className="bookbyid">
    //       {rowData?.bookingId}
    //     </Link>
    //   ),
    // },
    {
      field: "carName",
      header: "Car Name",
      body: (rowData: any) => (
        <BookingAvatar
          img={rowData?.imgSrc}
          carName={rowData?.carName}
          deliveryStatus={rowData?.carType}
        />
      ),
    },
    {
      field: "deliveryStatus",
      header: "Pickup / Delivery Location",
      body: (rowData: any) => (
        <p style={locationStyle}> {rowData?.pickupLocation}
          <span className="d-block">{rowData?.pickupTime}</span>
        </p>
      ),
    },
    {
      field: "location",
      header: "Dropoff Location",
      body: (rowData: any) => (
        <p style={locationStyle}> {rowData?.dropoffLocation}
          <span className="d-block">{rowData?.dropoffTime}</span>
        </p>
      ),
    },
    // {
    //   field: "bookedOn",
    //   header: "Booked On",
    // },
    {
      field: "total",
      header: "Total",
    },
    {
      field: "paymentStatus",
      header: "Payment Status",
      body: (rowData: any) => <RelocationStatus status={rowData?.paymentStatus} />,
    },
    {
      field: "bookingStatus",
      header: "Booking Status",
      body: (rowData: any) => <RelocationStatus status={rowData?.bookingStatus} />,
    },
    {
      field: "createdAt",
      header: "Created At",
      body: (rowData: any) => <RelocationStatus status={rowData?.createdAt} />,
    },
    // {
    //   field: "updatedAt",
    //   header: "Updated At",
    //   body: (rowData: any) => <RelocationStatus status={rowData?.updatedAt} />,
    // },
    {
      field: "action",
      header: "Action",
      body: (rowData: any) => {
        let actions = [];
        if (rowData?.paymentStatus === 'Pending') actions = [...actionButtons, { icon: "feather icon-edit-2", label: "Edit", target: "#edit_booking" }];
        else actions = actionButtons;
        return <ListActionMenu actions={actions} />
      },
    },
  ];


  return (
    <div className="col-lg-12 d-flex">
      <div className="card book-card flex-fill mb-0">
        <UserBookingTableHeader
          title={title}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          navigatePath={navigatePath}
          itemCount={filteredData?.length}
          show_btn={true}
        />
        <ListingTable filteredData={filteredData} columnConfig={columnConfig} />
      </div>
    </div>
  );
};
export default CommonBookingList;
