import React from "react";
import { Link } from "react-router-dom";

const SecurityItem = ({ title, description, btnLabel, btnClass, modalTarget, additionalBtn }:any) => (
  <div className="col-lg-4 col-md-6 d-flex">
    <div className="security-grid flex-fill">
      <div className="security-heading">
        <h5>{title}</h5>
      </div>
      <div className="security-content">
        <p>{description}</p>
      </div>
      <div className="security-btn">
        <Link
          to="#"
          className={`btn ${btnClass}`}
          data-bs-toggle="modal"
          data-bs-target={modalTarget}
        >
          {btnLabel}
        </Link>
        {additionalBtn && (
          <Link to="#" className="btn btn-remove">
            Remove
          </Link>
        )}
      </div>
    </div>
  </div>
);

export default SecurityItem;
