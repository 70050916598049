import React from "react";
import { BiLogoPlayStore } from "react-icons/bi";
import { SiApple } from "react-icons/si";


const AppAvailableSection = () => {

  return (
    <section className="pricing-section pricing-page pricing-section-bottom">
      <div className="card_container">
        <div className="user-app-group" data-aos="fade-down">
          {/* <div className="app-left">
            <ImageWithBasePath
              src="assets/img/bg/app-left.png"
              className="img-fluid"
              alt="App Available"
            />
          </div>
          <div className="app-right">
            <ImageWithBasePath
              src="assets/img/bg/app-right.png"
              className="img-fluid"
              alt="App Available"
            />
          </div> */}
          <div className="row">
            <div className="col-lg-5">
              <div className="userapp-heading">
                <h2 data-aos="fade-down">
                  FlexyMove User Friendly App Available
                </h2>
                <p data-aos="fade-down">
                  Appropriately monetize one-to-one interfaces rather than
                  cutting-edge Competently disinte rmediate backward.
                </p>
                <div className="download-btn">
                  <div className="app-avilable" data-aos="fade-down">
                    <BiLogoPlayStore style={{ fontSize: '40px' }} />
                    <div>
                      <div>Get it on</div>
                      <div className="btn_lower_text">Google Play</div>
                    </div>
                  </div>
                  <div className="app-avilable" style={{backgroundImage:"#2F2F2F"}} data-aos="fade-down">
                    <SiApple style={{ fontSize: '40px' }} />
                    <div>
                      <div>Get it on</div>
                      <div className="btn_lower_text">App Store</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 d-none d-lg-block app_banner"></div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AppAvailableSection;
