import React, { useEffect, useState } from "react";
import SortingHeader from "./SortingHeader";
import PaymentListSection from "./PaymentListSection";
import DashboardLayout from "../DashboardLayout";
import DeleteModal from "../UserSectionModals/DeleteModal";
import InvoiceModel from "../UserSectionModals/InvoiceModal";
import { useDispatch, useSelector } from "react-redux";
import { fetchTransactions } from "../../../../redux/reducers/transactionReducer";
import { format } from 'date-fns';
import useHelperFuncs from "../../../../utils/hooks/useHelperFuncs";



const UserPayment = ({ title = "Payment", subtitle = "Payment" }: any) => {
  const dispatch = useDispatch();
  const { toTitleCase } = useHelperFuncs();

  const transactionList = useSelector((state: any) => state?.transactionReducer?.transactionList);

  const [listData, setListData] = useState<any[]>([]);
  const [activeStatus, setActiveStatus] = useState("All Payments");

  const getFilteredList = () => {
    switch (activeStatus) {
      case "Pending":
        return listData.filter((rowData) => rowData?.status === "pending");
      case "Success":
        return listData.filter((rowData) => rowData?.status === "success");
      case "Failed":
        return listData.filter((rowData) => rowData?.status === "failed");
      default:
        return listData;
    }
  };
  const filteredListData = getFilteredList();

  useEffect(() => {
    dispatch(fetchTransactions({ endpoint: `transaction/alltransactions`, }));
  }, [])

  useEffect(() => {
    if (transactionList?.length > 0) {
      const formatedListData = transactionList.map((transaction: any) => {
        return {
          bookingID: transaction?.tempId || 'NA',
          imgSrc: transaction?.carId?.images?.[0]?.key ? `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/medias?key=${transaction?.carId?.images?.[0]?.key}&width=${100}` : '/assets/img/cars/car-01.jpg',
          carName: transaction?.carId?.modelName || 'NA',
          carType: transaction?.carId?.carCategory?.name,
          paidOn: format(new Date(transaction?.updatedAt), "dd MMM yyyy, hh:mm a") || "DD MM YYYY, 00:00 AM/PM",
          total: transaction?.amount ? `€${transaction?.amount}` : 'NA',
          mode: toTitleCase(transaction?.paymentMethod) || 'NA',
          status: transaction?.status || 'NA',
          // feature: "Delivery",
        }
      });
      setListData(formatedListData);
    }
  }, [transactionList])


  return (
    <>
      <DashboardLayout title={title} subtitle={subtitle}>
        <div className="content-header">
          <h4>Payments</h4>
        </div>
        <SortingHeader onStatusChange={setActiveStatus} />
        {listData?.length > 0 ? <PaymentListSection activeStatus={activeStatus} tableData={filteredListData} /> : 'No data found'}
      </DashboardLayout>

      <InvoiceModel />
      <DeleteModal id="delete_modal"
        title="Delete Payment History"
        message="Are you sure want to delete?" />
    </>
  );
};

export default UserPayment;

