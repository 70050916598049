import React from "react";

const InvoiceBillingInfo = ({ billTo, invoiceFrom, invoiceDetails }: any) => {
    return (
        <div className="invoice-item-bill">
            <ul>
                <li>
                    <div className="invoice-info">
                        <h6>Billed to</h6>
                        <p>
                            {billTo}
                            <br />
                            {/* {billTo.phoneNo}
                            <br />
                            {billTo.address}
                            <br /> */}
                        </p>
                    </div>
                </li>
                <li>
                    <div className="invoice-info">
                        <h6>Invoice From</h6>
                        <p>
                            {`${invoiceFrom?.first_name} ${invoiceFrom?.last_name}`}
                            <br />
                            {`+${invoiceFrom?.phone?.code} ${invoiceFrom?.phone?.number}`}
                            <br />
                            {invoiceFrom?.address?.street}
                            <br />
                            {/* {invoiceFrom?.address?.city}
                            <br /> */}
                            {invoiceFrom?.address?.country}
                            <br />
                        </p>
                    </div>
                </li>
                <li>
                    <div className="invoice-info">
                        <p>Pickup Date : <span>{invoiceDetails?.pickupDate}</span></p>
                        <p>Dropff Date : <span>{invoiceDetails?.dropoffDate}</span></p>
                    </div>
                </li>
            </ul>
        </div>
    );
};

export default InvoiceBillingInfo;
