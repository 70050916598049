// import React, { useEffect } from "react";
// import AOS from "aos";
// import "aos/dist/aos.css";
// import BannerSection from "./BannerSection";
// import SevicesOfferedSection from "./SevicesOfferedSection";
// import InstantPricingSection from "./InstantPricingSection";


// const MainLanding = () => {

//   useEffect(() => {
//     AOS.init({ duration: 1200, once: true });
//   }, []);

//   return (
//     <>
//       <BannerSection />
//       <SevicesOfferedSection />
//       <InstantPricingSection />
//     </>
//   );
// };

// export default MainLanding;


import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import BannerSection from "./BannerSection";
import SevicesOfferedSection from "./SevicesOfferedSection";
import InstantPricingSection from "./InstantPricingSection";
import { useSelector } from "react-redux";
import BookingBannerSection from "../LandingPageForBoking/BookingBannerSection";
import DriverBannerSection from "../LandingPageForDriver/DriverLandingBanner";
import RelocationBannerSection from "../LandingPageForRelocation/BannerForRelocation";

const CombinedLandingPage = () => {

  // const login_data = useSelector((state: any) => state?.login_data);
  const login_data = useSelector((state: any) => state?.authReducer?.login_data);
  // console.log("login_data====>**",login_data)

  const userRole = login_data?.role;
  useEffect(() => {
    AOS.init({ duration: 1200, once: true });
  }, []);

  const renderLandingPage = () => {
    switch (userRole) {
      case "professionalDriver":
        return <DriverBannerSection />;
      case "flexiDriver":
        return <BookingBannerSection />;
      case "carOwner":
        return <RelocationBannerSection />;
      default:
        return (
          <>
            <BannerSection />
            <SevicesOfferedSection />
            <InstantPricingSection />
          </>
        );
    }
  };

  return <>{renderLandingPage()}</>;
};

export default CombinedLandingPage;
