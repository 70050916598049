import React, { useEffect } from "react";
import { LuCopyPlus } from "react-icons/lu";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from 'react-redux';
import { doGetApiCall, doPostApiCall, doPutApiCall } from "../../../utils/apiconfig";
import { closeNotification, openNotification } from "../../../redux/reducers/notificationReducer";
import LocationDateTime from "./LocationDateTime";
import PickUpLocation from "./PickUpLocation";
import DropOffLocation from "./DropOffLocation";
import { getCookie, removeCookie, setCookie } from "../../../utils/cookieUtils";

interface FormValues {
  pickupDate: Date | null;
  pickupTime: string | null;
  pickupTimeEnd: string | null;
  dropoffDate: Date | null;
  dropoffTime: string | null;
  dropoffTimeEnd: string | null;
  pickupStreetAddress: string;
  pickupCountry: string;
  pickupCity: string;
  pickupPincode: string;
  dropoffStreetAddress: string;
  dropoffCountry: string;
  dropoffCity: string;
  dropoffPincode: string;
  pickupLat: string;
  pickupLng: string;
  dropoffLat: string;
  dropoffLng: string;
}

const validationSchema = Yup.object({
  pickupDate: Yup.date().nullable().required("Pickup Date is required"),
  pickupTime: Yup.string().required("Pickup Time is required"),
  // pickupTimeEnd: Yup.string().required("Pickup Time End is required"),
  pickupTimeEnd: Yup.string()
      .required("Pickup Time End is required")
      .test("is-valid-time", "Pickup end time should be greater than start time", function (value) {
        const { pickupTime } = this.parent;
        if (pickupTime && value) {
          const pickupStartTime = new Date(`1970-01-01T${pickupTime}`);
          const pickupEndTime = new Date(`1970-01-01T${value}`);
          return pickupEndTime > pickupStartTime;
        }
        return true;
      }),
  dropoffDate: Yup.string().nullable().required("Dropoff date is required")
    .test("is-greater", "Dropoff date can not less than pickup date", function (value) {
      const { pickupDate } = this.parent;
      return !pickupDate || !value || new Date(value) >= new Date(pickupDate);
    }),
  dropoffTime: Yup.string().required("Dropoff Time is required"),
  // dropoffTimeEnd: Yup.string().required("Dropoff Time End is required"),

  dropoffTimeEnd: Yup.string()
      .required("Dropoff end time is required")
      .test("is-valid-time", "Dropoff end time should be greater than start time", function (value) {
        const { dropoffTime } = this.parent;
        if (dropoffTime && value) {
          const dropoffStartTime = new Date(`1970-01-01T${dropoffTime}`);
          const dropoffEndTime = new Date(`1970-01-01T${value}`);
          return dropoffEndTime > dropoffStartTime;
        }
        return true;
      }),

  pickupStreetAddress: Yup.string().required("Pickup Street Address is required"),
  pickupCountry: Yup.string().required("Pickup Country is required"),
  pickupCity: Yup.string().required("Pickup City is required"),
  pickupPincode: Yup.string().required("Pickup Pincode is required"),
  dropoffStreetAddress: Yup.string().required("Dropoff Street Address is required"),
  dropoffCountry: Yup.string().required("Dropoff Country is required"),
  dropoffCity: Yup.string().required("Dropoff City is required"),
  dropoffPincode: Yup.string().required("Dropoff Pincode is required"),
});

const LocationForm = ({ setStepperState }: any) => {
  const dispatch = useDispatch();
  const CarId = getCookie('CarId');

  const handleSubmit = async (values: FormValues, { setSubmitting }: any) => {
    removeCookie('locationData');
    
    const formattedData: any = {
      pickupLocation: {
        street: values.pickupStreetAddress,
        city: values.pickupCity,
        country: values.pickupCountry,
        pincode: values.pickupPincode,
        coordinates: [values.pickupLng, values.pickupLat],
      },
      dropoffLocation: {
        street: values.dropoffStreetAddress,
        city: values.dropoffCity,
        country: values.dropoffCountry,
        pincode: values.dropoffPincode,
        coordinates: [values.dropoffLng, values.dropoffLat],
      },
      pickupDate: values.pickupDate?.toISOString().split('T')[0] || null,
      pickupTime: { from: values.pickupTime, to: values.pickupTimeEnd },
      dropoffDate: values.dropoffDate?.toISOString().split('T')[0] || null,
      dropoffTime: { from: values.dropoffTime, to: values.dropoffTimeEnd },
      ...(!CarId && { stepCompleted: 1, }), 
      ...(CarId && { carId: CarId }), 
    };

    const apiUrl = `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/cars${CarId ? `/${CarId}` : ''}`;
    const apiCall = CarId ? doPutApiCall : doPostApiCall;

    try {
      const res: any = await apiCall({ url: apiUrl, bodyData: formattedData });
      const notificationObj = {
        open: true,
        message: res.message,
        alertType: res.error ? 'warning' : 'success',
        subText: '',
        borderClass: res.error ? 'warning' : 'success',
      };
      dispatch(openNotification(notificationObj));
      setTimeout(() => dispatch(closeNotification()), 4000);

      if (!res.error && !CarId) setCookie('CarId', res.result._id);
      if (!res.error){
        setStepperState((prev: any) => prev + 1);
        setCookie("stepperstep",1)
      } 
    } catch (err) {
      console.error(err);
    } finally {
      setSubmitting(false);
    }
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      pickupDate: null,
      pickupTime: null,
      pickupTimeEnd: null,
      dropoffDate: null,
      dropoffTime: null,
      dropoffTimeEnd: null,
      pickupStreetAddress: "",
      pickupCountry: "",
      pickupCity: "",
      pickupPincode: "",
      dropoffStreetAddress: "",
      dropoffCountry: "",
      dropoffCity: "",
      dropoffPincode: "",
      pickupLat: "",
      pickupLng: "",
      dropoffLat: "",
      dropoffLng: "",
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    const locationData = getCookie('locationData');
    if (locationData && !CarId) {
      formik.setValues({
        ...locationData,
        pickupDate: locationData.pickupDate ? new Date(locationData.pickupDate) : null,
        dropoffDate: locationData.dropoffDate ? new Date(locationData.dropoffDate) : null,
      });
    } else if (CarId) {
      fetchLocationData(CarId);
    }
  }, []);

  const fetchLocationData = async (id: string) => {
    try {
      const { result }:any = await doGetApiCall({ url: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/cars/${id}` });
      formik.setValues({
        pickupDate: result?.pickupDate ? new Date(result.pickupDate) : null,
        pickupTime: result?.pickupTime?.from || null,
        pickupTimeEnd: result?.pickupTime?.to || null,
        dropoffDate: result?.dropoffDate ? new Date(result.dropoffDate) : null,
        dropoffTime: result?.dropoffTime?.from || null,
        dropoffTimeEnd: result?.dropoffTime?.to || null,
        pickupStreetAddress: result?.pickupLocation?.street || "",
        pickupCountry: result?.pickupLocation?.country || "",
        pickupCity: result?.pickupLocation?.city || "",
        pickupPincode: result?.pickupLocation?.pincode || "",
        dropoffStreetAddress: result?.dropoffLocation?.street || "",
        dropoffCountry: result?.dropoffLocation?.country || "",
        dropoffCity: result?.dropoffLocation?.city || "",
        dropoffPincode: result?.dropoffLocation?.pincode || "",
        pickupLat: result?.pickupLocation?.coordinates[1] || "",
        pickupLng: result?.pickupLocation?.coordinates[0] || "",
        dropoffLat: result?.dropoffLocation?.coordinates[1] || "",
        dropoffLng: result?.dropoffLocation?.coordinates[0] || "",
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit} className="main-form-container">
      <div className="form-details shadow-lg p-4">
        <div className="d-flex align-items-center gap-3 border-bottom pb-3">
          <LuCopyPlus />
          <p className="fw-bold fs-4">Enter Location and Date</p>
        </div>
        <LocationDateTime formik={formik} />
        <PickUpLocation formik={formik} />
        <DropOffLocation formik={formik} />
      </div>
      <div className="d-flex justify-content-center justify-content-md-end w-100 gap-3">
        <button type="submit" className="bg-black text-white py-2 px-3 rounded-2">
          Save & Next
        </button>
      </div>
    </form>
  );
};

export default LocationForm;


// import React, { useEffect } from "react";
// import { LuCopyPlus } from "react-icons/lu";
// import { useFormik } from "formik";
// import * as Yup from "yup";
// import { doGetApiCall, doPostApiCall, doPutApiCall } from "../../../utils/apiconfig";
// import { useDispatch } from 'react-redux';
// import { closeNotification, openNotification } from "../../../redux/reducers/notificationReducer";
// import LocationDateTime from "./LocationDateTime";
// import PickUpLocation from "./PickUpLocation";
// import DropOffLocation from "./DropOffLocation";
// import { getCookie, removeCookie, setCookie } from "../../../utils/cookieUtils";

// interface FormValues {
//   pickupDate: Date | null;
//   pickupTime: string | null;
//   pickupTimeEnd: string | null;
//   dropoffDate: Date | null;
//   dropoffTime: string | null;
//   dropoffTimeEnd: string | null;
//   pickupStreetAddress: string;
//   pickupCountry: string;
//   pickupCity: string;
//   pickupPincode: string;
//   dropoffStreetAddress: string;
//   dropoffCountry: string;
//   dropoffCity: string;
//   dropoffPincode: string;
//   pickupLat: string;
//   pickupLng: string;
//   dropoffLat: string;
//   dropoffLng: string;
// }

// const validationSchema = Yup.object().shape({
//   pickupDate: Yup.date().nullable().required("Pickup Date is required"),
//   pickupTime: Yup.string().required("Pickup Time is required"),
//   pickupTimeEnd: Yup.string().required("Pickup Time End is required"),
//   dropoffDate: Yup.date().nullable().required("Dropoff Date is required"),
//   dropoffTime: Yup.string().required("Dropoff Time is required"),
//   dropoffTimeEnd: Yup.string().required("Dropoff Time End is required"),
//   pickupStreetAddress: Yup.string().required("Pickup Street Address is required"),
//   pickupCountry: Yup.string().required("Pickup Country is required"),
//   pickupCity: Yup.string().required("Pickup City is required"),
//   pickupPincode: Yup.string().required("Pickup Pincode is required"),
//   dropoffStreetAddress: Yup.string().required("Dropoff Street Address is required"),
//   dropoffCountry: Yup.string().required("Dropoff Country is required"),
//   dropoffCity: Yup.string().required("Dropoff City is required"),
//   dropoffPincode: Yup.string().required("Dropoff Pincode is required"),
// });

// const LocationForm = ({ setStepperState }: any) => {
//   const dispatch = useDispatch();
//   const CarId = getCookie('CarId');

//   const handleSubmit = async (values: FormValues, { setSubmitting }: any) => {
//     removeCookie('locationData');
//     console.log("CarId====>",CarId)
    
//     // Common formatted data
//     let formattedData = {
//       pickupLocation: {
//         street: values.pickupStreetAddress,
//         city: values.pickupCity,
//         country: values.pickupCountry,
//         pincode: values.pickupPincode,
//         coordinates: [values.pickupLng, values.pickupLat],
//       },
//       dropoffLocation: {
//         street: values.dropoffStreetAddress,
//         city: values.dropoffCity,
//         country: values.dropoffCountry,
//         pincode: values.dropoffPincode,
//         coordinates: [values.dropoffLng, values.dropoffLat],
//       },
//       pickupDate: values.pickupDate ? values.pickupDate.toISOString().split('T')[0] : null,
//       pickupTime: {
//         from: values.pickupTime,
//         to: values.pickupTimeEnd,
//       },
//       dropoffDate: values.dropoffDate ? values.dropoffDate.toISOString().split('T')[0] : null,
//       dropoffTime: {
//         from: values.dropoffTime,
//         to: values.dropoffTimeEnd,
//       },
//       stepCompleted: 1,
//     };
  
//     // If CarId exists, add it to the formattedData for PUT request
//     if (CarId !== null) {
//       formattedData = {
//         ...formattedData,
//         carId: CarId, // Add CarId for PUT requests
//       } as typeof formattedData & { carId: string }; // Type assertion
//     }
  
//     // Determine if it's a POST or PUT request
//     const apiUrl = CarId !== null 
//       ? `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/cars/${CarId}` // PUT request URL
//       : `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/cars`; // POST request URL
  
//     const data = {
//       url: apiUrl,
//       bodyData: formattedData,
//     };

//     const apiCall = CarId !== null ? doPutApiCall : doPostApiCall;
  
//     await apiCall(data)
//       .then((res: any) => {
//         if (!res.error) {
//           const notificationObj = { 
//             open: true, 
//             message: res.message, 
//             alertType: 'success', 
//             subText: '', 
//             borderClass: 'success' 
//           };
//           dispatch(openNotification(notificationObj));
//           setTimeout(() => dispatch(closeNotification()), 4000);
  
//           // Set CarId only after a successful POST request
//           if (CarId === null) {
//             setCookie('CarId', res.result._id);
//           }
  
//           setStepperState((prev: any) => prev + 1);
//         } else {
//           const notificationObj = { 
//             open: true, 
//             message: res.message, 
//             alertType: 'warning', 
//             subText: '', 
//             borderClass: 'warning' 
//           };
//           dispatch(openNotification(notificationObj));
//           setTimeout(() => dispatch(closeNotification()), 4000);
//         }
//       })
//       .catch((err) => {
//         console.error(err, '<<-- err');
//       })
//       .finally(() => {
//         setSubmitting(false);
//       });
//   };
  
  

//   const formik = useFormik<FormValues>({
//     initialValues: {
//       pickupDate: null,
//       pickupTime: null,
//       pickupTimeEnd: null,
//       dropoffDate: null,
//       dropoffTime: null,
//       dropoffTimeEnd: null,
//       pickupStreetAddress: "",
//       pickupCountry: "",
//       pickupCity: "",
//       pickupPincode: "",
//       dropoffStreetAddress: "",
//       dropoffCountry: "",
//       dropoffCity: "",
//       dropoffPincode: "",
//       pickupLat: "",
//       pickupLng: "",
//       dropoffLat: "",
//       dropoffLng: ""
//     },
//     validationSchema,
//     onSubmit: handleSubmit
//   });

//   useEffect(() => {
//     const locationData = getCookie('locationData')
//     console.log(locationData)
//     if (locationData && !CarId) {
//       formik.setValues({
//         pickupDate: locationData.pickupDate ? new Date(locationData.pickupDate) : null,
//         pickupTime: locationData.pickupTime || null,
//         pickupTimeEnd: locationData.pickupTimeEnd || null,
//         dropoffDate: locationData.dropoffDate ? new Date(locationData.dropoffDate) : null,
//         dropoffTime: locationData.dropoffTime || null,
//         dropoffTimeEnd: locationData.dropoffTimeEnd || null,
//         pickupStreetAddress: locationData.pickupStreetAddress || "",
//         pickupCountry: locationData.pickupCountry || "",
//         pickupCity: locationData.pickupCity || "",
//         pickupPincode: locationData.pickupPincode || "",
//         dropoffStreetAddress: locationData.dropoffStreetAddress || "",
//         dropoffCountry: locationData.dropoffCountry || "",
//         dropoffCity: locationData.dropoffCity || "",
//         dropoffPincode: locationData.dropoffPincode || "",
//         pickupLat: locationData.pickupLat || "",
//         pickupLng: locationData.pickupLng || "",
//         dropoffLat: locationData.dropoffLat || "",
//         dropoffLng: locationData.dropoffLng || "",
//       });
//     }else if(CarId){
//       console.log("CarId====>lf",CarId)
//       getLocationData(CarId)
//     }
//   }, []);

//   const getLocationData = async (id: any) => {
//     const data = {
//       url: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/cars/${id}`,
//     };
//     await doGetApiCall(data)
//       .then((res: any) => {
//         if (!res.error) {
//           console.log(res)
//           formik.setValues({
//             pickupDate: res?.result?.pickupDate ? new Date(res?.result?.pickupDate) : null,
//             pickupTime: res?.result?.pickupTime?.to || null,
//             pickupTimeEnd: res?.result?.pickupTime?.from || null,
//             dropoffDate: res?.result?.dropoffDate ? new Date(res?.result?.dropoffDate) : null,
//             dropoffTime: res?.result?.dropoffTime?.to || null,
//             dropoffTimeEnd: res?.result?.dropoffTime?.from || null,
//             pickupStreetAddress: res?.result?.pickupLocation?.street|| "",
//             pickupCountry: res?.result?.pickupLocation?.country || "",
//             pickupCity: res?.result?.pickupLocation?.city || "",
//             pickupPincode: res?.result?.pickupLocation?.pincode || "",
//             dropoffStreetAddress: res?.result?.dropoffLocation?.street || "",
//             dropoffCountry: res?.result?.dropoffLocation?.country || "",
//             dropoffCity: res?.result?.dropoffLocation?.city || "",
//             dropoffPincode: res?.result?.dropoffLocation?.pincode || "",
//             pickupLat: res?.result?.pickupLocation?.coordinates[1] || "",
//             pickupLng: res?.result?.pickupLocation?.coordinates[0] || "",
//             dropoffLat: res?.result?.dropoffLocation?.coordinates[1] || "",
//             dropoffLng: res?.result?.dropoffLocation?.coordinates[0] || "",
//           });
//         }
//       })
//       .catch((err) => {
//         console.error(err);
//       })
//   };

//   return (
//     <form onSubmit={formik.handleSubmit} className="main-form-container">
//       <div className="form-details shadow-lg p-4">
//         <div className="d-flex align-items-center gap-3 border-bottom pb-3">
//           <LuCopyPlus />
//           <p className={`fw-bold fs-4`}>Enter Location and Date</p>
//         </div>
//         <LocationDateTime formik={formik} />
//         <PickUpLocation formik={formik} />
//         <DropOffLocation formik={formik} />
//       </div>
//       <div className="d-flex justify-content-center justify-content-md-end align-items-center w-100 gap-3">
//         <button
//           type={`submit`}
//           className="bg-black text-white py-2 px-3 d-flex justify-content-center align-items-center rounded-2"
//         >
//           Save & Next
//         </button>
//       </div>
//     </form>
//   );
// };

// export default LocationForm;
