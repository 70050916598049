import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { all_routes } from "../router/all_routes";
import AuthenticationHeader from "../common/ui_components/AuthenticationComponents/AuthenticationHeader";
import AuthenticationFooter from "../common/ui_components/AuthenticationComponents/AuthenticationFooter";
import WrapperModal from "../common/ui_components/AuthenticationComponents/WrapperModal";
import { forgotPassword, resetAuthReducerState } from "../../redux/reducers/authReducer";
import { closeNotification, openNotification } from "../../redux/reducers/notificationReducer";



const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loading = useSelector((state: any) => state?.authReducer?.loading);
  const { code, message, error } = useSelector((state: any) => state?.authReducer?.forgotPassRes);

  const routes = all_routes;
  const [email, setEmail] = useState("");

  const handleSubmit = (e: any) => {
    e.preventDefault();
    dispatch(forgotPassword({ url: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/auth/forgot/password`, bodyData: { email } }));
  }

  useEffect(() => {
    switch (loading) {
      case 'pending':
        break;

      case 'succeeded':
        dispatch(openNotification({ open: true, message: `${message} !!`, alertType: error ? 'error' : 'success', subText: '', borderClass: error ? 'error' : 'success' }));
        setTimeout(() => dispatch(closeNotification()), 4000);
        dispatch(resetAuthReducerState());
        if (code === 'OTP_SENT') navigate(routes.OtpVerification, { state: { emailFromForgotPass: email } });
        break;

      case 'failed':
        break;

      default:
        break;
    }

  }, [loading])


  return (
    <>
      <div className="main-wrapper login-body">
        <AuthenticationHeader />
        <div className="login-wrapper">
          <WrapperModal title="Forget Password" subtitle="Enter your email and we will send you a link to reset your password.">
            <>
              <form onSubmit={handleSubmit}>
                <div className="input-block">
                  <label className="form-label">
                    Email Address <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    placeholder="Enter email"
                    type="email"
                    value={email}
                    onInput={(e: any) => setEmail(e.target.value)}
                  />
                </div>
                <button type="submit" className="btn btn-primary w-100 btn-size font-semibold">Save Changes</button>
              </form>
            </>
          </WrapperModal>
        </div>
        <AuthenticationFooter />
      </div>
    </>
  );
};

export default ForgotPassword;
