import React from 'react'
import ImageWithBasePath from '../../../core/data/img/ImageWithBasePath'
import Button from '../../common/ui_components/buttons/blueBtn'
import { all_routes } from '../../router/all_routes'

const ContactUsBanner = () => {
  return (
    <section className="contact-us-banner-section">
      <div className="row" data-aos="fade-down">
        <div className="col-lg-7 d-none d-lg-block contact_banner">
          <ImageWithBasePath
            src="assets/img/cars/enterprise.png"
            className="img-fluid"
            alt="App Available"
          />
        </div>
        <div className="col-lg-4 contact-right-section">
          <div className='contact_heading'>Ready <br />to Optimize <br /> Your Fleet</div>
          <div>Appropriately monetize one-to-one interfaces rather than cutting-edge Competently disinte rmediate backward.</div>
          <div className='btn_container'>
            <Button label="Get a Quote"
              className="btn-view d-inline-flex align-items-center"
              to={all_routes.Relocation}
            />
            <Button label="Contact Us"
              className="btn-view d-inline-flex align-items-center contact-us-button"
              to={all_routes.contactUs}
            />
          </div>

        </div>
      </div>
    </section>
  )
}

export default ContactUsBanner