import React from 'react';
import CheckboxItem from './CheckBox';

const CheckBoxFilter = ({ items, onChange }: { items: any[], onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void }) => (
  <>
    {items.map((item, itemIndex) => (
      <CheckboxItem
        key={itemIndex}
        label={item.label}
        count={item.count}
        checked={item.checked}
        onChange={onChange}
      />
    ))}
  </>
);

export default CheckBoxFilter;
