import React, { useEffect, useState } from 'react';
import BookingAvatar from './BookingAvatar';
import useHelperFuncs from '../../../utils/hooks/useHelperFuncs';
import { format } from 'date-fns';



type Props = {
  imageSrc: string;
  carName: string;
  carType: string;
  status: string;
  dateTime: string;
  route: string;
}

const TransactionItem = ({ carName, carType, imageSrc, status, dateTime, route }: Props) => {
  const { toTitleCase, getStatusClass } = useHelperFuncs();

  const [pStatusCalss, setPStatusCalss] = useState('');

  useEffect(() => {
    setPStatusCalss(getStatusClass(status));
  }, [status])

  return (
    <>
      <tr>
        <td className="border-0">
          <BookingAvatar
            link={route}
            device='md'
            img={imageSrc}
            carName={carName}
            deliveryStatus={`Car Type : ${carType}`}
          />
        </td>
        <td className="border-0 text-end">
          <span className={`badge ${pStatusCalss}`}>{toTitleCase(status)}</span>
        </td>
      </tr>

      <tr>
        <td colSpan={2} className="pt-0">
          <div className="status-box">
            <p><span>Date : </span>{format(new Date(dateTime), "dd MMM yyyy, hh:mm a")}</p>
          </div>
        </td>
      </tr>
    </>
  );
};

export default TransactionItem;
