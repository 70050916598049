import React, { useEffect } from "react";
import Aos from "aos";
import Breadcrumbs from "../../common/ui_components/breadcrumbs";
import DashboardMenu from "./UserDashboard/DashboardMenu";
// import { useSelector } from "react-redux";



// This is the layout for - how it works route - according user type
const DashboardLayout = ({ title, subtitle, children }: any) => {
  // const loginData = useSelector((state: any) => state?.authReducer?.login_data);

  useEffect(() => {
    Aos.init({ duration: 1200, once: true });
  }, []);

  return (
    <>
      <Breadcrumbs title={title} subtitle={subtitle} />

      {/* After login - Menu list according user type */}
      <DashboardMenu />

      {/* This is the main page content, which we getting through children prop */}
      <div className="content">
        <div className="dashboard_container">
          {children}
        </div>
      </div>
    </>
  );
};

export default DashboardLayout;
