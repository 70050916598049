import React from "react";

type Props = {
  label?:string;
  onClick?: () => void;
  iconClass?:string;
};

const IconButton = ({ label, onClick, iconClass }: Props) => {

  return (
    <div className="input-block">
      <div className="search-btn">
        <button className="btn search-button" type="button" onClick={onClick}>
          <i className={iconClass} aria-hidden="true" />
          {label}
        </button>
      </div>
    </div>
  );
};

export default IconButton;
