import React, { useEffect, useState } from "react";
import ListHeader from "./ListHeader";
import DashboardTransactionTable from "../../../common/ui_components/DashboardTransactionTable";
import { useSelector } from "react-redux";
import { all_routes } from "../../../router/all_routes";



interface Transaction {
  imageSrc: string;
  carName: string;
  carType: string;
  status: string;
  dateTime: string;
  route: string;
}

const UserRecentTransection = ({ options, tranFilterQuery, setTranFilterQuery }: any) => {
  const transactionList = useSelector((state: any) => state?.transactionReducer?.transactionList);

  const [transactionListData, setTransactionListData] = useState<Transaction[]>([]);
  const [title, setTitle] = useState<string>('Recent Transactions');

  // Only for formating lsit data
  useEffect(() => {
    if (transactionList?.length > 0) {
      const formatedCarList = transactionList.map((transaction: any) => {
        return {
          imageSrc: transaction?.carId?.images?.[0]?.key ? `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/medias?key=${transaction?.carId?.images?.[0]?.key}&width=${100}` : '/assets/img/cars/car-01.jpg', //'assets/img/cars/d1.png'
          carName: transaction?.carId?.modelName || 'Model Name',
          carType: transaction?.carId?.carCategory?.name || 'Car Type',
          status: transaction?.status || 'Status',
          dateTime: transaction?.updatedAt || "On 00 MM YYYY, 00:00 AM/PM",
          route: all_routes?.userPayment,
        }
      });
      setTransactionListData(formatedCarList);
    }
  }, [transactionList])

  useEffect(() => {
    // console.log('tranFilterQuery ------>', tranFilterQuery);
    if (tranFilterQuery.includes('time=7')) setTitle('Last 7 days Transaction');
    if (tranFilterQuery.includes('time=15')) setTitle('Last 15 days Transaction');
    if (tranFilterQuery.includes('time=30')) setTitle('Last 30 days Transaction');
    if (tranFilterQuery.includes('time=lastMonth')) setTitle('Last Month Transactions');
  }, [tranFilterQuery])

  return (
    <div className="col-lg-4 d-flex">
      <div className="card user-card flex-fill">
        <ListHeader options={options} title={title} setFilterQuery={setTranFilterQuery} />
        <div className="card-body p-0">
          {transactionListData?.length > 0 ? <DashboardTransactionTable transactionList={transactionListData} /> : 'No data found'}
        </div>
      </div>
    </div>
  );
};

export default UserRecentTransection;