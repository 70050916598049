import React from 'react'
import { Link } from 'react-router-dom'
import ImageWithBasePath from '../../core/data/img/ImageWithBasePath'

const CanceledBooking = () => {
  return (
    <div
      className="modal new-modal multi-step fade"
      id="cancelled_booking"
      data-keyboard="false"
      data-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close-btn"
              data-bs-dismiss="modal"
            >
              <span>×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="booking-header">
              <div className="booking-img-wrap">
                <div className="book-img">
                  <ImageWithBasePath
                    src="assets/img/cars/car-05.jpg"
                    alt="img"
                  />
                </div>
                <div className="book-info">
                  <h6>Chevrolet Camaro</h6>
                  <p>
                    <i className="feather icon-map-pin" /> Location : Miami
                    St, Destin, FL 32550, USA
                  </p>
                </div>
              </div>
              <div className="book-amount">
                <p>Total Amount</p>
                <h6>
                  $4700{" "}
                  <Link to="#">
                    <i className="feather icon-alert-circle" />
                  </Link>
                </h6>
              </div>
            </div>
            <div className="booking-group">
              <div className="booking-wrapper">
                <div className="booking-title">
                  <h6>Booking Details</h6>
                </div>
                <div className="row">
                  <div className="col-lg-4 col-md-6">
                    <div className="booking-view">
                      <h6>Booking Type</h6>
                      <p>Delivery</p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="booking-view">
                      <h6>Rental Type</h6>
                      <p>Days (3 Days)</p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="booking-view">
                      <h6>Extra Service</h6>
                      <p>Mobile Charging</p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="booking-view">
                      <h6>Delivery</h6>
                      <p>45, Avenue ,Mark Street, USA</p>
                      <p>11 Jan 2023, 03:30 PM</p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="booking-view">
                      <h6>Dropoff</h6>
                      <p>78, 10th street Laplace,USA</p>
                      <p>11 Jan 2023, 03:30 PM</p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="booking-view">
                      <h6>Status</h6>
                      <span className="badge badge-light-danger">
                        Refund Initiated
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="booking-view">
                      <h6>Booked On</h6>
                      <p>15 Sep 2023, 09:30 AM</p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="booking-view">
                      <h6>Start Date</h6>
                      <p>18 Sep 2023, 09:30 AM</p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="booking-view">
                      <h6>End Date</h6>
                      <p>20 Sep 2023, 09:30 AM</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="booking-wrapper">
                <div className="booking-title">
                  <h6>Personal Details</h6>
                </div>
                <div className="row">
                  <div className="col-lg-4 col-md-6">
                    <div className="booking-view">
                      <h6>Details</h6>
                      <p>Johna Melinda</p>
                      <p>+1 56441 56464</p>
                      <p>Johna@example.com</p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="booking-view">
                      <h6>Address</h6>
                      <p>78, 10th street</p>
                      <p>Laplace,USA</p>
                      <p>316 654</p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="booking-view">
                      <h6>No of Person’s</h6>
                      <p>2 Adults, 1 Child</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cancel-reason">
                <h6>Cancel Reason</h6>
                <p>
                  On the booking date i have other work on my personal so i am
                  cancelling my bookingOn the booking date i have other work
                  on my personal so i am cancelling my booking On the booking
                  date i have other work on my personal so i am cancelling my
                  booking
                </p>
              </div>
              <div className="cancel-box">
                <p>
                  Cancelled By User on 17 Sep 2023, Refund will deposited in
                  user account on 19 Sep 2023{" "}
                </p>
              </div>
            </div>
            <div className="modal-btn modal-btn-sm text-end">
              <Link
                to="#"
                data-bs-target="#view_status"
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
                className="btn btn-primary"
              >
                View status
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CanceledBooking