import React from 'react';
import { Dropdown } from 'primereact/dropdown';

const DropdownFilter = ({ value, onChange, options, placeholder, className }: any) => (
  <Dropdown
    value={value}
    onChange={(e) => onChange(e.value)}
    options={options}
    optionLabel="name"
    placeholder={placeholder}
    className={className}
  />
);

export default DropdownFilter;

