import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ProfileBasicSection from './ProfileBasicSection';
import ProfileAddressSection from './ProfileAddressSection';
import Button from '../../../common/ui_components/buttons/blueBtn';
import { doPutApiCall } from '../../../../utils/apiconfig';
import { useSelector, useDispatch } from 'react-redux';
import { } from "react-redux";
import { set_login_data } from '../../../../redux/reducers/authReducer';
import { setCookie } from '../../../../utils/cookieUtils';
import ConfirmationModal from '../UserSectionModals/ConfirmationModal';
import SuccessMessage from '../UserSectionModals/SuccessMessage';
import { fetchMediaData } from '../../../../services/auth/MediaData';
import { all_routes } from '../../../router/all_routes';
import { useNavigate } from 'react-router-dom';
import { closeNotification, openNotification } from '../../../../redux/reducers/notificationReducer';
import { clearUserDetails, getUserDetails } from '../../../../redux/reducers/userReducer';



const ProfileSection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routes = all_routes;

  const login_data = useSelector((state: any) => state?.authReducer?.login_data);
  const userDetails = useSelector((state: any) => state?.userReducer?.userDetails);

  const [phoneData, setPhoneData] = useState({ code: '', number: '' });
  const [profileImage, setProfileImage] = useState<string>('/assets/img/bg/blank_profile.jpg');
  const [verificationModal, setVerificaton] = useState(false);
  const [successMessage, setsuccessMessage] = useState(false);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    userName: Yup.string().required("User Name is required"),
    email: Yup.string().email("Invalid email address").required("Email is required"),
    phoneNumber: Yup.string()
      .required("Phone Number is required")
      .test("is-greater", "Please verify phone number", function () {
        return login_data?.phoneVerified;
      }),//Please provide Phone Number & verify it first
    // licenseField: Yup.string().required("Driving License Number is required"),
    ...((login_data?.role === 'flexiDriver' || login_data?.role === 'professionalDriver') && { ['licenseField']: Yup.string().required("Driving License Number is required") }),
    address: Yup.string().required("Address is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    country: Yup.string().required("Country is required"),
    pincode: Yup.string().required("Pincode is required"),

    document: Yup.mixed()
      .required("Document is required")
    // .test("fileSize", "File size is too large (Max 4MB)", (value: any) => (value && value.size > 4 * 1024 * 1024))
    // .test("fileType", "Unsupported File Format", (value: any) => !value || (value && ["image/jpeg", "image/png", "application/pdf"].includes(value.type))),
  });

  const handleSubmit = async (values: any, { setSubmitting, resetForm }: any) => {
    // console.log('Form Values --->', values);

    if (!login_data?.phoneVerified) {
      dispatch(openNotification({
        open: true,
        message: 'Please verify phone number !!',
        alertType: 'info',
        subText: '',
        borderClass: 'info',
      }));
      setTimeout(() => dispatch(closeNotification()), 5000);
      return;
    }

    const formData = new FormData();
    formData.append('profilePicture', values?.profilePicture);
    formData.append('first_name', values?.firstName);
    formData.append('last_name', values?.lastName);
    formData.append('user_name', values?.userName);
    formData.append('email', values?.email);
    formData.append('phone[code]', phoneData?.code);
    formData.append('phone[number]', phoneData?.number);
    if (login_data?.role === 'flexiDriver' || login_data?.role === 'professionalDriver') {
      formData.append('licenseField', values?.licenseField);
      formData.append('license', values?.document);
    }
    if (login_data?.role === 'carOwner') formData.append('document', values.document);
    formData.append('address[street]', values?.address);
    formData.append('address[city]', values?.city);
    formData.append('address[state]', values?.state);
    formData.append('address[country]', values?.country);
    formData.append('address[pincode]', values?.pincode);
    // formData.append('phoneNo', values.phoneNumber);

    const data = {
      url: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/users/update`,
      bodyData: formData,
      // method: "PUT",
    };

    // await doUploadMediaApiCall(data)
    await doPutApiCall(data)
      .then(async (res: any) => {
        if (!res.error) {
          dispatch(openNotification({
            open: true,
            message: 'Save successfully !!',
            alertType: 'success',
            subText: '',
            borderClass: 'success',
          }));
          setTimeout(() => dispatch(closeNotification()), 4000);
          dispatch(getUserDetails());
          if (!login_data?.profileUpdated) setVerificaton(true);
          resetForm();
        } else {
          console.error(res.error || "Save failed");
        }
      })
      .catch((err) => {
        dispatch(openNotification({
          open: true,
          message: 'Something went wrong',
          alertType: 'error',
          subText: '',
          borderClass: 'error',
        }));
        setTimeout(() => dispatch(closeNotification()), 5000);
        // alert("something went wrong");
        console.error('Error while updating user data ==>', err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      profilePicture: null,
      firstName: "",
      lastName: "",
      userName: "",
      email: "",
      phoneNumber: "",
      // licenseField: "",
      ...((login_data?.role === 'flexiDriver' || login_data?.role === 'professionalDriver') && { ['licenseField']: '' }),
      license: "" as string | null,
      document: "" as string | null, // Use string | null
      address: "",
      city: "",
      state: "",
      country: "",
      pincode: "",
    },
    validationSchema,
    onSubmit: handleSubmit
  });

  const handleCancel = () => {
    setVerificaton(false);
  };

  const handleVerifyClick = async () => {
    setVerificaton(false);
    setsuccessMessage(true);
    const formData = new FormData();
    formData.append('profileUpdated', "1");
    const data = {
      url: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/users/update`,
      bodyData: formData,
      // method: "PUT",
    };
    // await doUploadMediaApiCall(data)
    await doPutApiCall(data)
      .then(async (res: any) => {
        if (!res.error) dispatch(getUserDetails());
        else console.error(res.error || "Save failed");
      })
      .catch((err) => console.error(err, '<<-- err'));
  };

  const handleOkClick = () => {
    setsuccessMessage(false);
    navigate(routes.MainLanding);
  };

  useEffect(() => {
    // console.log("login_data ----->", login_data);

    (async () => {
      // const profilePictureUrl = await fetchMediaData(login_data?.profilePicture?.key) || '';
      const licenseUrl = await fetchMediaData(login_data?.license?.key) || null;
      // setProfileImage(profilePictureUrl);
      login_data?.profilePicture && setProfileImage(login_data?.profilePicture);
      login_data?.profilePicture && setProfileImage(`${process.env.REACT_APP_PUBLIC_BASE_API_URL}/medias?key=${login_data?.profilePicture?.key}&width=${100}`);

      formik.setValues({
        // profilePicture: profilePictureUrl || '',
        profilePicture: login_data?.profilePicture || null,
        firstName: login_data?.first_name || "",
        lastName: login_data?.last_name || "",
        userName: login_data?.user_name || "",
        email: login_data?.email || "",
        phoneNumber: login_data?.phoneNo || "",
        // licenseField: login_data?.licenseField || "",
        ...((login_data?.role === 'flexiDriver' || login_data?.role === 'professionalDriver') && { ['licenseField']: login_data?.licenseField || '' }),
        license: licenseUrl || null,
        address: login_data?.address?.street || "",
        city: login_data?.address?.city || "",
        state: login_data?.address?.state || "",
        country: login_data?.address?.country || "",
        pincode: login_data?.address?.pincode || "",

        document: login_data?.document || null,
      });
    })();
  }, [login_data]);

  useEffect(() => {
    // console.log('userDetails ------>', userDetails);
    if (userDetails && Object.keys(userDetails)?.length > 0) {
      dispatch(set_login_data(userDetails));
      setCookie('userdata', userDetails, 7);
    }
  }, [userDetails])

  useEffect(() => {
    // window.addEventListener("mousemove", () => { });
    return () => {
      dispatch(clearUserDetails());
    }
  }, []);


  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <ProfileBasicSection formik={formik} phoneData={phoneData} setPhoneData={setPhoneData} profileImage={profileImage} setProfileImage={setProfileImage} />
        <ProfileAddressSection formik={formik} />
        <div className="profile-submit-btn">
          <Button label="Save" className="btn btn-primary" type="submit" />
        </div>
      </form>
      {verificationModal && <ConfirmationModal handleCancel={handleCancel} handleVerifyClick={handleVerifyClick} />}
      {successMessage && <SuccessMessage handleOkClick={handleOkClick} />}
    </>
  );
};

export default ProfileSection;
