import React from 'react';
import { Link } from 'react-router-dom';
import usePagination from '@mui/material/usePagination';
import { styled } from '@mui/material/styles';

const List = styled('ul')({
  listStyle: 'none',
  padding: 0,
  margin: 0,
  display: 'flex',
  justifyContent: 'center', // To center the pagination
  alignItems: 'center', // To vertically align the items
});

const PageItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const PaginationLink = styled(Link)(({ theme, selected }:any) => ({
  textDecoration: 'none',
  color: selected ? theme.palette.primary.main : theme.palette.text.primary,
  fontWeight: selected ? 'bold' : 'normal',
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const Pagination = () => {
  const { items } = usePagination({
    count: 3, // You can set this to the actual number of pages you have
  });

  return (
    <nav>
      <List>
        {items.map(({ page, type, ...item }, index) => {
          let children = null;

          if (type === 'start-ellipsis' || type === 'end-ellipsis') {
            children = '…';
          } else if (type === 'page') {
            children = (
              <PaginationLink
                to={`#${page}`}
                // selected={selected ? 1 : 0}
                {...item}
              >
                {page}
              </PaginationLink>
            );
          } else {
            children = (
              <PaginationLink to="#" {...item}>
                {type === 'previous' ? (
                  <>
                    <i className="fas fa-arrow-left me-2" /> Prev
                  </>
                ) : (
                  <>
                    Next <i className="fas fa-arrow-right ms-2" />
                  </>
                )}
              </PaginationLink>
            );
          }

          return <PageItem key={index}>{children}</PageItem>;
        })}
      </List>
    </nav>
  );
};

export default Pagination;
