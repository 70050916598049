import React from 'react';
import CheckboxItem from "../../../common/ui_components/CheckBox";
import Switch from "@mui/material/Switch";

const NotificationCheckboxItem = ({ label, checked }:any) => (
  <li>
    <CheckboxItem label={label} checked={checked} />
  </li>
);

const NotificationToggle = ({ label, description, checked }:any) => (
  <div className="notification-status">
    <div className="notification-status-content">
      <h5>{label}</h5>
      <p>{description}</p>
    </div>
    <div className="status-toggle">
      <Switch defaultChecked={checked} />
    </div>
  </div>
);

export { NotificationCheckboxItem, NotificationToggle };
