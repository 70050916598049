import React, { useState } from "react";
import RelocationBannerCard from "../../common/ui_components/cards/RelocationBannerCard";



const RelocationBannerSection = () => {
  const [selectedTab, setSelectedTab] = useState("Individual");

  const handleTabSelect = (tab: string) => {
    setSelectedTab(tab);
  };

  return (
    <section className="relocation-banner">
      <div className="row align-items-center card-container">
        <div className="col-lg-4 left-section" data-aos="fade-down">
          <RelocationBannerCard selectedTab={selectedTab} onTabSelect={handleTabSelect} />
        </div>
        <div className={`col-lg-8 img-container ${selectedTab === "Enterprise" ? "enterprise-class" : "individual-class"}`}>
          <h1>
            <span style={{ color: '#00CCFF' }}>Efficient</span> and <br />
            <span className="banner-gradient-text" style={{ color: '#0076D7' }}>
              Cost Effective
            </span> Fleet <br /> Relocation Solutions
          </h1>
          <p style={{ color: '#fff' }}>
            Streamline your fleet management with our tailored services for enterprises.<br />
            Trust our expert solutions to ensure your fleet operates smoothly and complies<br />
            with all regulations.
          </p>
        </div>
      </div>
    </section>
  );
};

export default RelocationBannerSection;
