import * as React from "react";
// import Button from "@mui/material/Button";
import Snackbar, { SnackbarCloseReason, SnackbarOrigin } from "@mui/material/Snackbar";
// import CancelIcon from "@mui/icons-material/Cancel";
// import WarningIcon from "@mui/icons-material/Warning";
import {
    Alert,
    // IconButton
} from "@mui/material";
// import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";

export interface State extends SnackbarOrigin {
    open: boolean;
}

const CustomSnackBar = (props: any) => {
    // const dispatch = useDispatch();
    const { open, message, alertType, } = props;

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
        if (reason === 'clickaway') {
            return;
        }
        setTimeout(() => { return false }, 4000);
        // setTimeout(() => dispatch(closeNotification()), 4000);
    };

    return (
        <Snackbar
            // anchorOrigin={anchorOrigin}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={open}
            autoHideDuration={5000}
            style={{ position: 'fixed', zIndex: 1000 }}
            onClose={handleClose}
        // key={vertical + horizontal}
        // action={
        //     <IconButton
        //         size="small"
        //         aria-label="close"
        //         color="inherit"
        //         onClick={messageClose}
        //         data-cy="notifiedMessage-module-closeIcon"
        //     >
        //         <DriveFolderUploadIcon />
        //     </IconButton>
        // }
        >
            <Alert
                // onClose={handleClose}
                variant="filled"
                severity={alertType}
                sx={{ width: '100%' }}
            // style={{
            //     height: 81,
            //     width: "fitContent",
            //     textAlign: "left",
            //     background:
            //         alertType === "success"
            //             ? "linear-gradient(270deg, #262045 0%, #20444C 100%)"
            //             : alertType === "error"
            //                 ? "linear-gradient(270deg, #262045 0%, #481D47 100%)"
            //                 : alertType === "warning" ? "linear-gradient(270deg, #262045 0%, #292723 100%)" : "",
            // }}
            // icon={
            //     alertType === "success" ? (
            //         <DriveFolderUploadIcon className={`text-wv-green h-6`} />
            //     ) : alertType === "error" ? (
            //         <CancelIcon className={`text-wv-red h-6`} />
            //     ) : alertType === "warning" ? (
            //         <WarningIcon className={`text-wv-yellow h-6`} />
            //     ) : false
            // }
            >
                {message}

                {/* {alertType === "success" ? (
                                <p className={`text-wv-green`}>{message}</p>
                            ) : alertType === "error" ? (
                                <p className={`text-wv-red`}>{message}</p>
                            ) : alertType === "warning" ? (
                                <p className={`text-wv-yellow`}>{message}</p>
                            ) : alertType === "info" ? (
                                <p className={`text-wv-yellow`}>{message}</p>
                            ) : null} */}
            </Alert>
        </Snackbar>
    );
}

export default CustomSnackBar;