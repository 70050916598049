import React from 'react';
import { Link } from 'react-router-dom';
import ImageWithBasePath from '../../../../core/data/img/ImageWithBasePath';
import ModalWrapper from './ModalWrapper';

const BookingStatus = () => {
  return (
    <ModalWrapper id="view_status" size="modal-lg">
      <div className="booking-header">
        <div className="booking-img-wrap">
          <div className="book-img">
            <ImageWithBasePath
              src="assets/img/cars/car-05.jpg"
              alt="img"
            />
          </div>
          <div className="book-info">
            <h6>Chevrolet Camaro</h6>
            <p>
              <i className="feather icon-map-pin" /> Location: Miami
              St, Destin, FL 32550, USA
            </p>
          </div>
        </div>
        <div className="book-amount">
          <p>Total Amount</p>
          <h6>
            $4700{" "}
            <Link to="#">
              <i className="feather icon-alert-circle" />
            </Link>
          </h6>
        </div>
      </div>
      <div className="refund-wrap booking-group">
        <div className="booking-wrapper">
          <h6>Refund Method</h6>
          <div className="card-status-wrap">
            <div className="card-status">
              <h5>Mastercard</h5>
              <p>Started on: 20 Oct 2023</p>
            </div>
            <div className="status-img">
              <ImageWithBasePath
                src="assets/img/icons/card-icon.svg"
                alt="img"
              />
            </div>
          </div>
          <div className="refund-process">
            <h5>Refund Process</h5>
            <ul>
              <li>
                <h6>Dreams Rent initiated your refund</h6>
                <p>20 Oct 2023</p>
              </li>
              <li>
                <h6>Mastercard has accepted your request</h6>
                <p>20 Oct 2023</p>
              </li>
              <li>
                <h6>Refund credited to your account successfully</h6>
                <p>20 Oct 2023</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default BookingStatus;
