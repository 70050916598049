import React from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { styled } from '@mui/system';

const StyledRadio = styled(Radio)({
  display: 'none', // Hide the default radio button
});

const StyledLabel = styled(FormControlLabel)(({ theme }) => ({
  margin: '5px',
  border: "1px solid #b3adad",
  borderRadius: '4px',
  padding: theme.spacing(1, 2),
  color: 'grey',
  '&:not(:last-child)': {
    marginRight: theme.spacing(1),
  },
  '&:hover': {
    borderColor: '#1561D4', 
    backgroundColor: "rgba(0, 118, 215, 0.1)", 
    color: '#1561D4',
  },
  '&.Mui-checked': {
    backgroundColor: "rgba(0, 118, 215, 0.2)",
    color: "#1561D4",
    borderColor: '#1561D4',
  },
}));

const RadioFilter = ({ items, name }: { items: any[], name?: string }) => (
  <RadioGroup row name={name}>
    {items.map((item, itemIndex) => (
      <StyledLabel
        key={itemIndex}
        value={item.label}
        control={<StyledRadio />}
        label={item.label}
        className={item.checked ? 'Mui-checked' : ''}
      />
    ))}
  </RadioGroup>
);

export default RadioFilter;
