import React from "react";
import BookingInfoCard from "../../common/ui_components/BookingInfoCard";
import PaymentMethodSelector from "../../common/ui_components/PaymentMethodSelector";

const BookingCheckout = ({ onNext, onBack }: any) => {

  const backNavigatePath = () => {
    onBack(); 
  };

  const navigatePath = () => {
    onNext(); 
  };

  return (
    <div className="col-lg-8">
      <div className="booking-information-main">
        <form>
          <BookingInfoCard title="Payment" iconClass="bx bx-money">
            <PaymentMethodSelector />
          </BookingInfoCard>
          <div className="booking-info-btns d-flex justify-content-end">
            <button
              onClick={backNavigatePath}
              className="btn btn-secondary"
              type="button"
            >
              Back to billing info
            </button>
            <button
              onClick={navigatePath}
              className="btn btn-primary continue-book-btn"
              type="button"
            >
              Pay $4700 &amp; Place Reservation
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BookingCheckout;
