import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import { all_routes } from "../router/all_routes";
import AuthenticationHeader from "../common/ui_components/AuthenticationComponents/AuthenticationHeader";
import AuthenticationFooter from "../common/ui_components/AuthenticationComponents/AuthenticationFooter";
import WrapperModal from "../common/ui_components/AuthenticationComponents/WrapperModal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { callResetPass } from "../../redux/reducers/authReducer";
import { useLocation, useNavigate } from "react-router-dom";
import { closeNotification, openNotification } from "../../redux/reducers/notificationReducer";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { emailFromForgotPass } = location.state || {}; // Destructure the passed state

  const loading = useSelector((state: any) => state?.authReducer?.loading);
  const { code, message, error } = useSelector((state: any) => state?.authReducer?.resetPassRes);

  const routes = all_routes;
  const [isToggle, setIsToggle] = useState(false);
  const [isToggle1, setIsToggle1] = useState(false);

  const formik = useFormik({
    initialValues: {
      newPass: "",
      confirmPass: "",
    },
    validationSchema: Yup.object({
      newPass: Yup.string()
        // .min(8, 'Password must be at least 8 characters long')
        .required('Password is required'),
      confirmPass: Yup.string()
        .oneOf([Yup.ref('newPass'), undefined], 'Passwords must match')
        .required('Confirm Password is required'),
    }),
    onSubmit: (values) => {
      // console.log("Form values ---->", values);
      dispatch(callResetPass({ email: emailFromForgotPass, newpassword: values?.newPass }));
    },
  });

  useEffect(() => {
    // console.log("loading in resSetPass -->", loading);
    
    switch (loading) {
      case 'pending':
        break;

      case 'succeeded':
        dispatch(openNotification({ open: true, message: `${message} !!`, alertType: error ? 'error' : 'success', subText: '', borderClass: error ? 'error' : 'success' }));
        setTimeout(() => dispatch(closeNotification()), 4000);
        if (code === 'PASSWORD_RESET_SUCCESSFUL') navigate(routes.login);
        break;

      case 'failed':
        break;

      default:
        break;
    }

  }, [loading])

  return (
    <div className="main-wrapper login-body">
      <AuthenticationHeader />
      <div className="login-wrapper">
        <WrapperModal title="Reset Password" subtitle="Your new password must be different from previous used passwords.">
          <>
            <form onSubmit={formik.handleSubmit}>
              {/* New Password */}
              <div className="input-block">
                <label className="form-label">New Password <span className="text-danger">*</span></label>
                <div className="pass-group">
                  <input
                    className="form-control pass-input"
                    placeholder="Enter New Password"
                    type={isToggle ? "text" : "password"}
                    onInput={(e: any) => formik.setFieldValue("newPass", e.target.value)}
                  />
                  <span onClick={() => setIsToggle(!isToggle)} className={`fas toggle-password ${isToggle ? "fa-eye" : "fa-eye-slash"}`} />
                </div>

                {formik.touched?.newPass && formik.errors?.newPass && (
                  <div className="error text-danger font-short" style={{ marginLeft: '15px' }}>
                    {formik.errors?.newPass}
                  </div>
                )}
              </div>

              {/* Confirm Password */}
              <div className="input-block">
                <label className="form-label">Confirm Password <span className="text-danger">*</span></label>
                <div className="pass-group">
                  <input
                    className="form-control pass-input"
                    placeholder="Enter Confirm Password"
                    type={isToggle1 ? "text" : "password"}
                    onInput={(e: any) => formik.setFieldValue("confirmPass", e.target.value)}
                  />
                  <span onClick={() => setIsToggle1(!isToggle1)} className={`fas toggle-password ${isToggle1 ? "fa-eye" : "fa-eye-slash"}`} />
                </div>

                {formik.touched?.confirmPass && formik.errors?.confirmPass && (
                  <div className="error text-danger font-short" style={{ marginLeft: '15px' }}>
                    {formik.errors?.confirmPass}
                  </div>
                )}
              </div>
              <button type="submit" className="btn btn-primary w-100 btn-size font-semibold">Reset Password</button>
              {/* <Link
                to={routes.MainLanding}
                className="btn btn-primary w-100 btn-size"
              >
                Save Changes
              </Link> */}
            </form>
          </>
        </WrapperModal>
      </div>
      <AuthenticationFooter />
    </div>
  );
};

export default ResetPassword;
