import React from 'react'
import { Link } from 'react-router-dom'
import CustomAccordion from '../../common/ui_components/Accordion';
import SearchInput from '../../common/ui_components/SearchInput';
import IconButton from '../../common/ui_components/buttons/IconButton';
import useDropdownOptions from '../../../services/hooks/DropDownOption';



const FilterSection = () => {

  const FilterSections = [
    {
      id: "1",
      title: "Car Brand",
      type: "checkbox",
      items: [
        { label: "Tesla" },
        { label: "Ford" },
        { label: "Mercedes Benz" },
        { label: "Audi" },
      ],
    },
    {
      id: "2",
      title: "Car Category",
      type: "checkbox",
      items: [
        { label: "Convertible", count: 25 },
        { label: "Coupe", count: 15 },
        { label: "Sedan", count: 10 },
        { label: "EV", count: 5 },
      ],
    },
    {
      id: "3",
      title: "Year",
      type: "checkbox",
      items: [
        { label: "2024" },
        { label: "2022" },
        { label: "2021" },
        { label: "2020" },
      ],
    },
    {
      id: "4",
      title: "Fuel Type",
      type: "radio",
      radioName: "fuelType",
      items: [
        { id: "petrol", label: "Petrol", checked: false },
        { id: "diesel", label: "diesel", checked: true },
        { id: "electic", label: "Electric", checked: false },
        { id: "cng", label: "CNG", checked: false },
      ],
    },
    {
      id: "5",
      title: "Mileage",
      type: "radio",
      radioName: "mileage",
      items: [
        { id: "limited", label: "Limited", checked: false },
        { id: "unlimited", label: "Unlimited", checked: true },
      ],
    },
    {
      id: "6",
      title: "Car Specifications",
      type: "checkbox",
      items: [
        { label: "Air Conditioners" },
        { label: "Keyless" },
        { label: "Panoramic" },
        { label: "Bluetooth" },
      ],
    },
    {
      id: "7",
      title: "Capacity",
      type: "checkbox",
      items: [
        { label: "2 Seater" },
        { label: "4 Seater" },
        { label: "5 Seater" },
        { label: "7 Seater" },
      ],
    },
    {
      id: "8",
      title: "Transmission",
      type: "radio",
      radioName: "transmission",
      items: [
        { id: "manual", label: "Manual" },
        { id: "semiAutomatic", label: "semi Automatic", checked: true },
        { id: "automatic", label: "Automatic" },
      ],
    },
  ];
  const { options: carOptions } = useDropdownOptions('carCategory');
  console.log("options",carOptions)
  return (
    <div className="stickybar">
      <form action="#" autoComplete="off" className="sidebar-form">
        <div className="sidebar-heading">
          <h3>What Are You Looking For</h3>
        </div>
        <div className="product-search">
          <SearchInput />
        </div>
        <div className="accord-list">
          <CustomAccordion sections={FilterSections} />
        </div>
        <IconButton label='Filter result' iconClass="feather icon-filter me-2" />
        <Link to="#" className="reset-filter">
          Reset Filter
        </Link>
      </form>
    </div>
  )
}

export default FilterSection