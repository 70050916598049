export const testimonialsData = [
  {
    id: 1,
    name: "Rabien Ustoc",
    image: "assets/img/profiles/user1.png",
    rating: 5.0,
    review:
      "Flexymove made fleet management seamless for our company.",
  },
  {
    id: 2,
    name: "Valerie L. Ellis",
    image: "assets/img/profiles/user2.png",
    rating: 5.0,
    review:
      "Efficient service and transparent pricing  highly recommend",
  },
  {
    id: 3,
    name: "Laverne Marier",
    image: "assets/img/profiles/user3.png",
    rating: 5.0,
    review:
      "Reliable and cost-effective solutions for our fleet relocation.",
  },
  {
    id: 4,
    name: "Sydney Salmons",
    image: "assets/img/profiles/user1.png",
    rating: 5.0,
    review:
      "Reliable and cost-effective solutions for our fleet relocation.",
  },
  {
    id: 5,
    name: "Lucas Moquin",
    image: "assets/img/profiles/user1.png",
    rating: 5.0,
    review:
      "Reliable and cost-effective solutions for our fleet relocation.",
  },
  // Add more testimonials as needed
];