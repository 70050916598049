import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({ element, allowedRoles }:any) => {
  const login_data = useSelector((state: any) => state?.authReducer?.login_data);

  const userRole = login_data?.role; 
  const location = useLocation(); 

  if (!allowedRoles.includes(userRole)) {
    return <Navigate to="/authentication/login" state={{ from: location }} replace />; 
  }

  return element; 
};

export default ProtectedRoute;
