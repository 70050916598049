// import React, { useEffect } from "react";
// import AllRoutes from "./router/router";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   // set_current_route,
//   // set_current_route_array,
//   set_is_mobile_sidebar,
// } from "../core/data/redux/action";
// // import { useLocation } from "react-router-dom";

// const Feature = () => {
//   const mobileSidebar = useSelector((state: any) => state.mobileSidebar);
//   // const [currentRoute, setCurrentRoute] = useState(location.pathname);
//   // const location = useLocation();
//   const dispatch = useDispatch();

//   // const getRoutes = () => {
//   //   setCurrentRoute(location.pathname);
//   //   const splitVal = location.pathname.split("/");
//   //   const route_data = {
//   //     base: splitVal[1],
//   //     page: splitVal[2] || "",
//   //     last: splitVal[3] || "",
//   //   };
//   //   dispatch(set_current_route_array(splitVal));
//   //   dispatch(set_current_route(route_data));
//   //   dispatch(set_is_mobile_sidebar(false));
//   // };
//   useEffect(() => {
//     dispatch(set_is_mobile_sidebar(false));
//   }, [location, dispatch]);

//   return (
//     <>
//       <div className={`main-wrapper ${mobileSidebar ? "menu-opened true" : "false"}`}>
//         <AllRoutes />
//       </div>
//     </>
//   );
// };

// export default Feature;


// ---------- for scroll -----------------

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import AllRoutes from "./router/router";
import { useDispatch, useSelector } from "react-redux";
// import { set_is_mobile_sidebar } from "../core/data/redux/action";
import { set_is_mobile_sidebar } from "../redux/reducers/mobileViewRedurer";
import CustomSnackBar from "./common/ui_components/CustomSnackBar";

const Feature = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  // const mobileSidebar = useSelector((state: any) => state?.mobileSidebar);
  const mobileSidebar = useSelector((state: any) => state?.mobileViewRedurer?.mobileSidebar);
  const notification = useSelector((state: any) => state?.notificationReducer);

  useEffect(() => {
    dispatch(set_is_mobile_sidebar(false));
    window.scrollTo(0, 0);
  }, [location, dispatch]);

  return (
    <div className={`main-wrapper ${mobileSidebar ? "menu-opened true" : "false"}`}>
      <AllRoutes />

      {notification && notification?.open &&
        <CustomSnackBar
          open={notification?.open}
          message={notification?.message}
          alertType={notification?.alertType}
          // messageClose={messageClose}

          subText={notification?.subText}
          borderClass={notification?.borderClass}
        />
      }
    </div>
  );
};

export default Feature;
