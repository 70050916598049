import React from 'react';
import ModalWrapper from './ModalWrapper';

const defaultReasonData = {
  reason: "On the booking date I have other work on my personal so I am cancelling my booking. On the booking date I have other work on my personal so I am cancelling my booking. On the booking date I have other work on my personal so I am cancelling my booking. On the booking date I have other work on my personal so I am cancelling my booking."
};

const CancelReason = ({ reason = defaultReasonData.reason }: { reason?: string }) => {
  return (
    <ModalWrapper id="cancel_reason" title="Cancel Reason">
      <div className="reason-item">
        <p>{reason}</p>
      </div>
    </ModalWrapper>
  );
};

export default CancelReason;
