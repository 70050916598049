import React from 'react';

// type Props = {
//   id: string;
//   title: string;
//   message: string;
//   onDelete?: () => void;
//   onCancel?: () => void;
// }

const ConfirmationModal = ({handleCancel,handleVerifyClick}:any) => {
  return (
    <div className='confirmation_modal'>
      <div className='modal_header'>Account Verification</div>
      <div className='modal_body'>{"Please review your details carefully. Once you click “Verify”, your information will be submitted for approval. You won't be able to make changes until the verification process is complete."}</div>
      <div className='btn_container'>
        <div className='single_btn' onClick={handleVerifyClick}>Verify my account</div>
        <div className='single_btn' style={{color:'#000', backgroundColor:"#F3F3F3"}} onClick={handleCancel}>Cancel</div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
