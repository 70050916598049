// import React from "react";
// import { TimePicker } from "antd";
// import type { Dayjs } from "dayjs";
// import dayjs from "dayjs";

// type Props = {
//   label?: string;
//   onChange?: (date: Dayjs, dateString: string | string[]) => void;
//   placeholder?: string;
//   defaultValue?: Dayjs;
// }

// const CustomTimePicker = ({ label, onChange, placeholder, defaultValue = dayjs("00:00:00", "HH:mm:ss") }: Props) => {
//   return (
//     <>
//       <div className="input-block">
//         <label>{label}</label>
//       </div>
//       <div className="input-block-wrapp">
//         <div className="input-block time-widge">
//           <div className="group-img">
//             <TimePicker
//               placeholder={placeholder}
//               className="form-control timepicker"
//               onChange={onChange}
//               defaultValue={defaultValue}
//             />
//             <span>
//               <i className="feather icon-clock"></i>
//             </span>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default CustomTimePicker;

import React from "react";
import { TimePicker } from "antd";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";

type Props = {
  label?: string;
  className?: string;
  onChange?: (date: Dayjs, dateString: string | string[]) => void;
  placeholder?: string;
  defaultValue?: Dayjs;
}

const CustomTimePicker = ({ label, className, onChange, placeholder, defaultValue = dayjs("00:00", "HH:mm") }: Props) => {
  return (
    <>
      {label && <div className="input-block"><label>{label}</label></div>}

      <div className="input-block-wrapp">
        <div className="input-block time-widge">
          <div className="group-img">
            <TimePicker
              placeholder={placeholder}
              className={`form-control timepicker ${className ? className : ''}`}
              onChange={onChange}
              defaultValue={defaultValue}
              format="HH:mm"
              showSecond={false}
            />
            <span>
              <i className="feather icon-clock"></i>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomTimePicker;


