import React, { useEffect } from "react";
import Breadcrumbs from "../common/ui_components/breadcrumbs";
import AOS from "aos";
import "aos/dist/aos.css";
import { useSelector } from "react-redux";
import { ContactUs } from "../../core/data/interface/interface";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import { Link } from "react-router-dom";
import Header from "../common/Header/header";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import InputField from "../common/ui_components/InputFields/InputField";

const Contact = () => {
  const data = useSelector((state: ContactUs) => state.contactdata);

  useEffect(() => {
    AOS.init({ duration: 1200, once: true });
  }, []);

  // Formik validation schema
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid email format").required("Email is required"),
    message: Yup.string().required("Message is required"),
  });

  const initialValues = {
    name: "",
    email: "",
    phone: "",
    message: "",
  };

  const handleSubmit = () => {
    alert("Thank you for reaching out! We will get back to you soon.");
  };

  return (
    <div className="main-wrapper">
      <Header />
      <Breadcrumbs title="Contact us" subtitle="contact us" maintitle={""} />
      <section className="contact-section">
        <div className="card_container">
          <div className="contact-info-area">
            <div className="row">
              {data?.map((info: ContactUs, index: number) => (
                <div
                  key={index}
                  className="col-lg-3 col-md-6 col-12 d-flex"
                  data-aos="fade-down"
                  data-aos-duration={1200}
                  data-aos-delay="0.1"
                >
                  <div className="single-contact-info flex-fill">
                    <span>
                      <i className={info.icon} />
                    </span>
                    <h3>{info.title}</h3>
                    {info.type === "phone" ? (
                      <Link to={info.link}>{info.text}</Link>
                    ) : (
                      <p>
                        <Link to={info.link}>{info.text}</Link>
                      </p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div
            className="form-info-area"
            data-aos="fade-down"
            data-aos-duration={1200}
            data-aos-delay="0.5"
          >
            <div className="row">
              <div className="col-lg-6 d-flex">
                <ImageWithBasePath
                  src="assets/img/bg/relocation_banner2.png"
                  className="img-fluid"
                  alt="Contact"
                />
              </div>
              <div className="col-lg-6">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ handleChange, values }) => (
                    <Form>
                      <h1>Get in touch!</h1>
                      <div className="row">
                        <div className="col-md-12">
                          <InputField
                            label="Name"
                            placeholder="Enter your name"
                            value={values.name}
                            required
                            onChange={handleChange("name")}
                          />
                          <ErrorMessage
                            name="name"
                            component="div"
                            className="error text-danger font-short"
                          />
                        </div>
                        <div className="col-md-12">
                          <InputField
                            label="Email Address"
                            placeholder="Enter your email"
                            value={values.email}
                            required
                            onChange={handleChange("email")}
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="error text-danger font-short"
                          />
                        </div>
                        <div className="col-md-12">
                          <InputField
                            label="Phone number"
                            placeholder="Enter your phone number"
                            value={values.phone}
                            onChange={handleChange("phone")}
                          />
                        </div>
                        <div className="col-md-12">
                          <div className="input-block">
                            <label>
                              Comments <span className="error text-danger font-short">*</span>
                            </label>
                            <Field
                              as="textarea"
                              name="message"
                              className="form-control"
                              rows={4}
                              placeholder="Enter your message"
                            />
                            <ErrorMessage
                              name="message"
                              component="div"
                              className="error text-danger font-short"
                            />
                          </div>
                        </div>
                      </div>
                      <button className="btn contact-btn" type="submit">
                        Send Enquiry
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
