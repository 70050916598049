import React from 'react'
import { all_routes } from '../../router/all_routes';
import { useNavigate } from 'react-router-dom';



const UserOptionsModal = ({logout}:any) => {
  const navigate = useNavigate();

  const handleDashboard = () =>{
    navigate(all_routes.userSettings);
  }

  return (
    <div className='user_option'>
      <div onClick={handleDashboard}>Dashboard</div>
      <div onClick={logout}>Log Out</div>
    </div>
  )
}

export default UserOptionsModal