import React, { useEffect, useState } from 'react';
import BookingAvatar from './BookingAvatar';
import { IconButton, Tooltip } from '@mui/material';
import useHelperFuncs from '../../../utils/hooks/useHelperFuncs';
import { setCookie } from '../../../utils/cookieUtils';
import { useNavigate } from 'react-router-dom';
import { all_routes } from '../../router/all_routes';
import EditIcon from '@mui/icons-material/Edit';
import { format } from 'date-fns';



type Props = {
  imgSrc: string;
  carName: string;
  carType: string;
  startDate: string;
  endDate: string;
  price: string;
  paymentStatus: string;
  relocationStatus: string;

  carId: string;
  stepCompleted: number;
  rentAmount: string;
  route: string;
}

const DashboardBookingItem = ({
  imgSrc,
  carName,
  carType,
  startDate,
  endDate,
  price,
  paymentStatus,
  relocationStatus,

  carId,
  stepCompleted,
  rentAmount,
}: Props) => {
  const navigate = useNavigate();
  const { getStatusClass } = useHelperFuncs();

  const [pStatusCalss, setPStatusCalss] = useState('');
  const [rStatusCalss, setRStatusCalss] = useState('');

  const handleClick = () => {
    setCookie("stepperstep", stepCompleted++, 7);
    setCookie("CarId", carId, 7);
    setCookie("rentAmount", rentAmount, 7);
    navigate(all_routes?.relocateCar);
  }

  useEffect(() => {
    setPStatusCalss(getStatusClass(paymentStatus));
    setRStatusCalss(getStatusClass(relocationStatus));
  }, [paymentStatus, relocationStatus])


  return (
    <tr>
      <td>
        <BookingAvatar
          img={imgSrc}
          carName={carName}
          deliveryStatus={`Car Type : ${carType}`}
        />
      </td>
      <td>
        <h6>Start date</h6>
        <p>{format(new Date(startDate), "dd MMM yyyy, hh:mm a")}</p>
      </td>
      <td>
        <h6>End Date</h6>
        <p>{format(new Date(endDate), "dd MMM yyyy, hh:mm a")}</p>
      </td>
      <td>
        <h6>Price</h6>
        <h5 className="text-danger">{price ? `€${price}` : 'NA'}</h5>
      </td>
      {paymentStatus &&
        <td>
          <h6>Payment</h6>
          <p><span className={pStatusCalss}>{paymentStatus}</span></p>
        </td>
      }
      {relocationStatus &&
        <td>
          <h6>Relocation</h6>
          <span className={rStatusCalss}>{relocationStatus}</span>
        </td>
      }

      {paymentStatus === 'success' ? <td><h6>NA</h6></td> :
        <td>
          <Tooltip title={stepCompleted === 3 && (paymentStatus === 'pending') ? "Complete Payment" : 'Modify'}>
            <IconButton onClick={handleClick}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </td>
      }
    </tr>
  )
};

export default DashboardBookingItem;
