import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckBoxFilter from './CheckBoxFilterContainer';
import RadioFilter from './RadioFilter';
import ColorPicker from './ColorPicker';
import PricingSlider from './PricingSlider';

const CustomAccordion = ({ sections }: { sections: any[] }) => {
  const [expanded, setExpanded] = useState<string | false>(sections[0]?.id || false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.name, event.target.checked);
  };

  return (
    <div>
      {sections.map((section, sectionIndex) => (
        <Accordion
          key={sectionIndex}
          expanded={expanded === section.id}
          onChange={handleChange(section.id)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${section.id}-content`}
            id={`panel${section.id}-header`}
          >
            <Typography className='filter-title'>{section.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {section.type === 'checkbox' && (
              <CheckBoxFilter items={section.items} onChange={handleCheckboxChange} />
            )}
            {section.type === 'radio' && <RadioFilter items={section.items} name={section.radioName} />}
            {section.type === 'slider' && <PricingSlider />}
            {section.type === 'colorPicker' && <ColorPicker items={section.items} />}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default CustomAccordion;
