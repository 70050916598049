import React from "react";
import ImageWithBasePath from "../../../core/data/img/ImageWithBasePath";
import SearchSection from "./SearchSection";

const BookingBannerSection = () => {

  return (
    <section className="booking-banner">
      <div className="row">
        <div className="col-lg-5 left-section" data-aos="fade-down">
          <h1>
            <span style={{ color: '#00CCFF' }}>Efficient</span> and <br />
            <span className="banner-gradient-text " style={{ color: '#0076D7' }}>Cost Effective</span> Fleet Relocation Solutions
          </h1>
          <p style={{ color: '#fff' }}>
            Streamline your fleet management with our tailored services for enterprises.<br />
            Trust our expert solutions to ensure your fleet operates smoothly and complies<br />
            with all regulations.
          </p>
        </div>
        <div className="col-lg-7" data-aos="fade-down">
          <div className="banner-imgs">
            <ImageWithBasePath
              src="assets/img/bg/bookingbanner.png"
              className="img-fluid aos"
              alt="bannerimage"
            />
          </div>
        </div>
      </div>
      <div className="search-container">
        <SearchSection />
      </div>
    </section>
  );
}

export default BookingBannerSection;