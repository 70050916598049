import React, { useEffect, useState } from "react";
import BookingFormSection from "./BookingFormSection";
import { LuUpload } from 'react-icons/lu';
import ImageWithBasePath from "../../../core/data/img/ImageWithBasePath";
import { FaFilePdf } from "react-icons/fa6";
// import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { IconButton, Tooltip } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';



const FileUploader = ({ label, required, file, onChange, readOnly }: any) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [sizeError, setSizeError] = useState<string>('');

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files?.[0];
    if (files) {
      if (files?.size > 4 * 1024 * 1024) {
        setSizeError('File size is too large (Max 4MB)');
        return;
      } else setSizeError('');
      setSelectedFile(files);

      // file(files); // Pass file to parent component
      onChange && onChange({ target: { files: [files] } });
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files?.[0];
    if (files) {
      if (files?.size > 4 * 1024 * 1024) {
        setSizeError('File size is too large (Max 4MB)');
        return;
      } else setSizeError('');
      setSelectedFile(files);
      file(files);
      onChange && onChange({ target: { files: [files] } });
    }
  };

  const getFileIcon = (file: any) => {
    if (file?.mimetype?.startsWith('image/')) {
      return <ImageWithBasePath srcType='new' src={`${process.env.REACT_APP_PUBLIC_BASE_API_URL}/medias?key=${file?.key}&width=${100}`} alt="document" className="object-fit-contain rounded-2 w-100 h-100" />
    } else if (file?.type?.startsWith('image/')) {
      return <ImageWithBasePath src={URL.createObjectURL(file)} alt="document" className="object-fit-contain rounded-2 w-100 h-100" />
    }
    return <div className="pdf-file-upload"><FaFilePdf className="pdf_icon" /></div>;
  };

  const removeFile = () => {
    setSelectedFile(null);
    // onChange({ target: { files: null } });
  };

  useEffect(() => {
    if (file?.length > 0) {
      if (file[0]?.size > 4 * 1024 * 1024) {
        setSizeError('File size is too large (Max 4MB)');
        return;
      } else setSizeError('');
      setSelectedFile(file[0]);
    }
  }, [file])


  return (
    <BookingFormSection>
      <label className="form-label">
        {label} {required && <span className="text-danger"> *</span>}
      </label>
      {selectedFile &&
        <div style={{ float: 'right', cursor: 'pointer' }}>
          {/* <DeleteOutlineIcon onClick={removeFile} /> */}

          <Tooltip title="Delete">
            <IconButton onClick={removeFile}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      }
      <div
        className="profile-uploader"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        {selectedFile ? (
          <div className="d-flex flex-wrap justify-content-center gap-2">
            <div className="document-upload-preview">
              {getFileIcon(selectedFile)}
              <p>{selectedFile?.name}</p>
            </div>
          </div>
        ) :
          (<>
            <div className="flex flex-column justify-content-center align-items-center gap-2">
              <div
                className="py-2 px-3 rounded-2 text-white fw-bold d-flex justify-content-center align-items-center gap-2"
                style={{ backgroundColor: '#0076D7' }}
              >
                <LuUpload />
                Upload File
              </div>
              <p className="mt-2">or Drag and Drop File</p>
            </div>
            <input
              type="file"
              id="file_input"
              onChange={handleFileChange}
              onClick={(e) => readOnly && e.preventDefault()}  // Prevent click if readOnly is true
              accept="image/*,application/pdf"
            />
          </>)}
        < p className="img-size-info">
          The maximum file size is 4MB. Formats: jpeg, jpg, png, pdf.
        </p>
      </div>

      {sizeError &&
        <div className="error text-danger font-short" style={{ margin: '0 0 5px 5px' }}>
          {sizeError}
        </div>
      }
    </BookingFormSection >
  );
};

export default FileUploader;
