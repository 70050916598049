import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { testimonialsData } from "../../../core/data/json/testimonials_data";
import TestimonialCard from "../../common/ui_components/cards/TestimonialCard";

const RatingAboutUsSection = () => {
  const testimonials = testimonialsData;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 992, 
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, 
        settings: {
          slidesToShow: 1, 
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="section about-testimonial testimonials-section">
      <div className="card_container">
        <div className="section-heading" data-aos="fade-down">
          <h2 className="title text-white">What People Say About Us?</h2>
          <p className="description text-white">
            Discover what our customers think about us
          </p>
        </div>
        <Slider
          {...settings}
          className="owl-carousel about-testimonials testimonial-group mb-0 owl-theme"
        >
          {testimonials.map((testimonial) => (
            <TestimonialCard key={testimonial.id} testimonial={testimonial} />
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default RatingAboutUsSection;

