import { getCookie, removeCookie } from "./cookieUtils";

export const doGetApiCall = async (data: any) => {
  return new Promise((resolve, reject) => {
    let token: any;
    if (typeof window !== 'undefined') {
      token = getCookie('token')
    }
    const reqstValues = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token || "",
      },
    };
    fetch(data.url, reqstValues)
      .then((response) => {
        if (response.status === 401) {
          removeCookie('token');
          removeCookie('userdata');
          // localStorage.clear();
        }
        resolve(response.json());
      })
      // .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const doPostApiCall = async (data: any) => {
  return new Promise((resolve, reject) => {
    let token: any;
    if (typeof window !== 'undefined') {
      token = getCookie('token')
    }
    const reqstValues = {
      method: "POST",
      body: JSON.stringify(data.bodyData),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token || "",
        // ...(!data?.url.includes('auth/login') && { ['Authorization']: token || '' }),
      },
    };
    fetch(data.url, reqstValues)
      .then((result) => {
        if (result.status === 401) {
          // localStorage.clear()
        }
        return result.json();
      }).then((result) => {
        if (result?.token) localStorage.setItem("token", result.token);
        resolve(result);
      }).catch((err) => {
        reject(err);
      });
  });
};

export const doDeleteApiCall = async (data: any) => {
  return new Promise((resolve, reject) => {
    let token: any;
    if (typeof window !== 'undefined') {
      token = getCookie('token')
    }
    const reqstValues = {
      method: "DELETE",
      body: JSON.stringify(data.bodyData),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token || "",
      },
    };
    fetch(data.url, reqstValues)
      .then((response) => {
        if (response.status === 401) {
          // localStorage.clear()
        } else {
          return response.json();
        }
      })
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

// export const doUploadMediaApiCall = async (data: any) => {
//   return new Promise((resolve, reject) => {
//     let token: any;
//     if (typeof window !== 'undefined') {
//       token = getCookie('token')
//     }
//     const reqstValues = {
//       method: data.method,
//       body: data.body,
//       headers: {
//         // "Content-Type": "multipart/form-data",
//         Accept: "application/json",
//         Authorization: token || "",
//       },
//     };
//     fetch(data.url, reqstValues)
//       .then((result) => {
//         if (result.status === 401) {
//           // localStorage.clear()
//         } else {
//           return result.json();
//         }
//       })
//       .then((result) => {
//         if (result?.token) localStorage.setItem("token", result.token);
//         resolve(result);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };

export const doPutApiCall = async (data: any) => {
  return new Promise((resolve, reject) => {
    let token: any;
    if (typeof window !== 'undefined') {
      token = getCookie('token')
    }
    // console.log("body type", typeof data.bodyData);
    const reqstValues = {
      method: "PUT",
      body: (typeof data.bodyData === 'object') ? data.bodyData : JSON.stringify(data.bodyData),
      headers: {
        // "Content-Type": "multipart/form-data",
        // "Content-Type": (typeof data.bodyData === 'object') ? data.bodyData : "application/json",
        ...(!(typeof data.bodyData === 'object') && { ['Content-Type']: "application/json" }),
        Accept: "application/json",
        Authorization: token || "",
      },
    };
    fetch(data.url, reqstValues)
      .then((result) => {
        // console.log(result.status, '***code')
        if (result.status === 401) {
          // localStorage.clear()
        } else {
          return result.json();
        }
      })
      .then((result) => {
        if (result?.token) localStorage.setItem("token", result.token);
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const doPatchApiCall = async (data: any) => {
  return new Promise((resolve, reject) => {
    let token: any;
    if (typeof window !== 'undefined') {
      token = getCookie('token');
    }
    const reqstValues = {
      method: "PATCH",
      body: JSON.stringify(data.bodyData),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token || "",
      },
    };

    fetch(data.url, reqstValues)
      .then((result) => {
        if (result.status === 401) {
          // localStorage.clear();
        } else {
          return result.json();
        }
      })
      .then((result) => {
        if (result?.token) localStorage.setItem("token", result.token);
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

