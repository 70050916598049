// import React from 'react'

const useHelperFuncs = () => {
    const toTitleCase = (str: string) => {
        return str?.toLowerCase() // Convert the entire string to lowercase
            .split(' ') // Split the string into words
            .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
            .join(' '); // Join the words back into a single string
    };

    const getStatusClass = (status: string) => {
        switch (status) {
            // Payment Status
            case "pending":
                return "badge badge-light-warning";
            case "success":
                return "badge badge-light-success";
            case "failed":
                return "badge badge-light-danger";

            // Relocation Status
            case "upcoming":
                return "badge badge-light-secondary";
            case "inprogress":
                return "badge badge-light-warning";
            case "completed":
                return "badge badge-light-success";
            case "cancelled":
                return "badge badge-light-danger";

            default:
                return "";
        }
    };

    const getCarExtraServices = (specifications: any) => {
        // const specs = [
        // {
        //     id: 4,
        //     imgSrc: "assets/img/icons/extraService4.png",
        //     label: "Fuel Options",
        // },
        // {
        //     id: 7,
        //     imgSrc: "assets/img/icons/extraService7.png",
        //     label: "Additional Accessories",
        // },
        // {
        //     id: 8,
        //     imgSrc: "assets/img/icons/extraService8.png",
        //     label: "Express Check-in/out",
        // },
        // ];

        const specs = specifications?.map((spec: any) => {
            if (spec?.name === 'GPS') {
                return {
                    id: 1,
                    imgSrc: "assets/img/icons/extraService1.png",
                    // imgSrc: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/medias?key=${spec?.icon?.key}&width=${100}` || "assets/img/icons/extraService1.png",
                    label: "GPS Navigation Systems",
                }
            }

            if (spec?.name === 'Wifi') {
                return {
                    id: 2,
                    imgSrc: "assets/img/icons/extraService2.png",
                    // imgSrc: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/medias?key=${spec?.icon?.key}&width=${100}` || "assets/img/icons/extraService2.png",
                    label: "Wi-Fi Hotspot",
                }
            }

            if (spec?.name === 'Road Assistance') {
                return {
                    id: 3,
                    imgSrc: "assets/img/icons/extraService5.png",
                    // imgSrc: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/medias?key=${spec?.icon?.key}&width=${100}` || "assets/img/icons/extraService5.png",
                    label: "Roadside Assistance",
                }
            }

            if (spec?.name === 'Radio') {
                return {
                    id: 4,
                    imgSrc: "assets/img/icons/extraService6.png",
                    // imgSrc: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/medias?key=${spec?.icon?.key}&width=${100}` || "assets/img/icons/extraService6.png",
                    label: "Satellite Radio",
                }
            }

            if (spec?.name === 'Child Safety Seats') {
                return {
                    id: 5,
                    imgSrc: "assets/img/icons/extraService3.png",
                    // imgSrc: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/medias?key=${spec?.icon?.key}&width=${100}` || "assets/img/icons/extraService3.png",
                    label: "Child Safety Seats",
                }
            }

            if (spec?.name === 'Air Conditioning') {
                return {
                    id: 6,
                    imgSrc: "assets/img/icons/speci8.png",
                    // imgSrc: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/medias?key=${spec?.icon?.key}&width=${100}` || "assets/img/icons/speci8.png",
                    label: "Air Conditioning",
                }
            }
        });

        return specs;
    }

    const getCarSpecs = (carDetails: any) => {
        const specs = [
            // {
            //     id: 8,
            //     imgSrc: "assets/img/icons/speci8.png",
            //     label: "AC",
            //     value: "Air Condition",
            // },

            // {
            //     id: 6,
            //     imgSrc: "assets/img/icons/speci6.png",
            //     label: "Drivetrain",
            //     value: "Front Wheel",
            // },
            // {
            //     id: 9,
            //     imgSrc: "assets/img/icons/speci9.png",
            //     label: "VIN",
            //     value: "45456444",
            // },
        ];

        if (carDetails?.transmissionType) specs.push({
            id: 1,
            imgSrc: "assets/img/icons/speci3.png",
            label: "Transmission",
            value: carDetails?.transmissionType?.name,
        },)

        if (carDetails?.fuelType) specs.push({
            id: 2,
            imgSrc: "assets/img/icons/speci4.png",
            label: "Fuel Type",
            value: carDetails?.fuelType?.name,
        },)

        if (carDetails?.door) specs.push({
            id: 3,
            imgSrc: "assets/img/icons/speci10.png",
            label: "Door",
            value: `${carDetails?.door} Doors`,
        },)

        if (carDetails?.make) specs.push({
            id: 4,
            imgSrc: "assets/img/icons/speci2.png",
            label: "Make",
            value: carDetails?.make?.name,
        },)

        if (carDetails?.brakeType) specs.push({
            id: 5,
            imgSrc: "assets/img/icons/speci11.png",
            label: "Brake",
            value: carDetails?.brakeType?.name,
        },)

        if (carDetails?.engine) specs.push({
            id: 6,
            imgSrc: "assets/img/icons/speci12.png",
            label: "Engine (Hp)",
            value: carDetails?.engine,
        },)

        if (carDetails?.mileage) specs.push({
            id: 7,
            imgSrc: "assets/img/icons/speci5.png",
            label: "Mileage",
            value: `${carDetails?.mileage} Km`,
        },)

        if (carDetails?.mileage) specs.push({
            id: 8,
            imgSrc: "assets/img/icons/speci7.png",
            label: "Year",
            value: "2018",
        },)

        if (carDetails?.carCategory) specs.push({
            id: 9,
            imgSrc: "assets/img/icons/speci1.png",
            label: "Body",
            value: carDetails?.carCategory?.name,
        },)

        return specs;
    };

    const printSection = (sectionId: string) => {
        const element = document.getElementById(sectionId);
        if (element) {
            // Safe to use `element` here
            const printContent = element.innerHTML;
            const originalContent = document.body.innerHTML;

            document.body.innerHTML = printContent;   // Replace the body's HTML with the section's HTML
            window.print();
            document.body.innerHTML = originalContent; // Restore original content
        }

    }

    const isDateRangeWithin = (selectedStart: string, selectedEnd: string, rangeStart: string, rangeEnd: string) => {
        // Convert all date strings (YYYY-MM-DD) to Date objects
        const selectedStartDate = new Date(selectedStart);
        const selectedEndDate = new Date(selectedEnd);
        const rangeStartDate = new Date(rangeStart);
        const rangeEndDate = new Date(rangeEnd);

        // Check if selected range is within or equal to the defined range
        return (
            selectedStartDate >= rangeStartDate && // selected start date is on or after range start date
            selectedEndDate <= rangeEndDate        // selected end date is on or before range end date
        );
    }

    return { toTitleCase, getStatusClass, getCarExtraServices, getCarSpecs, printSection, isDateRangeWithin }
}

export default useHelperFuncs