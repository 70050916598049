import React, { useState } from "react";
import { IoMdAdd } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";

const CarFeatures = ({ formik }: any) => {
  const [newFeature, setNewFeature] = useState(""); // Manage the current feature input
  const [inputVisible, setInputVisible] = useState(false); // Manage input visibility
  const [error, setError] = useState<string | null>(null); // Manage validation errors
  const [typingTimeout, setTypingTimeout] = useState<number | undefined>(); // Manage the debounce timer

  const handleFeatureInputChange = (value: string) => {
    setNewFeature(value);

    // Clear the previous timeout if the user is still typing
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    // Set a new timeout to save the feature after a delay
    const timeout = setTimeout(() => {
      if (value.trim() === "") {
        setError("Please enter a valid feature.");
      } else {
        // Add the feature to formik's features array
        formik.setFieldValue("features", [...formik.values.features, value]);
        setNewFeature(""); // Clear the input field
        setInputVisible(false); // Hide the input field
        setError(null); // Clear any error message
      }
    }, 3000); // Wait 1 second after the user stops typing

    setTypingTimeout(timeout as unknown as number);
  };

  const handleRemoveFeature = (index: number) => {
    const updatedFeatures = formik.values.features.filter(
      (_: any, i: number) => i !== index
    );
    formik.setFieldValue("features", updatedFeatures);
  };

  return (
    <>
      <p>Car Features</p>
      <div className="d-flex align-items-center over-mt">
        <div className="border-line"></div>
        <div className={`exc-border`}></div>
      </div>

      {/* Display all added features */}
      {formik.values.features.map((feature: string, index: number) => (
        <div key={index} className="features_inputs_container">
          <input type="text" value={feature} className="feature_input" readOnly />
          <RxCross2
            onClick={() => handleRemoveFeature(index)}
            className="cursor-pointer"
          />
        </div>
      ))}

      {/* New feature input box - only shown when inputVisible is true */}
      {inputVisible && (
        <div className="features_inputs_container">
          <input
            type="text"
            value={newFeature}
            onChange={(e) => handleFeatureInputChange(e.target.value)}
            className="feature_input"
            placeholder="Enter feature"
          />
          <RxCross2
            onClick={() => {
                setNewFeature(""); // Clear input field
                setInputVisible(false); // Hide the input box
              }} // Clear input if user decides to cancel
            className="cursor-pointer"
          />
        </div>
      )}

      {/* Add Feature Button */}
      <div
        className="d-flex align-items-center gap-2 py-3 cursor-pointer"
        onClick={() => setInputVisible(true)} // Show the input box
      >
        <IoMdAdd className="fs-4" />
        <p>Add Feature</p>
      </div>

      {/* Error message */}
      {error && <p style={{ color: "red" }}>{error}</p>}
    </>
  );
};

export default CarFeatures;




// import React, { useState } from "react";
// import { IoMdAdd } from "react-icons/io";
// import { RxCross2 } from "react-icons/rx";

// const CarFeatures = ({ formik }: any) => {
//   const [newFeature, setNewFeature] = useState(""); // Manage the current feature input
//   const [inputVisible, setInputVisible] = useState(false); // Manage input visibility
//   const [error, setError] = useState<string | null>(null); // Manage validation errors

//   const handleAddFeatureClick = () => {
//     if (!inputVisible) {
//       // Show the input box when +Add Feature is clicked for the first time
//       setInputVisible(true);
//     } else {
//       // Validate if new feature is non-empty
//       if (newFeature.trim() === "") {
//         setError("Please enter a valid feature.");
//       } else {
//         // Add the feature to formik's features array
//         formik.setFieldValue("features", [...formik.values.features, newFeature]);
//         setNewFeature(""); // Clear the input field
//         setError(null); // Clear any error message
//       }
//     }
//   };

//   const handleRemoveFeature = (index: number) => {
//     // Remove the feature from formik's array by index
//     const updatedFeatures = formik.values.features.filter(
//       (_: any, i: number) => i !== index
//     );
//     formik.setFieldValue("features", updatedFeatures);
//   };

//   return (
//     <>
//       <p>Car Features</p>
//       <div className="d-flex align-items-center over-mt">
//         <div className="border-line"></div>
//         <div className={`exc-border`}></div>
//       </div>

//       {/* Display all added features */}
//       {formik.values.features.map((feature: string, index: number) => (
//         <div key={index} className="features_inputs_container">
//           <input type="text" value={feature} className="feature_input" readOnly />
//           <RxCross2
//             onClick={() => handleRemoveFeature(index)}
//             className="cursor-pointer"
//           />
//         </div>
//       ))}

//       {/* New feature input box - only shown when inputVisible is true */}
//       {inputVisible && (
//         <div className="features_inputs_container">
//           <input
//             type="text"
//             value={newFeature}
//             onChange={(e) => setNewFeature(e.target.value)}
//             className="feature_input"
//             placeholder="Enter feature"
//           />
//           <RxCross2
//             onClick={() => {
//                 setNewFeature(""); // Clear input field
//                 setInputVisible(false); // Hide the input box
//               }} // Clear input if user decides to cancel
//             className="cursor-pointer"
//           />
//         </div>
//       )}

//       {/* Add Feature Button */}
//       <div
//         className="d-flex align-items-center gap-2 py-3 cursor-pointer"
//         onClick={handleAddFeatureClick}
//       >
//         <IoMdAdd className="fs-4" />
//         <p>Add Feature</p>
//       </div>

//       {/* Error message */}
//       {error && <p style={{ color: "red" }}>{error}</p>}
//     </>
//   );
// };

// export default CarFeatures;




// import React, { useState } from "react";
// import { IoMdAdd } from "react-icons/io";
// import { RxCross2 } from "react-icons/rx";

// const CarFeatures = ({ formik }:any) => {

//     const [inputbox, setInputBox] = useState(false);

//     const openInputBox = () =>{
//         setInputBox(true)
//     }

//     const closeInputBox = () => {
//         setInputBox(false)
//     }

//     return (
//         <>
//             <p>Car Features</p>
//             <div className="d-flex align-items-center over-mt">
//                 <div className="border-line"></div>
//                 <div className={`exc-border`}></div>
//             </div>
//             {inputbox && <div className="features_inputs_container"><input type="text" className="feature_input"/> <RxCross2 onClick={closeInputBox}/></div>}
            
//             <div className="d-flex align-items-center gap-2 py-3 cursor-pointer" onClick={openInputBox}>
//                 <IoMdAdd className="fs-4" />
//                 <p>Add Feature</p>
//             </div>
//         </>
//     )

// }


// export default CarFeatures;

// import React, { useState } from "react";
// import { IoMdAdd } from "react-icons/io";
// import { RxCross2 } from "react-icons/rx";

// const CarFeatures = ({ formik }: any) => {
//   const [newFeature, setNewFeature] = useState(""); // To manage the current feature input
//   const [error, setError] = useState<string | null>(null); // To manage validation errors

//   const handleAddFeatureClick = () => {
//     // Validate if new feature is non-empty
//     if (newFeature.trim() === "") {
//       setError("Please enter a valid feature.");
//     } else {
//       // Add the feature to formik's features array
//       formik.setFieldValue("features", [...formik.values.features, newFeature]);
//       setNewFeature(""); // Clear the input field
//       setError(null); // Clear any error message
//     }
//   };

//   const handleRemoveFeature = (index: number) => {
//     // Remove the feature from formik's array by index
//     const updatedFeatures = formik.values.features.filter(
//       (_: any, i: number) => i !== index
//     );
//     formik.setFieldValue("features", updatedFeatures);
//   };

//   return (
//     <>
//       <p>Car Features</p>
//       <div className="d-flex align-items-center over-mt">
//         <div className="border-line"></div>
//         <div className={`exc-border`}></div>
//       </div>

//       {/* Display all added features */}
//       {formik.values.features.map((feature: string, index: number) => (
//         <div key={index} className="features_inputs_container">
//           <input type="text" value={feature} className="feature_input" readOnly />
//           <RxCross2
//             onClick={() => handleRemoveFeature(index)}
//             className="cursor-pointer"
//           />
//         </div>
//       ))}

//       {/* New feature input box */}
//       <div className="features_inputs_container">
//         <input
//           type="text"
//           value={newFeature}
//           onChange={(e) => setNewFeature(e.target.value)}
//           className="feature_input"
//           placeholder="Enter feature"
//         />
//         <RxCross2
//           onClick={() => setNewFeature("")} // Clear input if user decides to cancel
//           className="cursor-pointer"
//         />
//       </div>

//       {/* Add Feature Button */}
//       <div
//         className="d-flex align-items-center gap-2 py-3 cursor-pointer"
//         onClick={handleAddFeatureClick}
//       >
//         <IoMdAdd className="fs-4" />
//         <p>Add Feature</p>
//       </div>

//       {/* Error message */}
//       {error && <p style={{ color: "red" }}>{error}</p>}
//     </>
//   );
// };

// export default CarFeatures;

