import React from "react";

interface AuthButtonProps {
  label: string;
  iconClass: string;
  
  className?: string; 
  onClick?: () => void; 
}

const AuthButton: React.FC<AuthButtonProps> = ({ label, iconClass, className, onClick }) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <div>
      <div className={`nav-link ${className} cursor-pointer`} onClick={handleClick}>
        <span>
          <i className={iconClass} />
        </span>
        {label}
      </div>
    </div>
  );
};

export default AuthButton;
