import React from 'react';
import { Link } from 'react-router-dom';
import ModalWrapper from './ModalWrapper';
import TextAreaField from '../../../common/ui_components/TextAreaField';

const CancelBooking = () => {
  return (
    <ModalWrapper id="cancel_ride" title="Cancel Reason" size="modal-lg">
      <form action="#">
        <div className="modal-item">
          <div className="modal-form-group">
            <TextAreaField
              label="Reason"
              placeholder="Enter Reason"
              rows={4}
              required={true}
            />
          </div>
        </div>
        <div className="modal-btn modal-btn-sm text-end">
          <Link to="#" data-bs-dismiss="modal" className="btn btn-secondary" >
            Cancel
          </Link>
          <Link to="#" data-bs-dismiss="modal" className="btn btn-primary" >
            Submit
          </Link>
        </div>
      </form>
    </ModalWrapper>
  );
};

export default CancelBooking;
