import React from "react";
import { servicesData } from "../../../core/data/json/common_static_data";
import ServiceCard from "../../common/ui_components/cards/ServiceCard";


const SevicesOfferedSection = () => {

  return (
    <section className="section popular-services popular-explore" style={{ paddingLeft: '50px', paddingRight: '50px' }} >
      <div className="card_container">
        <div className="section-heading" data-aos="fade-down">
          <h2>Services offered by Us</h2>
          <p>Enjoy the convenience of free car rentals, providing flexible transportation options at no cost. </p>
        </div>
        <div className="tab-content">
          <div className="tab-pane active" id="Carmazda">
            <div className="row">
              {servicesData.map((service, index) => (
                <ServiceCard
                  key={index}
                  service={service}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SevicesOfferedSection;
