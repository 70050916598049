import React, { useEffect } from "react";
import { LuCopyPlus } from "react-icons/lu";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "@mui/material";
import DropdownOptions from "./OptionDropdown";
import { FormikHelpers, FormikValues, useFormik } from "formik";
import * as Yup from "yup";
import { doGetApiCall, doPutApiCall } from "../../../utils/apiconfig";
import { getCookie, setCookie } from "../../../utils/cookieUtils";
import { useSelector, useDispatch } from "react-redux";
import { set_login_data } from "../../../redux/reducers/authReducer";
import FileUploader from "../../common/ui_components/FileUploader";
import { all_routes } from "../../router/all_routes";
import { closeNotification, openNotification } from "../../../redux/reducers/notificationReducer";



const label = { inputProps: { "aria-label": "Checkbox demo" } };

const { countryOptions } = DropdownOptions();

const BillingForm = ({ setStepperState }: any) => {
  const dispatch = useDispatch();
  const routes = all_routes;

  const UserDetails = useSelector((state: any) => state?.authReducer?.login_data);
  const login_data = useSelector((state: any) => state?.authReducer?.login_data);

  const UpdateCarData = async () => {
    const carId = getCookie('CarId');
    const formData = new FormData();
    formData.append("carId", carId);
    formData.append("stepCompleted", "3");
    const data = {
      url: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/cars/${carId}`,
      bodyData: formData,
    };
    await doPutApiCall(data)
      .then(async (res: any) => {
        if (!res.error) {
          const notificationObj = {
            open: true,
            message: 'Billing Information saved successfully !!',
            alertType: 'success',
            subText: '',
            borderClass: 'success',
          }
          dispatch(openNotification(notificationObj));
          setTimeout(() => dispatch(closeNotification()), 4000);
          setStepperState((prev: any) => prev + 1);
          setCookie("stepperstep", 3)
        } else {
          const notificationObj = {
            open: true,
            message: res.message,
            alertType: 'warning',
            subText: '',
            borderClass: 'warning',
          }
          dispatch(openNotification(notificationObj));
          setTimeout(() => dispatch(closeNotification()), 4000);
          console.error(res.error || "Save failed");
        }
      })
      .catch((err) => {
        console.error(err, '<<-- err');
      })
  };

  const handleSubmit = async (values: FormikValues, formikHelpers: FormikHelpers<any>) => {
    console.log("HandleSubmit Triggered");
    console.log('Form values ----->', values, 'Form errors ------->', formik.errors);

    try {
      const { setSubmitting, setFieldError } = formikHelpers;

      const formData = new FormData();
      formData.append('first_name', values.firstName);
      formData.append('last_name', values.lastName);
      formData.append('phoneNo', values.phone);
      formData.append('address[city]', values.city);
      formData.append('address[country]', values.country);
      formData.append('address[pincode]', values.pincode);
      if (login_data?.role === 'carOwner') formData.append('document', values.document);
      if (login_data?.role === 'flexiDriver' || login_data?.role === 'professionalDriver') {
        formData.append('license', values.document);
        if (values?.licenseField) formData.append('licenseField', values.licenseField);
        else setFieldError('licenseField', 'Driving License Number is required');
      }
      formData.append('termsandcondition', values.acceptTerms)
      formData.append('additionalInfo', values.additionalDescription)

      console.log('Submitting formData ----->', formData);

      await doPutApiCall({ url: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/users/update`, bodyData: formData }).then(async (res: any) => {
        if (!res.error) UpdateCarData();
        else console.error(res.error || "Save failed");
      }).catch((err) => {
        alert("something went wrong");
        console.error(err, '<<-- err');
      }).finally(() => setSubmitting(false));
    } catch (error) {
      console.log("Error during submission ---->", error);
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      country: null,
      city: "",
      pincode: "",
      email: "",
      phone: "",
      additionalDescription: "",
      licenseField: "",
      document: [],
      acceptTerms: false,
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
      country: Yup.string().required("Country is required"),
      city: Yup.string().required("City is required"),
      pincode: Yup.string().required("Pincode is required"),
      email: Yup.string().email("Invalid email address").required("Email is required"),
      phone: Yup.string().required("Phone Number is required"),
      additionalDescription: Yup.string(),
      // licenseField: Yup.string().required("Driving License Number is required"),
      document: Yup.mixed()
        .required("A file is required"),
      // .test(
      //   "fileSize",
      //   "File size is too large (Max 4MB)",
      //   (value: any) => !value || (value && value.size <= 4 * 1024 * 1024)
      // )
      // .test(
      //   "fileType",
      //   "Unsupported File Format",
      //   (value: any) =>
      //     !value ||
      //     (value && ["application/pdf", "image/jpeg", "image/png", "image/jpg",].includes(value.type))),
      acceptTerms: Yup.bool().oneOf([true], "You must accept the terms and conditions"),
    }),
    onSubmit: handleSubmit
  });

  const fetchUserData = async () => {
    const userDataRequest = { url: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/users/details` };

    try {
      const response: any = await doGetApiCall(userDataRequest);
      if (!response.error) {
        dispatch(set_login_data(response.result));
        setCookie("userdata", response.result, 7);
        formik.setValues({
          firstName: response?.result?.first_name || "",
          lastName: response?.result?.last_name || "",
          phone: response?.result?.phoneNo || "",
          email: response?.result?.email || "",
          country: response?.result?.address?.country || "",
          city: response?.result?.address?.city || "",
          pincode: response?.result?.address?.pincode || "",
          additionalDescription: response?.result?.additionalInfo || "",
          licenseField: response?.result?.licenseField || "",
          document: response?.document,
          acceptTerms: response?.result?.termsandcondition,
        });
      }
    } catch (err) {
      console.error("Error fetching user data:", err);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <form onSubmit={formik.handleSubmit} className="form-container">
      <div className="main-form-container">
        <div className="form-details shadow-lg p-4">
          <div className="d-flex align-items-center gap-3 border-bottom pb-3">
            <LuCopyPlus />
            <p className={`fw-bold fs-4`}>Billing Info</p>
          </div>
          <div className="row row-cols-1 row-cols-md-2 py-2">
            <div className="input-block">
              <label className="form-label">First Name <span className="text-danger"> *</span></label>
              <input
                type="text"
                name="firstName"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
              />
              {formik.touched.firstName && formik.errors.firstName ? (
                <div className="text-danger font-short error">
                  {formik.errors.firstName}
                </div>
              ) : null}
            </div>
            <div className="input-block">
              <label className="form-label">Last Name <span className="text-danger"> *</span></label>
              <input
                type="text"
                name="lastName"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
              />
              {formik.touched.lastName && formik.errors.lastName ? (
                <div className="text-danger font-short error">
                  {formik.errors.lastName}
                </div>
              ) : null}
            </div>
          </div>
          <div className="row row-cols-1 row-cols-md-3 mb-2">
            <div className="input-block">
              <label className="form-label">Country <span className="text-danger"> *</span></label>
              <Dropdown
                value={countryOptions?.find((country) => country?.name === formik.values.country)}
                onChange={(e) => formik.setFieldValue("country", e.value.name)}
                options={countryOptions}
                optionLabel="name"
                className="w-100"
              />
              {formik.touched.country && formik.errors.country ? (
                <div className="text-danger error font-short mt-3">
                  {formik.errors.country}
                </div>
              ) : null}
            </div>
            <div className="input-block">
              <label className="form-label">City <span className="text-danger"> *</span></label>
              <input
                type="text"
                name="city"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.city}
              />
              {formik.touched.city && formik.errors.city ? (
                <div className="text-danger error font-short mt-3">
                  {formik.errors.city}
                </div>
              ) : null}
            </div>
            <div className="input-block">
              <label className="form-label">Pincode <span className="text-danger"> *</span></label>
              <input
                type="text"
                name="pincode"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.pincode}
              />
              {formik.touched.pincode && formik.errors.pincode ? (
                <div className="text-danger error font-short mt-3">
                  {formik.errors.pincode}
                </div>
              ) : null}
            </div>
          </div>
          <div className="row row-cols-1 row-cols-md-2 py-2">
            <div className="input-block">
              <label className="form-label">Email Address <span className="text-danger"> *</span></label>
              <div className="d-flex flex-column">
                <div className="d-flex gap-2 h-fit-cont">
                  <input
                    type="text"
                    name="email"
                    className="form-control"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {UserDetails?.emailVerified === false &&
                    <button type="button" className="py-2 px-3 text-white d-flex justify-content-center align-items-center rounded-2 h-fit-cont" style={{ backgroundColor: '#0076D7', border: 'none', outline: 'none' }}>
                      Verify
                    </button>}
                </div>
                {formik.touched.email && formik.errors.email ? (
                  <div className="text-danger error font-short">
                    {formik.errors.email}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="input-block">
              <label className="form-label">Phone Number <span className="text-danger"> *</span></label>
              <div className="d-flex flex-column">
                <div className="d-flex gap-2 h-fit-cont">
                  <input
                    type="text"
                    name="phone"
                    className="form-control"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone}
                  />
                  {UserDetails?.phoneVerified === false &&
                    <button type={`button`} className="py-2 px-3 text-white d-flex justify-content-center align-items-center rounded-2 h-fit-cont" style={{ backgroundColor: '#0076D7', border: 'none', outline: 'none' }}>
                      Verify
                    </button>}
                </div>
                {formik.touched.phone && formik.errors.phone ? (
                  <div className="text-danger font-short error">
                    {formik.errors.phone}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="input-block">
            <label className="form-label">Additional Description</label>
            <textarea
              name="additionalDescription"
              className="form-control"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.additionalDescription}
            />
          </div>

          {(login_data?.role === 'flexiDriver' || login_data?.role === 'professionalDriver') &&
            <div className="input-block">
              <label className="form-label">Driving License Number <span className="text-danger"> *</span></label>
              <input
                type="text"
                name="licenseField"
                className="form-control h-160"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.licenseField}
              />
              {formik.touched.licenseField &&
                formik.errors.licenseField ? (
                <div className="text-danger error font-short">
                  {formik.errors.licenseField}
                </div>
              ) : null}
            </div>
          }

          <div className="col-md-12">
            <FileUploader
              label="Upload Document"
              file={formik.values.document}
              // file={(file:any) => formik.setFieldValue('document', file)}
              onChange={(e: any) => formik.setFieldValue('document', e?.target?.files?.[0])}
              required
            />
            {formik.touched.document && formik.errors.document && (
              <div className="error text-danger font-short" style={{ margin: '0 0 5px 5px' }}>
                {formik.errors.document}
              </div>
            )}
          </div>
          <div className="d-flex gap-1 align-items-center">
            <div className="d-flex flex-column">
              <div className="d-flex align-items-center">
                <Checkbox
                  {...label}
                  name="acceptTerms"
                  checked={formik.values.acceptTerms}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <div className={`d-flex align-items-center`}>
                  <p>I have Read and Accept <a href={routes.termsConditions} target="_blank" rel="noopener noreferrer">Terms & Conditions</a></p>
                  <span className="text-danger">*</span>
                </div>
              </div>
            </div>
            {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
              <div className="text-danger error font-short">
                {formik.errors.acceptTerms}
              </div>
            ) : null}
          </div>
        </div>
        <div className="d-flex justify-content-center justify-content-md-end align-items-center w-100 gap-3">
          <button
            onClick={() => setStepperState((prev: any) => prev - 1)}
            type={`button`}
            className="bg-black text-white py-2 px-5 d-flex justify-content-center align-items-center rounded-2"
          >
            Back
          </button>
          <button
            type={`submit`}
            className="bg-black text-white py-2 px-3 d-flex justify-content-center align-items-center rounded-2"
          >
            Confirm and Pay Now
          </button>
        </div>
      </div>
    </form>
  );
};

export default BillingForm;
