import React from "react";
import DriverBannerSection from "./DriverLandingBanner";


export const DriverLandingPage = () => {
  return (
    <>
      <DriverBannerSection />
    </>
  );
}
