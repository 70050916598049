import React from "react";

const InvoiceTable = ({ items }: any) => {
  return (
    <div className="invoice-table-wrap">
      <div className="row">
        <div className="col-md-12">
          <div className="table-responsive">
            <table className="table table-center table-hover">
              <thead className="thead-light">
                <tr>
                  <th>Model Name</th>
                  <th>Registration No.</th>
                  <th>Discount (%)</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{items?.modelName}</td>
                  <td>{items?.registration}</td>
                  <td>0%</td>
                  <td>{`$${items?.rentAmount}`}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceTable;
