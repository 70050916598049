import React from "react";

const UserStateSwitcher = ({ userStates, activeState, setActiveState }: any) => (
  <div className="blue-bg d-flex justify-content-between gap-1 align-items-center p-1 mb-3 rounded-2 perc-corn">
    {userStates.map((item: any, index:any) => (
      <div
        key={index}
        className={`${
          item.id === activeState ? "bg-white" : "bg-transparent"
        } text-black padding-change d-flex justify-content-center align-items-center rounded-2 w-50 cursor-pointer animation-bg`}
        onClick={() => setActiveState(item.id)} 
      >
        <p>{item.label}</p> 
      </div>
    ))}
  </div>
);

export default UserStateSwitcher;
