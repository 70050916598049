import React, { useState } from "react";
import BillingInfoHeader from "./BillingInfoHeader";
import BookingFormSection from "../../common/ui_components/BookingFormSection";
import DropdownFilter from "../../common/ui_components/DropDown";
import TextAreaField from "../../common/ui_components/TextAreaField";
import FileUploader from "../../common/ui_components/FileUploader";
import Button from "../../common/ui_components/buttons/blueBtn";
import InputField from "../../common/ui_components/InputFields/InputField";

const BookingDetails = ({ onNext }: any) => {
  const [selectedPersons, setSelectedPersons] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);


  const navigatePath = () => {
    onNext(); 
  };
  return (
    <div className="col-lg-8">
      <div className="booking-information-main">
        <form>
          <div className="booking-information-card">
            <BillingInfoHeader />
            <div className="booking-info-body">
              <div className="row">
                <div className="col-md-6">
                  <InputField
                    label="First Name"
                    placeholder="Enter First Name"
                    required={true}
                  />
                </div>
                <div className="col-md-6">
                  <InputField label="Last Name" placeholder="Enter Last Name" required={true}/>
                </div>
                <div className="col-md-6">
                  <BookingFormSection>
                    <label className="form-label">
                      No of Persons <span className="text-danger"> *</span>
                    </label>
                    <DropdownFilter
                      value={selectedPersons}
                      onChange={setSelectedPersons}
                      // options={persons}
                      placeholder="2 Adults, 1 Child"
                      className="w-100"
                    />
                  </BookingFormSection>
                </div>
                <div className="col-md-12">
                  <InputField
                    label="Street Address"
                    placeholder="Enter Address"
                    required={true}
                  />
                </div>
                <div className="col-md-4">
                  <BookingFormSection>
                    <label className="form-label">
                      Country <span className="text-danger"> *</span>
                    </label>
                    <DropdownFilter
                      value={selectedCountry}
                      onChange={setSelectedCountry}
                      // options={country}
                      placeholder="Country"
                      className="w-100"
                    />
                  </BookingFormSection>
                </div>
                <div className="col-md-4">
                  <InputField label="Enter City" placeholder="City" required={true}/>
                </div>
                <div className="col-md-4">
                  <InputField label="Pincode" placeholder="Enter Pincode" required={true}/>
                </div>
                <div className="col-md-6">
                  <InputField label="Email Address" placeholder="Enter Email" required={true}/>
                </div>
                <div className="col-md-6">
                  <InputField
                    label="Phone Number"
                    placeholder="Enter Phone Number"
                    required={true}
                  />
                </div>
                <div className="col-md-12">
                  <TextAreaField
                    label="Additional Information"
                    placeholder="Enter Additional Information"
                    rows={5}
                    required={true}
                  />
                </div>
                <div className="col-md-12">
                  <InputField
                    label="Driving Licence Number"
                    placeholder="Enter Driving Licence Number"
                    required={true}
                  />
                </div>
                <div className="col-md-12">
                  <FileUploader label="Upload Document" />
                </div>
              </div>
            </div>
          </div>
          <div className="booking-info-btns d-flex justify-content-end">
            <Button
              onClick={navigatePath}
              label="Confirm & Pay Now"
              className="btn btn-primary continue-book-btn"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default BookingDetails;
