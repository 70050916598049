// FeatureCard.js
import React from "react";
import ImageWithBasePath from "../../../../core/data/img/ImageWithBasePath";

const FeatureCard = ({ feature, index }: any) => {
  return (
    <div
      className={`col-lg-3 col-md-6 col-12 d-flex card-item ${index % 4 === 3 ? 'no-border' : ''}`}
      data-aos="fade-down"
      style={{ padding: '0', margin: '0 0 20px 0' }}
    >
      <div className="card flex-fill">
        <div className="card-body" style={{display:'flex'}}>
          <div className="choose-img">
            <ImageWithBasePath src={feature.imgSrc} alt="" />
          </div>
          <div className="choose-content">
            <h4>{feature.title}</h4>
            <p>{feature.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureCard;
