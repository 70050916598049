import React from 'react'
import { Link } from 'react-router-dom'
import { all_routes } from '../../../router/all_routes';
import { CornerDownLeft } from 'react-feather';

const WrapperModal = ({title,children,subtitle}:any) => {
    const routes = all_routes;

    return (
        <div className="loginbox">
            <div className="login-auth">
                <div className="login-auth-wrap">
                    <div className="sign-group">
                        <Link to={routes.MainLanding} className="btn sign-up">
                            <span>
                                <CornerDownLeft />
                            </span>{" "}
                            Back To Home
                        </Link>
                    </div>
                    {title && <h1>{title}</h1> }
                    {subtitle && <p className="account-subtitle"> {subtitle} </p>}
                    {children}
                </div>
            </div>
        </div>
    )
}

export default WrapperModal


// import React from 'react';
// import { Link } from 'react-router-dom';
// import { CornerDownLeft } from 'react-feather';
// import SocialLoginComponent from '../SocialLoginComponent';
// import { all_routes } from '../../../router/all_routes';

// const routes = all_routes;

// const ModalWrapper = ({ title, subtitle, children, showSocialLogin = true }: any) => (
//     <div className="loginbox">
//         <div className="login-auth">
//             <div className="login-auth-wrap">
//                 <div className="sign-group">
//                     <Link to={routes.MainLanding} className="btn sign-up">
//                         <span>
//                             <CornerDownLeft />
//                         </span>
//                         Back To Home
//                     </Link>
//                 </div>
//                 <h1>{title}</h1>
//                 <p className="account-subtitle">{subtitle}</p>
//                 {children}
//                 {showSocialLogin && <SocialLoginComponent />}
//             </div>
//         </div>
//     </div>
// );

// export default ModalWrapper;
