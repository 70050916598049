export const locations = [
    { value: "madrid", label: "Madrid" },
    { value: "barcelona", label: "Barcelona" },
    { value: "valencia", label: "Valencia" },
    { value: "seville", label: "Seville" },
    { value: "malaga", label: "Malaga" },
    { value: "bilbao", label: "Bilbao" },
    { value: "santiago", label: "Santiago de Compostela" },
    { value: "zaragoza", label: "Zaragoza" },
    { value: "granada", label: "Granada" },
    { value: "alicante", label: "Alicante" },
    { value: "sansebastian", label: "San Sebastián" },
    { value: "pamplona", label: "Pamplona" },
    { value: "salamanca", label: "Salamanca" },
    { value: "toledo", label: "Toledo" },
    { value: "murcia", label: "Murcia" },
    { value: "tarragona", label: "Tarragona" },
    { value: "burgos", label: "Burgos" },
    { value: "córdoba", label: "Córdoba" },
    { value: "oviedo", label: "Oviedo" },
    { value: "mallorca", label: "Palma de Mallorca" },
    { value: "ibiza", label: "Ibiza" },
    { value: "menorca", label: "Menorca" },
    { value: "ceuta", label: "Ceuta" },
    { value: "melilla", label: "Melilla" },
    { value: "la_coruna", label: "A Coruña" },
    { value: "cadiz", label: "Cádiz" },
    { value: "fuerteventura", label: "Fuerteventura" },
    { value: "tenerife", label: "Tenerife" },
    { value: "gijon", label: "Gijón" },
    { value: "vigo", label: "Vigo" },
    { value: "huelva", label: "Huelva" },
    { value: "jaen", label: "Jaén" },
    { value: "alcala", label: "Alcalá de Henares" },
    { value: "eibar", label: "Eibar" },
    { value: "la_laguna", label: "San Cristóbal de La Laguna" },
    { value: "lloret_de_mar", label: "Lloret de Mar" },
    { value: "paseo_de_gracia", label: "Paseo de Gracia, Barcelona" },
    { value: "ronda", label: "Ronda" },
    { value: "soria", label: "Soria" },
    { value: "segovia", label: "Segovia" },
    { value: "aviles", label: "Avilés" },
    { value: "cuenca", label: "Cuenca" },
    { value: "lugo", label: "Lugo" },
  ];
  