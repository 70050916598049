import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import ExtraServicesRelocation from "./ExtraServicesRelocation";
import SpecializationField from "./SpecializationField";
import RelocatingCarDetails from "./RelocatingCarDetails";
import CarFeatures from "./RelocationCarFeatures";
import FileUpload from "./FileUpload";
import { doGetApiCall, doPutApiCall } from "../../../utils/apiconfig";
import { closeNotification, openNotification } from "../../../redux/reducers/notificationReducer";
import { useDispatch } from 'react-redux';
import { getCookie, setCookie } from "../../../utils/cookieUtils";



const FILE_SIZE = 4 * 1024 * 1024; // 4MB in bytes
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png', 'image/webp'];

const validationSchema = Yup.object().shape({
  modalName: Yup.string().required("Car Name is required"),
  carType: Yup.string().required("Car Type is required"),
  registration: Yup.string().required("Car Registration Number is required"),
  year: Yup.string().required("Car Make is required"),
  seatingCapacity: Yup.string().required("No of Persons is required"),
  description: Yup.string().required("Description is required"),
  transmissionType: Yup.string().required("Transmission is required"),
  fuelType: Yup.string().required("Fuel Type is required"),
  door: Yup.string().required("Door is required"),
  // ac: Yup.string().required("AC is required"),
  makeId: Yup.string().required("Make is required"),
  brake: Yup.string().required("Brake is required"),
  engine: Yup.string().required("Engine is required"),
  mileage: Yup.string().required("Mileage is required"),
  // images: Yup.array().min(1, "At least one image is required").max(5, "You can upload up to 5 images")
  //   .of(Yup.mixed()
  //     .required("File is required")
  //     .test("fileSize", "File size is too large (Max 4MB)", (value: any) => !value || (value.size <= 4 * 1024 * 1024))
  //     .test("fileType", "Unsupported File Format", (value: any) => !value || ['image/jpg', 'image/jpeg', 'image/gif', 'image/png', 'image/webp'].includes(value.type))
  //   ),

  images: Yup.array()
    .required('File is required')  // Ensures the array is present
    .min(1, 'At least one image is required')  // At least one file required
    .max(5, 'You can upload up to 5 images')  // Maximum 5 files allowed
    .of(
      Yup.mixed()
        .test('fileSize', 'File size is too large (Max 4MB)', (file: any) => { return file && file.size <= FILE_SIZE; })  // Check file size
        .test('fileFormat', 'Unsupported File Format (only images allowed)', (file: any) => { return file && SUPPORTED_FORMATS.includes(file.type || file.mimetype); })  // Check file format
    )
});

const DetailsForm = ({ setStepperState }: any) => {
  const dispatch = useDispatch();
  const carId = getCookie('CarId');

  const [imagePreviews, setImagePreviews] = useState<string[]>([]);

  useEffect(() => {
    fetchCarData()
  }, [])

  const fetchCarData = async () => {
    const data = {
      url: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/cars/${carId}`,
    };
    await doGetApiCall(data)
      .then((res: any) => {
        const specificationsIds = res.result?.specifications.map((spec: any) => spec._id);
        const carCategoryId = res.result?.carCategory ? res.result.carCategory._id : "";
        const transmissionId = res.result?.transmissionType ? res.result.transmissionType._id : "";
        const fuelId = res.result?.fuelType ? res.result.fuelType._id : "";
        const makeId = res.result?.make ? res.result.make._id : "";
        const brakeId = res.result?.brakeType ? res.result.brakeType._id : "";
        if (!res.error) {
          if (res?.result?.images) {
            setImagePreviews(res?.result?.images.map((file: any) => `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/medias?key=${file?.key}&width=${100}`));
          }
          formik.setValues({
            modalName: res.result.modelName || "",
            carType: carCategoryId,
            registration: res.result?.registration || "",
            year: res.result?.year || "",
            seatingCapacity: res.result?.seatingCapacity || "",
            description: res.result?.description || "",
            transmissionType: transmissionId,
            fuelType: fuelId,
            door: String(res.result.door) || "",
            makeId: makeId,
            brake: brakeId,
            engine: res.result.engine || "",
            mileage: res.result?.mileage || "",
            images: res.result?.images || [],
            specifications: specificationsIds || [],
            features: res.result.features || [],
            stepCompleted: res.result?.stepCompleted || ""
          });
        }
      })
      .catch((err) => {
        console.error(err);
      })
  }


  const handleSubmit = async (values: any, { setSubmitting, resetForm }: any) => {
    const formData = new FormData();
    formData.append("carId", carId);
    formData.append("modelName", values.modalName);
    formData.append("carCategory", values.carType);
    formData.append("registration", values.registration);
    formData.append("year", values.year);
    formData.append("seatingCapacity", values.seatingCapacity);
    formData.append("description", values.description);
    formData.append("transmissionType", values.transmissionType);
    formData.append("fuelType", values.fuelType);
    formData.append("door", values.door);
    formData.append("makeId", values.makeId);
    formData.append("brakeType", values.brake);
    formData.append("engine", values.engine);
    formData.append("mileage", values.mileage);
    formData.append("stepCompleted", "2",)
    formData.append("specifications", JSON.stringify(values.specifications));
    formData.append("features", JSON.stringify(values.features));
    if (values.images && values.images.length > 0) {
      values.images.forEach((file: any) => {
        formData.append("images", file);
      });
    }

    const data = {
      url: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/cars/${carId}`,
      bodyData: formData,
      // method: 'PUT'
    };

    // await doUploadMediaApiCall(data);
    await doPutApiCall(data)
      .then((res: any) => {
        if (!res.error) {
          setCookie("rentAmount", res.result.rentAmount)
          // setcar(res.result)
          const notificationObj = { open: true, message: res.message, alertType: 'success', subText: '', borderClass: 'success', }
          dispatch(openNotification(notificationObj));
          setTimeout(() => dispatch(closeNotification()), 4000);
          resetForm();
          setStepperState((prev: any) => prev + 1);
          setCookie("stepperstep", 2)
        } else {
          const notificationObj = { open: true, message: res.message, alertType: 'warning', subText: '', borderClass: 'warning' }
          dispatch(openNotification(notificationObj));
          setTimeout(() => dispatch(closeNotification()), 4000);
          console.error(res.error || "Save failed");
        }
      })
      .catch((err) => {
        alert("Something went wrong");
        console.error(err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      modalName: "",
      carType: "",
      registration: "",
      year: "",
      seatingCapacity: "",
      description: "",
      transmissionType: "",
      fuelType: "",
      door: "",
      makeId: "",
      brake: "",
      engine: "",
      mileage: "",
      images: null,
      specifications: [],
      features: [],
      stepCompleted: ""
    },
    validationSchema,
    onSubmit: handleSubmit
  });

  return (
    <form onSubmit={formik.handleSubmit} className="main-form-container">
      <div className="form-details shadow-lg p-4">
        <RelocatingCarDetails formik={formik} />
        <ExtraServicesRelocation formik={formik} />
        <SpecializationField formik={formik} />
        <CarFeatures formik={formik} />
        <p>Gallery</p>
        <div className="d-flex align-items-center over-mt">
          <div className="border-line"></div>
          <div className={`exc-border`}></div>
        </div>
        <FileUpload formik={formik} FILE_SIZE={FILE_SIZE} SUPPORTED_FORMATS={SUPPORTED_FORMATS} imagePreviews={imagePreviews} setImagePreviews={setImagePreviews} />
      </div>
      <div className="d-flex justify-content-center justify-content-md-end align-items-center w-100 gap-3">
        <button onClick={() => setStepperState((prev: any) => prev - 1)} className="bg-black text-white py-2 px-5 d-flex justify-content-center align-items-center rounded-2">
          Back
        </button>
        <button className="bg-black text-white py-2 px-5 d-flex justify-content-center align-items-center rounded-2" type="submit">
          Save & Next
        </button>
      </div>
    </form>
  );
};

export default DetailsForm;

