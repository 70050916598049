import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../common/ui_components/breadcrumbs";
import SearchSection from "../LandingPageForBoking/SearchSection";
import TabListSection from "./TabListSection";
import FilterSection from "./FilterSection";
import Pagination from "../../common/ui_components/Pagination";
import ListView from "./ListView";
import GridView from "./GridView";
import MapContainer from "../../listings/map";
import { data } from "../../../core/data/json/common_static_data";
import { useDispatch, useSelector } from "react-redux";
import { fetchCars } from "../../../redux/reducers/carReducer";
import { useLocation } from "react-router-dom";
import CircularIndeterminate from "../../common/ui_components/CircularProgress";
import dayjs from 'dayjs';



const Listinglist = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { searchdata } = location.state || {};

  const { loading, carList, totalCars } = useSelector((state: any) => state?.carReducer);

  const [currentView, setCurrentView] = useState("list");

  // Fetching car list
  useEffect(() => {
    console.log('searchdata in car listing page', searchdata);

    if (searchdata) {
      const formattedPickupDate = searchdata?.pickupDate ? dayjs(searchdata?.pickupDate).format('YYYY-MM-DD') : "";
      const formattedDropoffDate = searchdata?.dropoffDate ? dayjs(searchdata?.dropoffDate).format('YYYY-MM-DD') : "";
      // const formattedPickupDate = searchdata?.pickupDate ? new Date(searchdata.pickupDate).toISOString().split('T')[0] : "";
      // const formattedDropoffDate = searchdata?.dropoffDate ? new Date(searchdata.dropoffDate).toISOString().split("T")[0] : "";

      const queryParams = [
        `pickUpLatitude=${searchdata?.pickupLat}`,
        `pickUpLongitude=${searchdata?.pickupLng}`,
        `maxDistance=${100}`,
        searchdata?.dropoffLat ? `dropOffLatitude=${searchdata?.dropoffLat}` : "",
        searchdata?.dropoffLng ? `dropOffLongitude=${searchdata?.dropoffLng}` : "",
        formattedPickupDate ? `pickupDate=${formattedPickupDate}` : "",
        formattedDropoffDate ? `dropoffDate=${formattedDropoffDate}` : ""
      ].filter(Boolean).join("&");

      dispatch(fetchCars({ endpoint: `cars/nearby?${queryParams}` }));
    }

  }, [searchdata]);

  return (
    <div className="listing-page">
      <Breadcrumbs title="Car Listings" subtitle="Listings" maintitle={""} searchdata={searchdata} />
      <div className="section-search page-search">
        <SearchSection searchdata={searchdata} />
      </div>
      <TabListSection setCurrentView={setCurrentView} currentView={currentView} totalCount={totalCars} />
      <section className="section car-listing pt-0">
        <div className="card_container">
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-sm-12 col-12 theiaStickySidebar">
              <FilterSection />
            </div>
            <div className="col-xl-9 col-lg-8 col-sm-12 col-12">
              {loading === "pending" ? (
                <div className="list-loader"><CircularIndeterminate /></div>
              ) : loading === "succeeded" ? (
                carList.length > 0 ? <>
                  {currentView === "list" && <ListView carList={carList} searchdata={searchdata} />}
                  {currentView === "grid" && <GridView carList={carList} />}
                  {currentView === "map" && (
                    <MapContainer
                      center={{ lat: 53.470692, lng: -2.220328 }}
                      places={data}
                    />
                  )}
                  <Pagination />
                </> : "No Data Found"

              ) : (
                <div>No cars found. Please search for relocation !!</div>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Listinglist;
