import React, { useState } from "react";
import Button from "../../common/ui_components/buttons/blueBtn";
import { vehicle_name } from "../../../core/data/json/common_static_data";
import CheckboxLabels from "../../common/ui_components/CheckBox";
import InfoForPricing from "../../common/ui_components/InfoForPricing";

const InstantPricingSection = () => {
  const [includeFuel, setIncludeFuel] = useState<boolean>(true);
  const [includeToll, setIncludeToll] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>("enterprise");

  const handleCheckboxOnchange = (event: React.ChangeEvent<HTMLInputElement>, name: string) => {
    // console.log('event ===>', name, event.target.checked);
    if (name === 'Fuel') setIncludeFuel(event.target.checked);
    else setIncludeToll(event.target.checked);
  };

  const handleTabChange = (tab: string) => {
    setSelectedTab(tab);
  };

  const handleCalculate = () => {
    // console.log("Calculating pricing...");
  };

  return (
    <section className="section popular-explore">
      <div className="card_container">
        <div className="section-heading" data-aos="fade-down">
          <h2>Get Instant Pricing</h2>
          <p>Quickly calculate the cost of relocating your fleet or renting a car with our</p>
        </div>
        <div className="instant-calculation-section" data-aos="fade-down">
          <div className="btn-container">
            <Button
              label="For Enterprise"
              className={`single_btn ${selectedTab === "enterprise" ? "active" : ""}`}
              onClick={() => handleTabChange("enterprise")}
            />
            <Button
              label="For Individual"
              className={`single_btn ${selectedTab === "individual" ? "active" : ""}`}
              onClick={() => handleTabChange("individual")}
            />
          </div>
          <div className="calculation-section">
            <InfoForPricing selectedTab={selectedTab} />
          </div>
          <div className="vehicles-pills">
            {vehicle_name.map((item) => (
              <Button
                key={item.id}
                label={item.name}
                className="vehicles-name active"
                onClick={handleCalculate}
              />
            ))}
          </div>
          <div className="lower_container">
            <div className="input-container">
              <CheckboxLabels label="Include Fuel" checked={includeFuel} onChange={(e) => handleCheckboxOnchange(e, 'Fuel')} />
              <CheckboxLabels label="Include Toll" checked={includeToll} onChange={(e) => handleCheckboxOnchange(e, 'Toll')} />
            </div>
            <Button label="Calculate Price" className="calculate-btn" onClick={handleCalculate} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default InstantPricingSection;
