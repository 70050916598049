import React from 'react';
import { useNavigate } from "react-router-dom";
import ImageWithBasePath from '../../../../core/data/img/ImageWithBasePath';
import IconButton from '../buttons/IconButton';
import ListingLocation from '../ListingLocation';
import { all_routes } from '../../../router/all_routes';
import ListingDetailsGroup from '../ListingDetailsGroup';

const ListViewCard = ({ car, searchdata = null }: any) => {
  const navigate = useNavigate();
  const routes = all_routes;

  const handleClick = () => {
    navigate(`${routes.CarDetails}?carId=${car?._id}`, { state: { searchdata } });
  };

  return (
    <div className="listview-car">
      <div className="card">
        <div className="blog-widget d-flex">
          <div className="blog-img">
            <ImageWithBasePath
              srcType='new'
              src={car?.images?.[0]?.key ? `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/medias?key=${car?.images?.[0]?.key}&width=${100}` : "assets/img/cars/car2.png"}
              className="img-fluid"
              alt="blog-img"
            />
          </div>
          <div className="bloglist-content w-100">
            <div className="card-body">

              <div className="blog-list-head d-flex">
                <div className="blog-list-title">
                  <h3 className='cursor-pointer text-black' onClick={handleClick}>{car?.modelName}</h3>
                  <h6>Category : <span>{car?.carCategory?.name}</span></h6>
                </div>
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <ListingLocation color='#000' searchdata={{
                  pickupLocation: `${car?.pickupLocation?.street}`,
                  dropoffLocation: `${car?.dropoffLocation?.street}`,
                  pickupDate: `${car?.pickupDate}`,
                  dropoffDate: `${car?.dropoffDate}`
                }} />
                {/* <PriceInfo /> */}
              </div>
              <div className="listing-details-group">
                <ListingDetailsGroup car={car} />
                <IconButton onClick={handleClick} label='Rent Now' iconClass="feather icon-calendar me-2" />
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListViewCard;
